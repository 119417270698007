import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from 'src/app/Globals';

@Injectable({
  providedIn: 'root'
})
export class PaisesProyectoService {

  constructor(private http: HttpClient, private global: Globals) { }

  getPaisesProyectos(token, search = null, pagination = null) {
    const headers = this.global.getHeader(token);
    const url: string = this.global.makeUrl('/proyectosPaises', search, pagination);
    return this.http.get(url, { headers });
  }
}
