import { ProyectoGobiernoResponse } from './../interfaces/proyecto-gobierno-response';
import { ProyectosGobiernoService } from './../services/proyectos-gobierno.service';
import { ProyectoGobierno } from './../interfaces/proyecto-gobierno';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { ErrorService } from 'src/app/util/services/error.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-detalle-proyecto-gobierno',
  templateUrl: './detalle-proyecto-gobierno.component.html',
  styleUrls: ['./detalle-proyecto-gobierno.component.sass']
})
export class DetalleProyectoGobiernoComponent implements OnInit {

  public proyectoGobiernoForm: FormGroup;
  editable = true;
  proyectoGobiernoId: number;
  proyectoGobierno: ProyectoGobierno;
  private token;
  cargando: boolean;
  process: boolean;

  constructor(private fB: FormBuilder, private router: Router, private route: ActivatedRoute, private fS: FormStatusService,
    private localStorage: LocalStorageService, private errorService: ErrorService, private toastr: ToastrService,
    private proyectoGobiernoService: ProyectosGobiernoService) { }

  ngOnInit() {
    this.proyectoGobiernoId = +this.route.snapshot.paramMap.get('id');
    this.makeForm();
    this.get();
  }

  makeForm() {
    this.proyectoGobiernoForm = this.fB.group({
      nombre: [{ value: undefined, disabled: this.editable }, Validators.required],
      codigo: [{ value: undefined, disabled: this.editable }],
      descripcion: [{ value: undefined, disabled: this.editable }]
    });
  }

  get() {
    this.cargando = true;
    this.proyectoGobiernoService.getProyectoGobierno(this.proyectoGobiernoId)
      .subscribe((respuesta: ProyectoGobiernoResponse) => {
        this.proyectoGobierno = respuesta.data;
        this.proyectoGobiernoForm.patchValue(this.proyectoGobierno);
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
        this.cargando = false;
      });
  }
  actualizar() {
    this.process = true;
    const jsonData = this.fS.getJsonFromForm(this.proyectoGobiernoForm);
    this.proyectoGobiernoService.actualizarProyectoGobierno(this.proyectoGobiernoId, jsonData)
      .subscribe((respuesta: ProyectoGobiernoResponse) => {
        if (respuesta.success) {
          this.toastr.success('Proyecto Gobierno actualizado correctamente', 'Actualizar Proyecto Gobierno');
          this.get();
          this.editableSwitcher();
        }
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => { this.process = false; });
  }

  volver() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  public editableSwitcher() {
    this.proyectoGobiernoForm = this.fS.cambiarEstado(this.proyectoGobiernoForm, this.editable, ['codigo'], true);
    this.editable = !this.editable;
  }

}
