import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormGroup, FormBuilder, Validators, FormControl} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {ErrorService} from '../../../../util/services/error.service';
import {LocalStorageService} from '../../../../util/local-storage.service';
import {CombosService} from '../../../../util/services/combos/combos.service';
import {DocumentoService} from '../services/documento.service';
import {Documento} from '../interfaces/documento.interface';
import {DocumentoResponse} from '../interfaces/documento-response.interface';
import {CombosResponse} from '../../../../util/interfaces/combos-response';
import {Combo} from '../../../../util/interfaces/combo';
import {map, startWith} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Actor, Persona} from '../interfaces/actor.interface';
import {ActoresResponse} from '../interfaces/actores-response.interface';
import {FormStatusService} from '../../../../util/services/form-status/form-status.service';
import {Globals} from '../../../../Globals';

@Component({
  selector: 'app-detalle-documentacion-proyecto',
  templateUrl: './detalle-documentacion-proyecto.component.html',
  styleUrls: ['./detalle-documentacion-proyecto.component.sass']
})
export class DetalleDocumentacionProyectoComponent implements OnInit {
  file: any;
  @ViewChild('inputGroupFile')
  labelImport: ElementRef;
  documento: Documento;
  documentoTipos: Combo[];
  documentoEstados: Combo[];
  actores: Actor[];
  documentoTipoControl = new FormControl();
  filteredOptionsTipoControl: Observable<object[]>;
  documentoEstadoControl = new FormControl();
  filteredOptionsEstadoControl: Observable<object[]>;
  actorControl = new FormControl();
  filteredActorControl: Observable<object[]>;
  cargando: boolean;
  public documentoForm: FormGroup;
  idProyecto: number;
  idDocumentoProyecto: number;
  editable = false;
  private token;

  constructor(
    private toastr: ToastrService,
    private documentosService: DocumentoService,
    private errorService: ErrorService,
    private localStorage: LocalStorageService,
    private fB: FormBuilder,
    private comboService: CombosService,
    private router: Router,
    private route: ActivatedRoute,
    private fS: FormStatusService,
    private globals: Globals) {
    this.token = this.localStorage.getTokenFromStorage();
    this.documentoForm = fB.group({
      pro_proyecto_documento_tipo_id: [{value: null}, [Validators.required]],
      pro_actor_id: null,
      pro_documento_estado_id: [{value: null}, [Validators.required]],
      nombre: [{value: null}, [Validators.required]],
      fecha_entrega: [{value: null}, [Validators.required]],
      fecha_vencimiento: null,
      url: null,
      descripcion: null
    });
  }

  ngOnInit() {
    this.idProyecto = +this.route.snapshot.paramMap.get('idProyecto');
    this.idDocumentoProyecto = +this.route.snapshot.paramMap.get('id');
    this.getDocumentoProyecto();
    this.getAutocompletes();
  }

  displayFnTipo(tipo?: Combo): string | undefined {
    return tipo ? tipo.nombre : undefined;
  }

  displayFnEstado(estado?: Combo): string | undefined {
    return estado ? estado.nombre : undefined;
  }

  displayFnActor(actor?: Persona): string | undefined {
    return actor ? actor.nombre + ' ' + actor.apellido : undefined;
  }

  public funcionRecorrer(aEvaluar: string[], objeto) {
    let cadenaSplitted = '';
    aEvaluar.forEach((element) => {
      cadenaSplitted = cadenaSplitted + objeto[element].split(' ').join('');
    });
    return cadenaSplitted;
  }

  private filter(value, combo, aEvaluar: string[]): object[] {
    let filterValue;
    if (value) {
      if (typeof value === 'object' && value.constructor === Object) {
        return combo.filter(objeto => objeto.id === value.id);
      } else {
        filterValue = value.toLowerCase();
        return combo.filter(
          objeto => {
            const cadenaSplitted = this.funcionRecorrer(aEvaluar, objeto);
            const filterValueSplitted = filterValue.split(' ').join('');
            return cadenaSplitted.toLowerCase().includes(filterValueSplitted);
          });
      }
    } else {
      filterValue = '';
      return combo.filter(objeto => {
        const cadenaSplitted = this.funcionRecorrer(aEvaluar, objeto);
        return cadenaSplitted.toLowerCase().includes(filterValue);
      });
    }
  }

  funcionAutocompleteDocumentoTipos() {
    this.filteredOptionsTipoControl = this.documentoTipoControl.valueChanges
      .pipe(
        startWith(''),
        map(value => {
          if (value) {
            if (typeof value === 'object' && value.constructor === Object) {
              this.documentoForm.controls.pro_proyecto_documento_tipo_id.setValue(value.id);
            }
          }
          return this.filter(value, this.documentoTipos, ['nombre']);
        }),
      );
  }

  funcionAutocompleteDocumentoEstados() {
    this.filteredOptionsEstadoControl = this.documentoEstadoControl.valueChanges
      .pipe(
        startWith(''),
        map(value => {
          if (value) {
            if (typeof value === 'object' && value.constructor === Object) {
              this.documentoForm.controls.pro_documento_estado_id.setValue(value.id);
            }
          }
          return this.filter(value, this.documentoEstados, ['nombre']);
        }),
      );
  }

  funcionAutocompleteActores() {
    const persona: Persona[] = [];
    this.actores.forEach(actor => {
      persona.push(actor.persona);
    });
    this.filteredActorControl = this.actorControl.valueChanges
      .pipe(
        startWith(''),
        map(value => {
          if (value) {
            if (typeof value === 'object' && value.constructor === Object) {
              this.documentoForm.controls.pro_actor_id.setValue(value.id);
            }
          }
          return this.filter(value, persona, ['nombre', 'apellido']);
        }),
      );
  }

  private getDocumentoProyecto() {
    this.cargando = true;
    this.documentosService.getDocumento(this.token, this.idProyecto, this.idDocumentoProyecto)
      .subscribe((respuesta: DocumentoResponse) => {
          this.documento = respuesta.data;
          this.documentoForm.patchValue(this.documento);
          this.documentoForm.disable();
        }, error => {
          this.errorService.handleError(error);
        }
      ).add(() => {
      this.cargando = false;
    });
  }

  private getDocumentoTiposCombo() {
    this.comboService.getProyectoDocumentoTipo(this.idProyecto)
      .subscribe((respuesta: CombosResponse) => {
        this.documentoTipos = respuesta.data;
        this.funcionAutocompleteDocumentoTipos();
      }, error => {
        this.errorService.handleError(error);
      }).add(() => {
    });
  }

  private getDocumentoEstadosCombo() {
    this.comboService.getDocumentoEstadosCombo(this.token)
      .subscribe((respuesta: CombosResponse) => {
        this.documentoEstados = respuesta.data;
        this.funcionAutocompleteDocumentoEstados();
      }, error => {
        this.errorService.handleError(error);
      }).add(() => {
    });
  }

  private getActoresCombo() {
    this.comboService.getProyectoActoresCombo(this.token, this.idProyecto)
      .subscribe((respuesta: ActoresResponse) => {
        this.actores = respuesta.data;
        this.funcionAutocompleteActores();
      }, error => {
        this.errorService.handleError(error);
      }).add(() => {
    });
  }

  evento($event: MouseEvent) {
    $event.preventDefault();
    this.volver();
  }

  volver() {
    this.router.navigate(['../'], {relativeTo: this.route});
  }

  getAutocompletes() {
    this.getActoresCombo();
    this.getDocumentoEstadosCombo();
    this.getDocumentoTiposCombo();
  }

  editarDocumento(bandera: string) {
    if (bandera === 'C') {
      this.documentoForm.reset();
      this.documentoEstadoControl.reset();
      this.documentoTipoControl.reset();
      this.actorControl.reset();
      this.getDocumentoProyecto();
      this.getAutocompletes();
    }
    this.documentoForm = this.fS.cambiarEstado(this.documentoForm, this.editable, ['pro_actor_id', 'pro_proyecto_documento_tipo_id']);
    this.editable = !this.editable;
    // this.documentoForm.enabled ? this.documentoForm.disable() : this.documentoForm.enable();
  }

  actualizarDocumento() {
    const formData = new FormData();
    if (this.file) {
      formData.append('url', this.file, this.file.name);
    }
    // formData.append('pro_proyecto_documento_tipo_id', this.documentoForm.value.pro_proyecto_documento_tipo_id);
    // formData.append('pro_actor_id', this.documentoForm.value.pro_actor_id);
    formData.append('pro_documento_estado_id', this.documentoForm.value.pro_documento_estado_id);
    formData.append('nombre', this.documentoForm.value.nombre);
    formData.append('fecha_entrega', this.documentoForm.value.fecha_entrega);
    formData.append('fecha_vencimiento', this.documentoForm.value.fecha_vencimiento);
    formData.append('descripcion', this.documentoForm.value.descripcion);
    this.documentosService.editarDocumento(this.token, this.idProyecto, this.idDocumentoProyecto, formData)
      .subscribe((respuesta: DocumentoResponse) => {
          this.toastr.success('Documento editado exitosamente', 'Edición de Documento');
          this.documentoForm.disable();
          this.editable = false;
          this.getDocumentoProyecto();
        },
        error => {
          this.toastr.error('Error al editar Documento', 'Edición de Documento', {toastClass: 'toastr-custom-error'});
        }).add(() => {
    });
  }

  fileChangeEvent(files): void {
    this.file = files[0];
    this.labelImport.nativeElement.innerText = files[0].name;
  }

  descargar() {
    window.open(`${this.globals.FILE_ENDPOINT}/storage/documentos/${this.documento.url}`, '_blank');
  }
}
