import { ProyectoRolResponse } from './../interfaces/proyecto-rol-response';
import { ProyectoRolService } from './../services/proyecto-rol.service';
import { Component, OnInit, Inject } from '@angular/core';
import { ProyectoRol } from '../interfaces/proyecto-rol';
import { CombosResponse } from 'src/app/util/interfaces/combos-response';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Combo } from 'src/app/util/interfaces/combo';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CombosService } from 'src/app/util/services/combos/combos.service';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from 'src/app/util/services/error.service';
import { LocalStorageService } from 'src/app/util/local-storage.service';

export interface DialogData {
  proyectoId: number;
  proyectoRolId: number;
}
@Component({
  selector: 'app-detalle-proyecto-rol',
  templateUrl: './detalle-proyecto-rol.component.html',
  styleUrls: ['./detalle-proyecto-rol.component.sass']
})
export class DetalleProyectoRolComponent implements OnInit {

  public proyectoRolForm: FormGroup;
  editable = true;
  proyectoRolId: number;
  proyectoId: number;
  cargando: boolean;
  process: boolean;
  private token;
  proyectoRol: ProyectoRol;
  proyectoRoles: Combo[];

  constructor(public dialogRef: MatDialogRef<DetalleProyectoRolComponent>, private fB: FormBuilder, private comboService: CombosService,
    private proyectoRolService: ProyectoRolService, private fS: FormStatusService, private toastr: ToastrService,
    private errorService: ErrorService, private localStorage: LocalStorageService, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
    this.proyectoId = this.data.proyectoId;
    this.proyectoRolId = this.data.proyectoRolId;
    this.token = this.localStorage.getTokenFromStorage();
    this.makeForm();
    this.getRolesCombo();
    this.get();
  }

  makeForm() {
    this.proyectoRolForm = this.fB.group({
      pro_actor_rol_id: [{ value: null, disabled: this.editable }, Validators.required],
      activo: [{ value: null, disabled: this.editable }, Validators.required]
    });
  }

  get() {
    this.cargando = true;
    this.proyectoRolService.getProyectoRol(this.proyectoId, this.proyectoRolId, this.token)
      .subscribe((respuesta: ProyectoRolResponse) => {
        this.proyectoRol = respuesta.data;
        this.proyectoRolForm.patchValue(this.proyectoRol);
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
        this.cargando = false;
      });
  }
  actualizar() {
    this.process = true;
    const jsonData = this.fS.getJsonFromForm(this.proyectoRolForm);
    this.proyectoRolService.actualizarProyectoRol(this.proyectoId, this.proyectoRolId, this.token, jsonData)
      .subscribe((respuesta: ProyectoRolResponse) => {
        if (respuesta.success) {
          this.toastr.success('Rol actualizado correctamente', 'Actualizar Rol');
          this.get();
          this.editableSwitcher();
          this.dialogRef.close('updated');
        }
      }, error => {
        this.errorService.handleError(error);
        this.proyectoRolForm.enable();
      }).add(() => { this.process = false; });
  }

  volver() {
    this.dialogRef.close();
  }

  public editableSwitcher() {
    this.proyectoRolForm = this.fS.cambiarEstado(this.proyectoRolForm, this.editable);
    this.editable = !this.editable;
  }

  getRolesCombo() {
    this.comboService.getRolActoresCombo(this.token)
      .subscribe((respuesta: CombosResponse) => {
        this.proyectoRoles = respuesta.data;
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
      });
  }


}
