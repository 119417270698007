import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from 'src/app/Globals';

@Injectable({
  providedIn: 'root'
})
export class ConfiguracionesParaproService {

  constructor(private http: HttpClient, private global: Globals) { }

  getConfiguraciones(token, search = null, pagination = null) {
    const headers = this.global.getHeader(token);
    const url: string = this.global.makeUrl('/configuraciones', search, pagination);
    return this.http.get(url, { headers });
  }
  getConfiguracion(id, token) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/configuraciones/' + id;
    return this.http.get(url, { headers });
  }
  actualizarConfiguracion(id, token, json) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/configuraciones/' + id;
    return this.http.put(url, json, { headers });
  }
  crearConfiguracion(token, json) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/configuraciones';
    return this.http.post(url, json, { headers });
  }
  borrarConfiguracion(id, token) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/configuraciones/' + id;
    return this.http.delete(url, { headers });
  }
}
