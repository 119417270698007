import { TipoDocumentoResponse } from './../interfaces/tipo-documento-response';
import { TipoDocumentoService } from './../services/tipo-documento.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { TipoDocumento } from '../interfaces/tipo-documento';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { ErrorService } from 'src/app/util/services/error.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-detalle-tipo-documento',
  templateUrl: './detalle-tipo-documento.component.html',
  styleUrls: ['./detalle-tipo-documento.component.sass']
})
export class DetalleTipoDocumentoComponent implements OnInit {

  public tipoDocumentoForm: FormGroup;
  editable = true;
  cargando: boolean;
  tipoDocumentoId: number;
  tipoDocumento: TipoDocumento;
  private token;
  process: boolean;

  constructor(private fB: FormBuilder, private router: Router, private route: ActivatedRoute, private fS: FormStatusService,
    private localStorage: LocalStorageService, private errorService: ErrorService, private tipoDocumentoService: TipoDocumentoService,
    private toastr: ToastrService) { }

  ngOnInit() {
    this.tipoDocumentoId = +this.route.snapshot.paramMap.get('id');
    this.token = this.localStorage.getTokenFromStorage();
    this.makeForm();
    this.get();
  }

  makeForm() {
    this.tipoDocumentoForm = this.fB.group({
      nombre: [{ value: undefined, disabled: this.editable }, Validators.required],
      codigo: [{ value: undefined, disabled: this.editable }],
      descripcion: [{ value: undefined, disabled: this.editable }]
    });
  }

  get() {
    this.cargando = true;
    this.tipoDocumentoService.getTipoDocumento(this.tipoDocumentoId, this.token)
      .subscribe((respuesta: TipoDocumentoResponse) => {
        this.tipoDocumento = respuesta.data;
        this.tipoDocumentoForm.patchValue(this.tipoDocumento);
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
        this.cargando = false;
      });
  }
  actualizar() {
    this.process = true;
    const jsonData = this.fS.getJsonFromForm(this.tipoDocumentoForm);
    this.tipoDocumentoService.actualizarTipoDocumento(this.tipoDocumentoId, this.token, jsonData)
      .subscribe((respuesta: TipoDocumentoResponse) => {
        if (respuesta.success) {
          this.toastr.success('Tipo de documento actualizado correctamente', 'Actualizar Tipo de documento');
          this.get();
          this.editableSwitcher();
        }
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => { this.process = false; });
  }

  volver() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  public editableSwitcher() {
    this.tipoDocumentoForm = this.fS.cambiarEstado(this.tipoDocumentoForm, this.editable, ['codigo'], true);
    this.editable = !this.editable;
  }

}
