import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from 'src/app/Globals';

@Injectable({
  providedIn: 'root'
})
export class ProyectosService {

  constructor(private http: HttpClient, private global: Globals) { }

  getProyectos(search = null, pagination = null) {
    const headers = this.global.getHeader();
    const url: string = this.global.makeUrl('/proyectos', search, pagination);
    return this.http.get(url, { headers });
  }
  getProyecto(id, token) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/proyectos/' + id;
    return this.http.get(url, { headers });
  }
  actualizarProyecto(id, token, json) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/proyectos/' + id;
    return this.http.put(url, json, { headers });
  }
  crearProyecto(token, json) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/proyectos';
    return this.http.post(url, json, { headers });
  }
  borrarProyecto(id) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/proyectos/' + id;
    return this.http.delete(url, { headers });
  }

  cambiarProyectoImagenes(id, formData) {
    const url = this.global.API_ENDPOINT_ES + `/proyectos/${id}/imagenesTipos`;
    return this.http.post(url, formData );
  }
}
