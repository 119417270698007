import { ImportarEditorEncuestaDialogComponent } from './importar-editor-encuesta-dialog/importar-editor-encuesta-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { FormStatusService } from './../../../../../../util/services/form-status/form-status.service';
import { EditorEncuestaService } from './services/editor-encuesta.service';
import { ErrorService } from './../../../../../../util/services/error.service';
import { CombosResponse } from './../../../../../../util/interfaces/combos-response';
import { CombosService } from 'src/app/util/services/combos/combos.service';
import { Combo } from './../../../../../../util/interfaces/combo';
import {DetalleRespuestaMonitoreo, PreguntasEncuesta} from './../interfaces/detalle-respuesta-monitoreo';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-editor-encuesta',
  templateUrl: './editor-encuesta.component.html',
  styleUrls: ['./editor-encuesta.component.sass']
})
export class EditorEncuestaComponent implements OnInit {
  @Input() preguntas: PreguntasEncuesta[];
  @Input() encuestaId: number;
  @Output() updated: EventEmitter<boolean> = new EventEmitter<boolean>();
  displayedColumns = ['nombre', 'tipo_respuesta'];
  showColumns: string[];
  allColumns: string[];
  preguntaForm: FormGroup;
  opcionForm: FormGroup;
  total: number;
  creating: boolean;
  creatingOption: boolean;
  selected = null;
  pagination = { page: 1, per_page: 10 };
  tiposPregunta: Combo[];
  cargando: boolean;
  process: boolean;
  constructor(private router: Router,
              private comboService: CombosService,
              private errorService: ErrorService,
              private route: ActivatedRoute, private fB: FormBuilder,
              private editorEncuestaService: EditorEncuestaService,
              private fS: FormStatusService,
              private toastr: ToastrService,
              public dialog: MatDialog) {
    this.preguntaForm = this.fB.group({
      enc_pregunta_tipo_id: [null, Validators.required],
      codigo: [null, Validators.required],
      texto: [null, Validators.required]
      // indicador_id: [null, Validators.required]
    });
  }

  ngOnInit() {
    this.cargando = false;
    this.loadOpcionForm();
  }

  creandoPregunta() {
    this.preguntaForm.reset();
    this.creating = !this.creating;
  }

  crearPregunta() {
    this.process = true;
    const code = this.preguntaForm.get('codigo').value;
    this.preguntaForm.get('codigo').setValue(code.toUpperCase());
    const jsonData = this.fS.getJsonFromForm(this.preguntaForm);
    this.editorEncuestaService.crearPregunta(this.encuestaId, jsonData).subscribe((respuesta: any) => {
      this.toastr.success('Pregunta creada exitosamente', 'Crear Pregunta');
      this.updated.emit(true);
    }, error => {
      this.errorService.handleError(error);
    }, () => { this.process = false; });
  }

  verDetalle(id) {
    // this.router.navigate(['./', 1], { relativeTo: this.route });
  }

  changeBackGroundColor(event, row) {
    this.selected = event.type == 'mouseover' ? row.id : null;
  }

  pageChange() {
    // this.getEncuestas();
  }

  onClick(event: any) {
    event ? this.filtrar() : this.onReset();
  }

  onReset() {
    this.pagination.page = 1;
    //  this.search.reset();
    //  this.getEncuestas();
  }

  filtrar() {
    this.pagination.page = 1;
    // this.getEncuestas();
  }

  openImportModal() {
    const dialogRef = this.dialog.open(ImportarEditorEncuestaDialogComponent, {
      width: '800px',
      data: { encuestaId: this.encuestaId, proyectoId: '' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.updated.emit(true);
      }
    });
  }

  loadOpcionForm() {
    this.opcionForm = this.fB.group({
      pregunta_id: [null],
      codigo: [null, Validators.required],
      texto: [null, Validators.required]
    });
  }
  creandoOpcion() {
    this.opcionForm.reset();
    this.creatingOption = !this.creatingOption;
  }

}
