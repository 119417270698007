import { ExcelErrorViewerComponent } from './../../../../../../../util/excel-error-viewer/excel-error-viewer.component';
import { GrupoAhorroService } from './../../../services/grupo-ahorro.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router'
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { ErrorService } from 'src/app/util/services/error.service';
import { CrearMiembroComponent } from './crear-miembro/crear-miembro.component';
import { RegistroPagosMiembroComponent } from './registro-pagos-miembro/registro-pagos-miembro.component';
import { Overlay } from '@angular/cdk/overlay';
import * as XLSX from 'xlsx';
import { DesactivarMiembroComponent } from './desactivar-miembro/desactivar-miembro.component';
import { ReportesGrupoAhorroService } from '../../../services/reportes-grupo-ahorro.service';
import {InformacionMiembroExcelComponent} from '../../../../talleres-gestion-proyecto/detalle-taller-gestion-proyecto/taller-participante/informacion-miembro-excel/informacion-miembro-excel.component';
import {InformacionExcelMiembroGrupoAhorroComponent} from './informacion-excel-miembro-grupo-ahorro/informacion-excel-miembro-grupo-ahorro.component';

@Component({
  selector: 'app-miembros',
  templateUrl: './miembros.component.html',
  styleUrls: ['./miembros.component.sass']
})
export class MiembrosComponent implements OnInit {

  heading = 'Miembros';
  subheading = 'En este módulo se encuentra la lista de miembros';
  icon = 'pe-7s-study icon-gradient bg-ripe-malin';
  @Input() ciclo: any;
  @Output() update: EventEmitter<boolean> = new EventEmitter();
  cargando: boolean;
  total: number;
  pagination = { page: 1, per_page: 10 };
  selected = null;
  displayedColumns = ['nombre', 'cant_acciones_fondo', 'estado', 'acciones'];
  miembros;
  searchForm: FormGroup;
  idProyecto: number;
  idGrupo: number;
  idCiclo: number;
  file;
  downloading: boolean;
  reporteData: any;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private fB: FormBuilder,
    public dialog: MatDialog,
    private localStorageService: LocalStorageService,
    private toastr: ToastrService,
    private errorService: ErrorService,
    private grupoAhorroService: GrupoAhorroService,
    private overlay: Overlay,
    private reportesGrupoAhorroService: ReportesGrupoAhorroService) {
    this.searchForm = this.fB.group({
      nombre: '',
      apellido: ''
    });
  }

  ngOnInit(): void {
    this.idProyecto = +this.route.snapshot.paramMap.get('idProyecto');
    this.idGrupo = +this.route.snapshot.paramMap.get('idGrupo');
    this.idCiclo = +this.route.snapshot.paramMap.get('idCiclo');
    this.getMiembros();
  }

  getMiembros(hasChange = false) {
    this.cargando = true;
    this.grupoAhorroService.getMiembrosCiclos(this.idProyecto, this.idGrupo, this.idCiclo, this.searchForm.value, this.pagination)
      .subscribe((response: any) => {
        if (response.success) {
          this.miembros = response.data;
          this.total = response.metadata.total;
        }
      }, error => {
        this.errorService.handleError(error);
      }, () => {
        this.cargando = false;
        if (hasChange) {
          this.update.emit(true);
        }
      });
  }

  crear() {
    this.router.navigate(['./miembros/new'], { relativeTo: this.route });
  }

  verDetalle(id: number) {
    this.router.navigate(['./miembros', id], { relativeTo: this.route });
  }

  borrar(id: number) {
    this.grupoAhorroService.deleteMiembro(this.idProyecto, this.idGrupo, this.idCiclo, id)
      .subscribe((respuesta: any) => {
        if (respuesta.success) {
          this.toastr.success('Miembro borrado exitosamente', 'Borrar Miembro');
        }
      }, (error) => {
        this.errorService.handleError(error);
      }, () => { this.getMiembros(true); });
  }

  evento($event: MouseEvent) {
    $event.preventDefault();
    this.volver();
  }

  onClick(event: any) {
    event ? this.filtrar() : this.onReset();
  }

  filtrar() {
    this.pagination.page = 1;
    this.getMiembros();
  }


  onReset() {
    this.pagination.page = 1;
    this.searchForm.reset();
    this.getMiembros();
  }


  pageChange() {
    this.getMiembros();
  }

  changeBackGroundColor(event, row) {
    this.selected = event.type == 'mouseover' ? row.id : null;
  }

  volver() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

  openDialog() {
    const dialogRef = this.dialog.open(CrearMiembroComponent, {
      width: '600px',
      autoFocus: true,
      hasBackdrop: true,
      scrollStrategy: this.overlay.scrollStrategies.noop(), // la página mantiene el scroll cuando se abre el dialogo
      data: {
        idProyecto: this.idProyecto,
        idGrupo: this.idGrupo,
        idCiclo: this.idCiclo,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getMiembros(true);
      }
    });
  }

  openPagos() {
    const dialogRef = this.dialog.open(RegistroPagosMiembroComponent, {
      width: '800px',
      autoFocus: true,
      scrollStrategy: this.overlay.scrollStrategies.noop(), // la página mantiene el scroll cuando se abre el dialogo
      data: { idProyecto: this.idProyecto }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
      }
    });
  }

  fileChangeEvent(files): void {
    this.file = files[0];
    if (this.validateFile()) {
      const formData = new FormData();
      formData.append('file', this.file, this.file.name);
      this.cargando = true;
      this.grupoAhorroService.uploadMiembros(this.idProyecto, this.idGrupo, this.idCiclo, formData)
        .subscribe((respnse: any) => {
          if (respnse.success) {
            this.toastr.success('Miembros cargados exitosamente', 'Carga de Miembros');
          }
        }, error => {
          if(error.status === 422 && error.error.errorExcel){
            this.dialog.open(ExcelErrorViewerComponent, {
              width: '700px',
              height: '600px',
              data: error.error.errorExcel
            });
          } else {
            this.errorService.handleError(error);
          }
          this.cargando = false;
        }, () => {
          this.cargando = false;
          this.getMiembros();
        });
    } else {
      this.toastr.error('Solo se permiten archivos de tipo  Excel', 'Atención');
      this.cargando = false;
    }

  }

  validateFile(): boolean {
    const allowedExtensions = /(.xls|.xlsx)$/i;
    if (!allowedExtensions.exec(this.file.name)) {
      return false;
    } else {
      return true;
    }
  }

  cambiarEstado(id) {
    this.dialog.open(DesactivarMiembroComponent, {
      width: '700px',
      data: { idProyecto: this.idProyecto, idGrupo: this.idGrupo, idCiclo: this.idCiclo, idMiembro: id }
    }).afterClosed().subscribe(result => {
      if (result) {
        this.getMiembros();
      }
    })
  }

  descargarReporte() {
    this.downloading = true;
    this.reportesGrupoAhorroService.getCiclosMiembrosReporte(this.idCiclo)
      .subscribe((response: any) => {
        this.reporteData = response.data;
      }, error => {
        this.errorService.handleError(error);
        this.downloading = false;
      }, () => {
        this.exportAsExcel();
      });
  }

  exportAsExcel() {
    const exportedData = [];
    const excelFileName =  'grupos-ahorro-ciclo-miembros'
    this.reporteData.forEach(value => {
      const obj = {
        nombre: `${value.nombre} ${value.apellido}`,
        documento: value.documento_completo,
        monto_fondo_social: (value.monto_fondo_social) ? value.monto_fondo_social : '0',
        monto_fondo_prestamo: (value.monto_fondo_prestamo) ? value.monto_fondo_prestamo : '0',
        cantidad_acciones: (value.cantidad_acciones) ? value.cantidad_acciones : '0',
        monto_multas: (value.monto_multas) ? value.monto_multas : '0',
        cantidad_multas: (value.cantidad_multas) ? value.cantidad_multas : '0',
        estado: (value.estado === 'A') ? 'Activo' : 'Inactivo'
      }
      exportedData.push(obj);
    });
    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.json_to_sheet(exportedData);
    XLSX.utils.book_append_sheet(workBook, workSheet, 'data');
    XLSX.writeFile(workBook, `${excelFileName}.xlsx`);
    this.downloading = false;
  }

  verInformacion(){
    this.dialog.open(
      InformacionExcelMiembroGrupoAhorroComponent,
      {
        width: '500px'
      }
    );
  }


}


