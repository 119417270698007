import { CrearReunionDiaComponent } from './crear-reunion-dia/crear-reunion-dia.component';
import { MatDialog } from '@angular/material';
import { ErrorService } from 'src/app/util/services/error.service';
import { ToastrService } from 'ngx-toastr';
import { ReunionesCrudService } from './reuniones-crud.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-reuniones-crud',
  templateUrl: './reuniones-crud.component.html',
  styleUrls: ['./reuniones-crud.component.sass']
})
export class ReunionesCrudComponent implements OnInit {

  @Input() idCiclo: number;
  @Input() ciclo: any;
  cargando: boolean;
  diasReu: any[];
  selected = null;
  displayedColumns = ['dia', 'hora', 'acciones'];


  constructor(
    private reunionesCrudService: ReunionesCrudService,
    private toastr: ToastrService,
    private errorService: ErrorService,
    private matDialog: MatDialog
  ) { }

  ngOnInit() {
    this.get();
  }

  get() {
    this.cargando = true;
    this.reunionesCrudService.getDiasReunion(this.idCiclo)
      .subscribe((response: any) => {
        if (response.success) {
          this.diasReu = response.data;
        }
      }, error => {
        this.errorService.handleError(error);

      }, () => {
        this.cargando = false;
      });
  }

  changeBackGroundColor(event, row) {
    this.selected = event.type == 'mouseover' ? row.id : null;
  }

  openModal() {
    this.matDialog.open(CrearReunionDiaComponent, {
      width: '550px',
      data: this.idCiclo
    }).afterClosed().subscribe(response => {
      if (response) {
        this.get();
      }
    })
  }

  borrar(id) {
    this.reunionesCrudService.borrarDiaReunion(this.idCiclo, id)
      .subscribe((response: any) => {

        if (response.success) {
          this.toastr.success('Se ha borrado correctamente el día de la reunión', 'Borrar Día de Reunión');
        }

      }, error => {
        this.errorService.handleError(error)
      }, () => {
        this.get();
      });
  }

}
