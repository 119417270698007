import { DimensionResponse } from './../interfaces/dimension-response';
import { DimensionService } from './../services/dimension.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from 'src/app/util/services/error.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-crear-dimension',
  templateUrl: './crear-dimension.component.html',
  styleUrls: ['./crear-dimension.component.sass']
})
export class CrearDimensionComponent implements OnInit {

  public nuevaDimension: FormGroup;
  private token;
  process: boolean;
  constructor(private fB: FormBuilder, private router: Router, private route: ActivatedRoute, private localStorage: LocalStorageService,
    private fS: FormStatusService, private toastr: ToastrService, private errorService: ErrorService, private dimensionService: DimensionService) { }

  ngOnInit() {
    this.token = this.localStorage.getTokenFromStorage();
    this.makeForm();
  }

  makeForm() {
    this.nuevaDimension = this.fB.group({
      nombre: [null, Validators.required],
      codigo: [null, Validators.required],
      orden: [null, Validators.required],
      descripcion: [undefined]
    });
  }

  crear() {
    this.process = true;
    const jsonData = this.fS.getJsonFromForm(this.nuevaDimension);
    this.dimensionService.crearDimension(this.token, jsonData)
      .subscribe((respuesta: DimensionResponse) => {
        if (respuesta.success) {
          this.toastr.success('Dimensión creada exitosamente', 'Crear Dimensión');
          this.volver();
        }
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
        this.nuevaDimension.enable();
      }).add(() => { this.process = false; });
  }

  volver() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
}
