import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from 'src/app/util/services/error.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TalleresFacilitadoresService } from '../services/talleres-facilitadores.service';


@Component({
  selector: 'app-crear-taller-facilitador',
  templateUrl: './crear-taller-facilitador.component.html',
  styleUrls: ['./crear-taller-facilitador.component.sass']
})
export class CrearTallerFacilitadorComponent implements OnInit {

  public nuevoTallerFacilitador: FormGroup;
  process: boolean;

  constructor(private fB: FormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private tallerFacilitadorService: TalleresFacilitadoresService,
              private fS: FormStatusService,
              private toastr: ToastrService,
              private errorService: ErrorService) { }

  ngOnInit() {
    this.formularioTaller();
  }

  formularioTaller(){
    this.nuevoTallerFacilitador = this.fB.group({
      nombre: [undefined, Validators.required],
      orden: [undefined, Validators.required],
      descripcion: [undefined]
    });
  }

  crear() {
    this.process = true;
    const jsonData = this.fS.getJsonFromForm(this.nuevoTallerFacilitador);
    this.tallerFacilitadorService.crearTallerFacilitador(jsonData)
      .subscribe((respuesta: any) => {
        if (respuesta.success) {
          this.toastr.success('Taller Facilitador creado exitosamente', 'Crear Taller Facilitador');
          this.volver();
        }
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
        this.nuevoTallerFacilitador.enable();
      }).add(() => { this.process = false; });
  }

  volver() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
}
