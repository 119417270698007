import { ProyectoResponse } from './../../../interfaces/proyecto-response';
import { ProyectosService } from './../../../services/proyectos.service';
import { CrearActorUbicacionComponent } from './crear-actor-ubicacion/crear-actor-ubicacion.component';
import { ActorUbicacionResponse } from './interfaces/actor-ubicacion-response';
import { ActorUbicacionService } from './services/actor-ubicacion.service';
import { ActorUbicacion, UbicacionDepartamento, UbicacionCiudad, UbicacionComunidad } from './interfaces/actor-ubicacion';
import { Component, OnInit, Input } from '@angular/core';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from 'src/app/util/services/error.service';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-actor-ubicacion',
  templateUrl: './actor-ubicacion.component.html',
  styleUrls: ['./actor-ubicacion.component.sass']
})
export class ActorUbicacionComponent implements OnInit {

  @Input() actorId: number;
  @Input() proyectoId: number;
  paisId: number;
  cargando: boolean;
  private token;
  ubicaciones: ActorUbicacion[];
  departamentos: UbicacionDepartamento[];
  ciudades: UbicacionCiudad[];
  comunidades: UbicacionComunidad[];
  total: number;
  constructor(private localStorage: LocalStorageService,
    private actorUbicacionService: ActorUbicacionService,
    private toastr: ToastrService,
    private errorService: ErrorService,
    public dialog: MatDialog,
    private proyectoService: ProyectosService) { }

  ngOnInit() {
    this.token = this.localStorage.getTokenFromStorage();
    this.getActorUbicaciones();
    this.getProyecto();
  }

  getActorUbicaciones() {
    this.cargando = true;
    this.actorUbicacionService.getActorUbicaciones(this.token, this.actorId)
      .subscribe((respuesta: ActorUbicacionResponse) => {
        this.ubicaciones = respuesta.data;
        this.total = respuesta.metadata.total;
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
        this.cargando = false;
      });
  }

  getProyecto() {
    this.proyectoService.getProyecto(this.proyectoId, this.token)
      .subscribe((respuesta: ProyectoResponse) => {
        this.paisId = respuesta.data.adm_pais_id;
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
      });
  }

  crear() {
    const dialogRef = this.dialog.open(CrearActorUbicacionComponent, {
      width: '700px',
      data: { actorId: this.actorId, paisId: this.paisId }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'created') {
        this.getActorUbicaciones();
      }
    });
  }

}
