import { ConfigDocumentosTiposResponse, ConfigDocumentoTipoResponse } from './interfaces/config-documento-tipo-response';
import { DetalleConfigDocumentoTipoComponent } from './detalle-config-documento-tipo/detalle-config-documento-tipo.component';
import { CrearConfigDocumentoTipoComponent } from './crear-config-documento-tipo/crear-config-documento-tipo.component';
import { ConfigDocumentoTipoService } from './services/config-documento-tipo.service';
import { Component, OnInit, Input } from '@angular/core';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from 'src/app/util/services/error.service';
import { MatDialog } from '@angular/material';
import { HttpErrorResponse } from '@angular/common/http';
import { ConfigDocumentoTipo } from './interfaces/config-documento-tipo';

@Component({
  selector: 'app-config-documento-tipo',
  templateUrl: './config-documento-tipo.component.html',
  styleUrls: ['./config-documento-tipo.component.sass']
})
export class ConfigDocumentoTipoComponent implements OnInit {

  @Input() proyectoId: number;
  cargando: boolean;
  private token;
  documentosTipos: ConfigDocumentoTipo[];
  displayedColumns = ['nombre', 'codigo', 'activo', 'acciones'];
  selected = null;

  constructor(
    private localStorage: LocalStorageService,
    private configDocumentoTipoService: ConfigDocumentoTipoService,
    private toastr: ToastrService,
    private errorService: ErrorService,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.token = this.localStorage.getTokenFromStorage();
    this.getDocumentosTipos();
  }

  getDocumentosTipos() {
    this.cargando = true;
    this.configDocumentoTipoService.getProyectoDocumentosTipos(this.token, this.proyectoId)
      .subscribe((respuesta: ConfigDocumentosTiposResponse) => {
        this.documentosTipos = respuesta.data;
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
        this.cargando = false;
      });
  }

  crear() {
    const dialogRef = this.dialog.open(CrearConfigDocumentoTipoComponent, {
      width: '700px',
      data: { proyectoId: this.proyectoId }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'created') {
        this.getDocumentosTipos();
      }
    });
  }

  verDetalle(id: number) {
    const dialogRef = this.dialog.open(DetalleConfigDocumentoTipoComponent, {
      width: '700px',
      data: { proyectoId: this.proyectoId, documentoTipoId: id }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'updated') {
        this.getDocumentosTipos();
      }
    });
  }

  changeBackGroundColor(event, row) {
    this.selected = event.type == 'mouseover' ? row.id : null;
  }

  borrar(id: number) {
    this.configDocumentoTipoService.borrarProyectoDocumentoTipo(this.proyectoId, id, this.token)
      .subscribe((respuesta: ConfigDocumentoTipoResponse) => {
        if (respuesta.success) {
          this.toastr.success('Tipo de Documento borrado exitosamente', 'Borrar Tipo de Documento');
        }
        this.getDocumentosTipos();
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
      }, () => { });
  }


}
