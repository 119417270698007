import { ComponenteMonitoreoService } from './../../../../parametricos-proyecto/componentes-monitoreo/services/componente-monitoreo.service';
import { ConfigProyectoMonitoreoService } from './../services/config-proyecto-monitoreo.service';
import { Component, OnInit, Inject } from '@angular/core';
import { ConfigProyectoMonitoreoResponse } from '../interfaces/config-proyecto-monitoreo-response';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Combo } from 'src/app/util/interfaces/combo';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { ToastrService } from 'ngx-toastr';
import { CombosService } from 'src/app/util/services/combos/combos.service';
import { ErrorService } from 'src/app/util/services/error.service';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CombosResponse } from 'src/app/util/interfaces/combos-response';

export interface DialogData {
  proyectoId: number;
}
@Component({
  selector: 'app-crear-proyecto-monitoreo',
  templateUrl: './crear-proyecto-monitoreo.component.html',
  styleUrls: ['./crear-proyecto-monitoreo.component.sass']
})
export class CrearProyectoMonitoreoComponent implements OnInit {

  public nuevoComponente: FormGroup;
  private proyectoId: number;
  private token;
  componentesMonitoreo: Combo[];
  process: boolean;
  constructor(public dialogRef: MatDialogRef<CrearProyectoMonitoreoComponent>, private fB: FormBuilder,
    private configProyectoMonitoreoService: ConfigProyectoMonitoreoService, private fS: FormStatusService, private toastr: ToastrService, private comboService: CombosService,
    private errorService: ErrorService, private localStorage: LocalStorageService, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
    this.token = this.localStorage.getTokenFromStorage();
    this.proyectoId = this.data.proyectoId;
    this.makeForm();
    this.getComponentesMonitoreo();
  }

  makeForm() {
    this.nuevoComponente = this.fB.group({
      pro_componente_monitoreo_id: [null, Validators.required],
    });
  }

  crear() {
    this.process = true;
    const jsonData = this.fS.getJsonFromForm(this.nuevoComponente);
    this.configProyectoMonitoreoService.crearProyectoMonitoreo(this.token, this.proyectoId, jsonData)
      .subscribe((respuesta: ConfigProyectoMonitoreoResponse) => {
        if (respuesta.success) {
          this.toastr.success('Proyecto Gestión creado exitosamente', 'Crear Proyecto Gestión');
          this.dialogRef.close('created');
        }
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
        this.nuevoComponente.enable();
      }).add(() => { this.process = false; });
  }

  getComponentesMonitoreo() {
    this.comboService.getComponentesMonitoreoCombo(this.token)
      .subscribe((respuesta: CombosResponse) => {
        this.componentesMonitoreo = respuesta.data;
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
      });
  }

  volver() {
    this.dialogRef.close();
  }

}
