import { AdministracionDashboardResponse } from './interfaces/administracion-dashboard-response';
import { ErrorService } from './../../util/services/error.service';
import { AdministracionDashboardService } from './services/administracion-dashboard.service';
import { Component, OnInit } from '@angular/core';
import { AdministracionDashboard } from './interfaces/administracion-dashboard';

@Component({
  selector: 'app-administracion',
  templateUrl: './administracion.component.html',
  styleUrls: ['./administracion.component.sass']
})
export class AdministracionComponent implements OnInit {

  heading = 'Administración';
  subheading = 'En este apartado de encuentran todas las informaciones administrativas del Sistema';
  icon = 'pe-7s-display1 icon-gradient bg-happy-fisher';
  adminDasboard: AdministracionDashboard;
  cargando = false;
  constructor(private adminDashboardService: AdministracionDashboardService,
    private errorService: ErrorService) { }

  ngOnInit() {
    this.getDashboard();
  }

  getDashboard() {
    this.cargando = true;
    this.adminDashboardService.getAdministracionDashboard().subscribe((respuesta: AdministracionDashboardResponse) => {
      if (respuesta.success) {
        this.adminDasboard = respuesta.data;
      }
    }, err => {
      this.errorService.handleError(err);
    }).add(() => { this.cargando = false; });
  }

}
