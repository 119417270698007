import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {FormacionDigitalService} from './../services/formacion-digital.service';
import {ToastrService} from 'ngx-toastr';
import {ErrorService} from './../../../../../util/services/error.service';
import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-crear-formacion-digital',
  templateUrl: './crear-formacion-digital.component.html',
  styleUrls: ['./crear-formacion-digital.component.sass']
})
export class CrearFormacionDigitalComponent implements OnInit {

  subiendo: boolean;
  proyectoId: number;
  createForm: FormGroup;
  file: any;

  constructor(public dialogRef: MatDialogRef<CrearFormacionDigitalComponent>, private errorService: ErrorService,
              private toastr: ToastrService, private formacionDigitalService: FormacionDigitalService,
              private fB: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.proyectoId = this.data;
    this.makeForm();
  }

  makeForm() {
    this.createForm = this.fB.group({
      tipo_reporte: [null, Validators.required],
      tipo_participante: [{value: null, disabled: true}, Validators.required]
    });
  }

  subir() {
    if (this.file) {
      this.subiendo = true;
      const formData = new FormData();
      formData.append('tipo_reporte', this.createForm.get('tipo_reporte').value);
      formData.append('tipo_participante', this.createForm.get('tipo_participante').value);
      formData.append('file', this.file);
      this.formacionDigitalService.crearFormacionDigital(this.proyectoId, formData)
        .subscribe((response: any) => {
          if (response.success) {
            this.toastr.success('Reporte cargado exitosamente', 'Formación Digital');
          }
        }, error => {
          this.errorService.handleError(error);
        }, () => {
          this.dialogRef.close(true);
        }).add(() => {
        this.subiendo = false;
      });
    } else {
      this.toastr.info('Es obligatorio subir el reporte', 'Formación Digital');
    }
  }

  onNoClick() {
    this.dialogRef.close();
  }

  fileChangeEvent(files) {
    this.file = files[0];
  }

  setDirigidoA(event) {
    switch (event) {
      case 'A':
        this.createForm.get('tipo_participante').setValue('P');
        break;
      case 'B':
        this.createForm.get('tipo_participante').setValue('P');
        break;
      case 'C':
        this.createForm.get('tipo_participante').setValue('F');
        break;
      case 'D':
        this.createForm.get('tipo_participante').setValue('P');
        break;
      case 'E':
        this.createForm.get('tipo_participante').setValue('P');
        break;
    }
  }
}
