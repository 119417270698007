import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CombosResponse} from '../../../../../util/interfaces/combos-response';
import {CombosService} from '../../../../../util/services/combos/combos.service';
import {ErrorService} from '../../../../../util/services/error.service';
import {LocalStorageService} from '../../../../../util/local-storage.service';
import {Combo} from '../../../../../util/interfaces/combo';
import {DimensionesService} from '../services/dimensiones.service';
import {ToastrService} from 'ngx-toastr';
import {DimensionResponse} from '../interfaces/dimension-response.interface';

@Component({
  selector: 'app-crear-dimension-monitoreo-proyecto',
  templateUrl: './crear-dimension-monitoreo-proyecto.component.html',
  styleUrls: ['./crear-dimension-monitoreo-proyecto.component.sass']
})
export class CrearDimensionMonitoreoProyectoComponent implements OnInit {
  dimensionForm: FormGroup;
  private token;
  idProyecto: number;
  dimensiones: Combo[];

  constructor(
    private router: Router,
    private fB: FormBuilder,
    private toastr: ToastrService,
    private comboService: CombosService,
    private errorService: ErrorService,
    private localStorage: LocalStorageService,
    private dimensionesService: DimensionesService,
    private route: ActivatedRoute
  ) {
    this.token = this.localStorage.getTokenFromStorage();
    this.dimensionForm = fB.group({
        pro_dimension_id: [null, [Validators.required]],
        activo: true,
    });
  }

  ngOnInit() {
    this.idProyecto = +this.route.snapshot.paramMap.get('idProyecto');
    this.getDimensionesCombo();
  }

  crearDimension() {
    this.dimensionesService.crearDimension(this.token, this.idProyecto, JSON.stringify(this.dimensionForm.value))
      .subscribe((respuesta: DimensionResponse) => {
          this.toastr.success('Dimensión creada exitosamente', 'Creación de Dimensión');
          this.volver();
        },
        error => {
          this.toastr.error('Error al crear Dimensión', 'Creación de Dimensión', {toastClass: 'toastr-custom-error'});
        }).add(() => {});
  }
  private getDimensionesCombo() {
    this.comboService.getDimensionesCombo(this.token)
      .subscribe((respuesta: CombosResponse) => {
        this.dimensiones = respuesta.data;
      }, error => {
        this.errorService.handleError(error);
      }).add(() => {});
  }
  evento($event: MouseEvent) {
    $event.preventDefault();
    this.volver();
  }
  volver() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
}
