import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../../../Globals';

@Injectable({
  providedIn: 'root'
})
export class CombosService {

  constructor(private http: HttpClient,
              private global: Globals) {
  }

  getRoles() {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/comboRoles';
    return this.http.get(url, { headers });
  }

  getPersonas() {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/comboPersonas';
    return this.http.get(url, { headers });
  }

  getEstadosCivilesCombo() {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/comboEstadosCiviles';
    return this.http.get(url, { headers });
  }

  getPaisesCombo() {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/comboPaises';
    return this.http.get(url, { headers });
  }

  getActividadesLaboralesCombo() {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/comboActividadesLaborales';
    return this.http.get(url, { headers });
  }

  getTipoDocumentosCombo() {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/comboDocumentosTipos';
    return this.http.get(url, { headers });
  }

  getTipoTelefonosCombo() {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/comboTelefonosTipos';
    return this.http.get(url, { headers });
  }

  getTipoDireccionesCombo() {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/comboDireccionesTipos';
    return this.http.get(url, { headers });
  }

  getTiposContactoCombo() {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/comboContactosTipos';
    return this.http.get(url, { headers });
  }

  getCiudadesCombo() {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/comboCiudades';
    return this.http.get(url, { headers });
  }

  getUsuariosCombo() {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/comboUsers';
    return this.http.get(url, { headers });
  }
  getModulosCombo() {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/comboModulos';
    return this.http.get(url, { headers });
  }
  getFuncionalidadesCombo(idModulo) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/comboModulos/' + idModulo + '/funcionalidades';
    return this.http.get(url, { headers });
  }
  getPermisosCombo(idFuncionalidad) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/comboFuncionalidades/' + idFuncionalidad + '/permisos';
    return this.http.get(url, { headers });
  }

  // Combos FUNDAK

  getProgramas(search = null) {
    const headers = this.global.getHeader();
    const url = this.global.makeUrl('/comboProgramas', search, null);
    return this.http.get(url, { headers });
  }

  getProyectoEstados() {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/comboProyectosEstados';
    return this.http.get(url, { headers });
  }

  getEstadoActoresCombo(token) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/comboActoresEstados';
    return this.http.get(url, { headers });
  }

  getRolActoresCombo(token) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/comboActoresRoles';
    return this.http.get(url, { headers });
  }

  getConfiguracionesCombo(token) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/comboConfiguraciones';
    return this.http.get(url, { headers });
  }

  getComponentesGestionCombo(token) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/comboComponentesGestion';
    return this.http.get(url, { headers });
  }
  getDimensionesCombo(token) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/comboDimensiones';
    return this.http.get(url, { headers });
  }
  getDocumentoTiposCombo(token = null) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/comboProyectosDocumentosTipos';
    return this.http.get(url, { headers });
  }
  getDocumentoEstadosCombo(token) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/comboDocumentosEstados';
    return this.http.get(url, { headers });
  }
  // Se le puede pasar parámetro pro_proyecto_rol_id para traer actores de un tipo específico
  getProyectoActoresCombo(token, idProyecto, parametro = null) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/comboActores/' + idProyecto + (parametro ? '?pro_actor_rol_id=' + parametro : '');
    return this.http.get(url, { headers });
  }

  getComunidadesCombo(token) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/comboComunidades';
    return this.http.get(url, { headers });
  }
  getTipoParticipanteCombo(token) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/comboGruposParticipantesTipos';
    return this.http.get(url, { headers });
  }

  getIndicadoresCombo() {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/comboIndicadores';
    return this.http.get(url, { headers });
  }

  getPreguntasPoolCombo() {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/comboPreguntasPool';
    return this.http.get(url, { headers });
  }





  getDepartamentosPorPaisCombo(token = null, paisId) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/comboPaises/' + paisId + '/departamentos';
    return this.http.get(url, { headers });
  }
  getCiudadesPorDepartamentoCombo(token, departamentoId) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/comboDepartamentos/' + departamentoId + '/ciudades';
    return this.http.get(url, { headers });
  }

  getComponentesMonitoreoCombo(token) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/comboComponentesMonitoreo';
    return this.http.get(url, { headers });
  }

  getComunidadesPorCiudad(token, comunidadId) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/comboCiudades/' + comunidadId + '/comunidades';
    return this.http.get(url, { headers });

  }

  getDepartamentosCombo(idPais) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/comboPaises/' + idPais + '/departamentos';
    return this.http.get(url, { headers });
  }

  getCiudadesCombo2(idDepartamento) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/comboDepartamentos/' + idDepartamento + '/ciudades';
    return this.http.get(url, { headers });
  }

  getProyectoDocumentoTipo(proyectoId) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/comboProyectos/' + proyectoId + '/comboDocumentosTipos';
    return this.http.get(url, { headers });
  }

  getProyectoRoles(proyectoId) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/comboProyectos/' + proyectoId + '/comboActoresRoles';
    return this.http.get(url, { headers });
  }


















  // Combos correspondientes para Encuestas

  getTiposPregunta() {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/comboPreguntasTipo';
    return this.http.get(url, { headers });
  }

  getPortafoliosCombo() {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/comboPortafolios';
    return this.http.get(url, { headers });
  }

  getTemasCombo() {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/comboTemas';
    return this.http.get(url, { headers });
  }

  getInversoresTiposCombo() {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/comboInversoresTipos';
    return this.http.get(url, { headers });
  }

  getModenasPaisCombo(paisId) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/comboInversoresTipos/' + paisId;
    return this.http.get(url, { headers });
  }

  getTalleresFacilitadoresCombo() {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/comboTalleresFacilitadores';
    return this.http.get(url, { headers });
  }

  getTalleresParticipantesCombo() {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/comboTalleresParticipantes';
    return this.http.get(url, { headers });
  }

  getMiembrosGruposCiclos() {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/comboMiembrosGruposCiclos';
    return this.http.get(url, { headers });
  }

  getActoresXProyectoEspecial(search = null) {
    const headers = this.global.getHeader();
    const url = this.global.makeUrl('/comboActoresPosiblesCiclos', search, null);
    return this.http.get(url, { headers });
  }

  getProyectosPersona(persona) {
    const headers = this.global.getHeader();
    const url = this.global.makeUrl(`/comboProyectosPersonas/${persona}`, null, null);
    return this.http.get(url, { headers });
  }
}
