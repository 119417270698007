import { GrupoAhorroService } from './../../../services/grupo-ahorro.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { ErrorService } from 'src/app/util/services/error.service';

@Component({
  selector: 'app-detalle-miembro-grupo-ahorro',
  templateUrl: './detalle-miembro-grupo-ahorro.component.html',
  styleUrls: ['./detalle-miembro-grupo-ahorro.component.sass']
})
export class DetalleMiembroGrupoAhorroComponent implements OnInit {


  selected = null;
  miembro;
  miembroDataSource = [];
  searchForm: FormGroup;
  pagination = {page: 1, per_page: 10};
  cargando: boolean;
  total: number;
  displayedColumns = ['ciclo', 'fecha_entrada', 'fecha_salida', 'estado_ciclo'];
  idProyecto: number;
  idGrupo: number;
  miembroId: number;
  periodo: any;
  periodosForm: any;

  constructor(private fB: FormBuilder,
              private grupoAhorroService: GrupoAhorroService,
              private toastr: ToastrService,
              private errorService: ErrorService,
              private localStorage: LocalStorageService,
              private route: ActivatedRoute,
              private router: Router)
  {
    this.idProyecto = +this.route.snapshot.paramMap.get('idProyecto');
    this.idGrupo = +this.route.snapshot.paramMap.get('idGrupo');
    this.miembroId = +this.route.snapshot.paramMap.get('id');
  }

  ngOnInit(){
    this.getMiembroGrupoAhorro();
    this.makeForm();
  }

  makeForm(){
    this.searchForm = this.fB.group({
      fecha_entrada: '',
      fecha_salida: ''
    });
  }

  getMiembroGrupoAhorro(){
    this.cargando = true;
    this.grupoAhorroService.getMiembroGrupoAhorro(this.idProyecto, this.idGrupo, this.miembroId)
      .subscribe((response: any) => {
        if (response.success) {
          this.miembro = response.data;
          this.miembroDataSource  = response.data.ciclos;
          this.total = response.metadata.total;
        }
      }, error => {
        this.errorService.handleError(error);
      }, ()=> {
        this.cargando = false;
      });
  }

  onClick(event: any) {
    event ? this.filtrar() : this.onReset();
  }

  filtrar() {
    this.pagination.page = 1;
    this.getMiembroGrupoAhorro();
  }

  onReset() {
    this.pagination.page = 1;
    this.searchForm.reset();
    this.getMiembroGrupoAhorro();
  }

  changeBackGroundColor(event, row) {
    this.selected = event.type == 'mouseover' ? row.id : null;
  }

  borrar(){}

  verDetalle(){}

  pageChange() {
    this.getMiembroGrupoAhorro();
  }
  
  volver() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

}
