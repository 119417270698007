import { Component, OnInit } from '@angular/core';
import {LocalStorageService} from '../../../util/local-storage.service';

@Component({
  selector: 'app-reporte',
  templateUrl: './reporte.component.html',
  styleUrls: ['./reporte.component.scss']
})
export class ReporteComponent implements OnInit {

  // Dashboard
  dashboardId: string;
  constructor( private localStorage: LocalStorageService) { }

  ngOnInit() {
    this.getDashboard();
  }

  getDashboard() {
    this.dashboardId = this.localStorage.getParametrosFromStorage().DASHBOARD5_ID;
  }


}
