import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from 'src/app/Globals';

@Injectable({
  providedIn: 'root'
})
export class EncuestasTiposService {

  constructor(private http: HttpClient, private global: Globals) { }

  obtenerEncuestasTipos(search = null, pagination = null) {
    const headers = this.global.getHeader();
    const url: string = this.global.makeUrl('/encuestasTipos', search, pagination);
    return this.http.get(url, { headers });
  }
  obtenerEncuestaTipo(id) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/encuestasTipos/' + id;
    return this.http.get(url, { headers });
  }
  actualizarEncuestaTipo(id, json) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/encuestasTipos/' + id;
    return this.http.put(url, json, { headers });
  }
  crearEncuestaTipo(json) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/encuestasTipos';
    return this.http.post(url, json, { headers });
  }
  borrarEncuestaTipo(id) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/encuestasTipos/' + id;
    return this.http.delete(url, { headers });
  }
}
