import { CrearFacilitadorSubgrupoInstanciaComponent } from './crear-facilitador-subgrupo-instancia/crear-facilitador-subgrupo-instancia.component';
import { FacilitadoresInstanciaService } from './services/facilitadores-instancia.service';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from 'src/app/util/services/error.service';

@Component({
  selector: 'app-facilitadores-subgrupo-instancia',
  templateUrl: './facilitadores-subgrupo-instancia.component.html',
  styleUrls: ['./facilitadores-subgrupo-instancia.component.sass']
})
export class FacilitadoresSubgrupoInstanciaComponent implements OnInit {

  @Input() tallerId: number;
  @Input() instanciaId: number;
  miembros: any[];
  public pagination = { page: 1, per_page: 10 };
  public total: number;
  searchForm: FormGroup;
  selected: any;
  cargando: boolean;
  displayedColumns = ['nombre', 'acciones'];
  constructor(private facilitadoresService: FacilitadoresInstanciaService, private errorService: ErrorService,
    private toastr: ToastrService, private fB: FormBuilder, private dialog: MatDialog) { }

  ngOnInit() {
    this.makeForm();
    this.get();
  }

  makeForm(){
    this.searchForm = this.fB.group({
      presente: ''
    });
  }

  get() {
    this.cargando = true;
    this.facilitadoresService.getList(this.instanciaId, this.searchForm.value, this.pagination)
      .subscribe((response: any) => {
        if (response.success) { }
        this.miembros = response.data;
      }, error => {
        this.errorService.handleError(error);
      }, () => {
        this.cargando = false;
      });
  }

  borrar(id){
    this.facilitadoresService.borrar(this.instanciaId, id)
    .subscribe((response: any) => {
      if(response.success){
        this.toastr.success('Facilitador borrado exitosamente.', 'Borrar Facilitador');
      }
    }, error => {
      this.errorService.handleError(error);
    }, ()=> {
      this.get();
    });
  }

  pageChange() {
    this.get();
  }

  changeBackGroundColor(event, row) {
    this.selected = event.type == 'mouseover' ? row.id : null;
  }

  onClick(event: any) {
    event ? this.filtrar() : this.onReset();
  }

  onReset() {
    this.pagination.page = 1;
    this.searchForm.reset();
    this.get();
  }

  filtrar() {
    this.pagination.page = 1;
    this.get();
  }

  crear(){
    const dialogRef = this.dialog.open(CrearFacilitadorSubgrupoInstanciaComponent, {
      width: '700px',
      data: { tallerId: this.tallerId, instanciaId: this.instanciaId }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.get();
      }
    });
  }

}
