import { CombosResponse } from 'src/app/util/interfaces/combos-response';
import { CombosService } from 'src/app/util/services/combos/combos.service';
import { InversoresService } from './../services/inversores.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from 'src/app/util/services/error.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Combo } from 'src/app/util/interfaces/combo';

@Component({
  selector: 'app-crear-inversor',
  templateUrl: './crear-inversor.component.html',
  styleUrls: ['./crear-inversor.component.sass']
})
export class CrearInversorComponent implements OnInit {

  public nuevoInversor: FormGroup;
  process: boolean;
  public inversorTipos: Combo[];
  constructor(private fB: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private invesoresService: InversoresService,
    private fS: FormStatusService,
    private toastr: ToastrService,
    private errorService: ErrorService,
    private comboService: CombosService) { }

  ngOnInit() {
    this.getInversorTipos();
    this.makeForm();
  }

  getInversorTipos() {
    this.comboService.getInversoresTiposCombo().subscribe((respuesta: CombosResponse) => {
      if (respuesta.success) {
        this.inversorTipos = respuesta.data;
      }
    }, err => {
      this.errorService.handleError(err);
    });
  }

  makeForm() {
    this.nuevoInversor = this.fB.group({
      nombre: [undefined, Validators.required],
      pro_inversor_tipo_id: [undefined, Validators.required],
      descripcion: [undefined]
    });
  }

  crear() {
    this.process = true;
    const jsonData = this.fS.getJsonFromForm(this.nuevoInversor);
    this.invesoresService.crearInversor(jsonData)
      .subscribe((respuesta: any) => {
        if (respuesta.success) {
          this.toastr.success('Aliado/Actor Institucional creado exitosamente', 'Crear Aliado/Actor Institucional');
          this.volver();
        }
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
        this.nuevoInversor.enable();
      }).add(() => { this.process = false; });
  }

  volver() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

}
