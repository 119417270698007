export class ModulosMap {
  [details: string]: Array<string>;
}
export class FuncionalidadMap {
  [details: string]: PermisosMap;
}
export class PermisosMap {
  [details: string]: string;
}


