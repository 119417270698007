import {RespuestaActorComponent} from './monitoreo-proyecto/respuesta-monitoreo-proyecto/detalle-respuesta-monitoreo/tabla-respuestas-monitoreo/respuesta-actor/respuesta-actor.component';
import {DetalleRespuestaMonitoreoComponent} from './monitoreo-proyecto/respuesta-monitoreo-proyecto/detalle-respuesta-monitoreo/detalle-respuesta-monitoreo.component';
import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ProyectosComponent} from './proyectos.component';
import {CrearProyectoComponent} from './crear-proyecto/crear-proyecto.component';
import {DetalleProyectoComponent} from './detalle-proyecto/detalle-proyecto.component';
import {EditarProyectoComponent} from './editar-proyecto/editar-proyecto.component';
import {DocumentacionProyectoComponent} from './documentacion-proyecto/documentacion-proyecto.component';
import {CrearDocumentacionProyectoComponent} from './documentacion-proyecto/crear-documentacion-proyecto/crear-documentacion-proyecto.component';
import {DetalleDocumentacionProyectoComponent} from './documentacion-proyecto/detalle-documentacion-proyecto/detalle-documentacion-proyecto.component';
import {ActoresProyectoComponent} from './actores-proyecto/actores-proyecto.component';
import {CrearActoresComponent} from './actores-proyecto/crear-actores/crear-actores.component';
import {DetalleActoresComponent} from './actores-proyecto/detalle-actores/detalle-actores.component';
import {IndicadoresProyectoComponent} from './indicadores-proyecto/indicadores-proyecto.component';
import {DimensionMonitoreoProyectoComponent} from './monitoreo-proyecto/dimension-monitoreo-proyecto/dimension-monitoreo-proyecto.component';
import {CrearDimensionMonitoreoProyectoComponent} from './monitoreo-proyecto/dimension-monitoreo-proyecto/crear-dimension-monitoreo-proyecto/crear-dimension-monitoreo-proyecto.component';
import {DetalleDimensionMonitoreoProyectoComponent} from './monitoreo-proyecto/dimension-monitoreo-proyecto/detalle-dimension-monitoreo-proyecto/detalle-dimension-monitoreo-proyecto.component';
import {IndicadorMonitoreoProyectoComponent} from './monitoreo-proyecto/indicador-monitoreo-proyecto/indicador-monitoreo-proyecto.component';
import {CrearIndicadorMonitoreoProyectoComponent} from './monitoreo-proyecto/indicador-monitoreo-proyecto/crear-indicador-monitoreo-proyecto/crear-indicador-monitoreo-proyecto.component';
import {DetalleIndicadorMonitoreoProyectoComponent} from './monitoreo-proyecto/indicador-monitoreo-proyecto/detalle-indicador-monitoreo-proyecto/detalle-indicador-monitoreo-proyecto.component';
import {RespuestaMonitoreoProyectoComponent} from './monitoreo-proyecto/respuesta-monitoreo-proyecto/respuesta-monitoreo-proyecto.component';
import {CrearEncuestaMonitoreoComponent} from './monitoreo-proyecto/respuesta-monitoreo-proyecto/crear-encuesta-monitoreo/crear-encuesta-monitoreo.component';
import { ConfigEncuestaProyectoComponent } from './monitoreo-proyecto/config-encuesta-proyecto/config-encuesta-proyecto.component';
import {RespuestaOdkDetalleComponent} from './monitoreo-proyecto/respuesta-monitoreo-proyecto/detalle-respuesta-monitoreo/tabla-respuestas-monitoreo/respuesta-odk-detalle/respuesta-odk-detalle.component';

const routes: Routes = [
  {
    path: '',
    component: ProyectosComponent,
    data: {extraParameter: 'proyectoMenu'}
  },
  {
    path: 'new',
    component: CrearProyectoComponent,
    data: {extraParameter: 'proyectoMenu'}
  },
  {
    path: ':idProyecto',
    children: [
      {
        path: '',
        component: DetalleProyectoComponent,
        data: {extraParameter: 'proyectoMenu'}
      }, {
        path: 'editar-proyecto',
        component: EditarProyectoComponent,
        data: {extraParameter: 'proyectoMenu'}
      },
      {
        path: 'documentaciones',
        children: [{
          path: '',
          component: DocumentacionProyectoComponent,
          data: {extraParameter: 'proyectoMenu'}
        }, {
          path: 'new',
          component: CrearDocumentacionProyectoComponent,
          data: {extraParameter: 'proyectoMenu'}
        }, {
          path: ':id',
          component: DetalleDocumentacionProyectoComponent,
          data: {extraParameter: 'proyectoMenu'}
        }],
        data: {extraParameter: 'proyectoMenu'}
      },
      {
        path: 'actores',
        children: [{
          path: '',
          component: ActoresProyectoComponent,
          data: {extraParameter: 'proyectoMenu'}
        }, {
          path: 'new',
          component: CrearActoresComponent,
          data: {extraParameter: 'proyectoMenu'}
        }, {
          path: ':id',
          component: DetalleActoresComponent,
          data: {extraParameter: 'proyectoMenu'}
        }],
        data: {extraParameter: 'proyectoMenu'}
      }, {
        path: 'indicadores',
        component: IndicadoresProyectoComponent,
        data: {extraParameter: 'proyectoMenu'}
      },
      {
        path: 'monitoreos',
        children: [
          {
            path: 'dimensiones',
            children: [{
              path: '',
              component: DimensionMonitoreoProyectoComponent,
              data: {extraParameter: 'proyectoMenu'}
            }, {
              path: 'new',
              component: CrearDimensionMonitoreoProyectoComponent,
              data: {extraParameter: 'proyectoMenu'}
            }, {
              path: ':id',
              component: DetalleDimensionMonitoreoProyectoComponent,
              data: {extraParameter: 'proyectoMenu'}
            }
            ],
            data: {extraParameter: 'proyectoMenu'}
          },
          {
            path: 'indicadores',
            children: [{
              path: '',
              component: IndicadorMonitoreoProyectoComponent,
              data: {extraParameter: 'proyectoMenu'}
            }, {
              path: 'new',
              component: CrearIndicadorMonitoreoProyectoComponent,
              data: {extraParameter: 'proyectoMenu'}
            }, {
              path: ':id',
              component: DetalleIndicadorMonitoreoProyectoComponent,
              data: {extraParameter: 'proyectoMenu'}
            }
            ],
            data: {extraParameter: 'proyectoMenu'}
          },
          {
            path: 'encuestas',
            children: [
              {
                path: '',
                component: RespuestaMonitoreoProyectoComponent,
                data: {extraParameter: 'proyectoMenu'}
              },
              {
                path: 'new',
                component: CrearEncuestaMonitoreoComponent,
                data: {extraParameter: 'proyectoMenu'}
              },
              {
                path: ':idEncuesta',
                children: [{
                  path: '',
                  component: DetalleRespuestaMonitoreoComponent,
                  data: {extraParameter: 'proyectoMenu'}
                },
                  {
                    path: 'instancia',
                    children: [
                      {
                        path: ':instanciaId',
                        component: RespuestaActorComponent,
                        data: {extraParameter: 'proyectoMenu'}
                      }
                    ]
                  },
                  {
                    path: 'respuesta-odk',
                    children: [
                      {
                        path: ':odk-id',
                        component: RespuestaOdkDetalleComponent,
                        data: {extraParameter: 'proyectoMenu'}
                      }
                    ]
                  }]
              }
            ],
            data: {extraParameter: 'proyectoMenu'}
          }, {
            path: 'encuestas-config',
            children: [
              {
                path: '',
                component: ConfigEncuestaProyectoComponent,
                data: {extraParameter: 'proyectoMenu'}
              }
            ],
            data: {extraParameter: 'proyectoMenu'}
          }
        ],
        data: {extraParameter: 'proyectoMenu'}
      },
      {
        path: 'gestiones',
        loadChildren: './gestion-proyecto/gestion-proyecto.module#GestionProyectoModule',
        data: {extraParameter: 'proyectoMenu'}
      }
    ],
    data: {extraParameter: 'proyectoMenu'}
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProyectosRoutingModule {
}
