import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-detalle-pregunta-tipo',
  templateUrl: './detalle-pregunta-tipo.component.html',
  styleUrls: ['./detalle-pregunta-tipo.component.sass']
})
export class DetallePreguntaTipoComponent implements OnInit {

  preguntaTipoId: number;
  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.preguntaTipoId = +this.route.snapshot.paramMap.get('id');
  }

  volver() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
}
