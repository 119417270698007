import { TemaIndicadorResponse } from './../interfaces/tema-indicador-response';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TemaIndicadorService } from '../services/tema-indicador.service';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from 'src/app/util/services/error.service';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { TemaIndicador } from '../interfaces/tema-indicador';

export interface DialogData {
  temaId: number;
  indicadorId: number;
}

@Component({
  selector: 'app-detalle-tema-indicador',
  templateUrl: './detalle-tema-indicador.component.html',
  styleUrls: ['./detalle-tema-indicador.component.sass']
})
export class DetalleTemaIndicadorComponent implements OnInit {

  public indicadorForm: FormGroup;
  editable = true;
  indicadorId: number;
  temaId: number;
  cargando: boolean;
  private token;
  indicador: TemaIndicador;
  process: boolean;

  constructor(public dialogRef: MatDialogRef<DetalleTemaIndicadorComponent>, private fB: FormBuilder, private router: Router, private route: ActivatedRoute,
    private temaIndicadorService: TemaIndicadorService, private fS: FormStatusService, private toastr: ToastrService,
    private errorService: ErrorService, private localStorage: LocalStorageService, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
    this.temaId = this.data.temaId;
    this.indicadorId = this.data.indicadorId;
    this.token = this.localStorage.getTokenFromStorage();
    this.makeForm();
    this.get();
  }

  makeForm() {
    this.indicadorForm = this.fB.group({
      nombre: [{ value: null, disabled: this.editable }, Validators.required],
      codigo: [{ value: null, disabled: this.editable }, Validators.required],
      descripcion: [{ value: null, disabled: this.editable }],
      preguntas: this.fB.array([])
    });
  }

  get() {
    this.cargando = true;
    this.temaIndicadorService.getTemaIndicador(this.temaId, this.indicadorId, this.token)
      .subscribe((respuesta: TemaIndicadorResponse) => {
        this.indicador = respuesta.data;
        this.indicadorForm.patchValue(this.indicador);
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
        this.cargando = false;
      });
  }
  actualizar() {
    this.process = true;
    const jsonData = this.fS.getJsonFromForm(this.indicadorForm);
    this.temaIndicadorService.actualizarTemaIndicador(this.temaId, this.indicadorId, this.token, jsonData)
      .subscribe((respuesta: TemaIndicadorResponse) => {
        if (respuesta.success) {
          this.toastr.success('Indicador actualizado correctamente', 'Actualizar Indicador');
          this.get();
          this.editableSwitcher();
          this.dialogRef.close('updated');
        }
      }, error => {
        this.errorService.handleError(error);
        this.indicadorForm.enable();
      }
      ).add(() => { this.process = false; });
  }

  volver() {
    this.dialogRef.close();
  }

  public editableSwitcher() {
    this.indicadorForm = this.fS.cambiarEstado(this.indicadorForm, this.editable);
    this.editable = !this.editable;
  }

  // Preguntas
  addPreguntaForm(): FormGroup {
    return this.fB.group({
      pregunta_id: undefined,
    });
  }

  addDocumentoBtnClick() {
    (this.indicadorForm.controls.preguntas as FormArray).push(this.addPreguntaForm());
  }

  quitarPregunta(id){
    // Implements here
  }

}
