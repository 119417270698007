import { RespuestaMonitoreoService } from './../services/respuesta-monitoreo.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from 'src/app/util/services/error.service';
import { RespuestaMonitoreoProyectoResponse } from '../interfaces/respuesta-monitoreo-proyecto-response';

@Component({
  selector: 'app-crear-encuesta-monitoreo',
  templateUrl: './crear-encuesta-monitoreo.component.html',
  styleUrls: ['./crear-encuesta-monitoreo.component.sass']
})
export class CrearEncuestaMonitoreoComponent implements OnInit {

  public ocultarConfig = true;
  nuevaEncuesta: FormGroup;
  process: boolean;
  proyectoId: number;
  file: any;
  @ViewChild('inputGroupFile02')
  labelImport: ElementRef;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fB: FormBuilder,
    private fS: FormStatusService,
    private toastr: ToastrService,
    private errorService: ErrorService,
    private respuestaMonitoreoService: RespuestaMonitoreoService
  ) { }

  ngOnInit() {
    this.proyectoId = +this.route.snapshot.paramMap.get('idProyecto');
    this.makeForm();
  }

  makeForm() {
    this.nuevaEncuesta = this.fB.group({
      activo: [null, Validators.required],
      descripcion: [null],
      fecha_desde: [null, Validators.required],
      fecha_hasta: [null, Validators.required],
      nombre: [null, Validators.required],
      enc_encuesta_tipo_id: [null, Validators.required]
    });
  }

  agregarEncuesta() {
    if (this.file) {
      this.process = true;
      const formData = new FormData();
      formData.append('nombre', this.nuevaEncuesta.get('nombre').value);
      formData.append('fecha_desde', this.nuevaEncuesta.get('fecha_desde').value);
      formData.append('fecha_hasta', this.nuevaEncuesta.get('fecha_hasta').value);
      formData.append('activo', this.nuevaEncuesta.get('activo').value);
      formData.append('enc_encuesta_tipo_id', this.nuevaEncuesta.get('enc_encuesta_tipo_id').value);
      if (this.nuevaEncuesta.get('descripcion').value) {
        formData.append('descripcion', this.nuevaEncuesta.get('descripcion').value);
      }
      formData.append('file', this.file);
      this.respuestaMonitoreoService.crearEncuesta(this.proyectoId, formData).subscribe((respuesta: RespuestaMonitoreoProyectoResponse) => {
        this.toastr.success('Encuesta creada exitosamente', 'Creación de Encuesta');
        this.goToEncuesta(respuesta.data.id);
      }, error => {
        this.errorService.handleError(error);
        this.nuevaEncuesta = this.fS.cambiarEstado(this.nuevaEncuesta, true);
        this.process = false;
      },
        () => { this.process = false; }
      );
    } else {
      this.toastr.info('Adjuntar un archivo excel es obligatorio', 'Atención');
    }
  }
  cambiarEstadoConfig() {
  }

  volver() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  goToEncuesta(id) {
    this.router.navigate(['../', id], { relativeTo: this.route });
  }

  fileChangeEvent(files): void {
    this.file = files[0];
    this.labelImport.nativeElement.innerText = files[0].name;
  }

  evento($event: MouseEvent) {
    $event.preventDefault();
    this.volver();
  }
}
