import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Globals} from '../../../Globals';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OdkService {

  constructor(private http: HttpClient) {
  }

  login(jsonData) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json'
    });
    const url = `${environment.ODK_CENTRAL_ENDPOINT}/sessions`;
    return this.http.post(url, jsonData, {headers});
  }

  /*Local Methods*/
  getHeaderODK(token = null) {
    return new HttpHeaders(
      {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      });
  }


}
