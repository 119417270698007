import { LottieAnimationViewModule } from 'ng-lottie';
import { ExcelErrorViewerModule } from './../../../util/excel-error-viewer/excel-error-viewer.module';
import { AddMiembroModule } from './../../../util/add-miembro/add-miembro.module';
import {MatChipsModule} from '@angular/material/chips';
import {BotonFiltroModule} from 'src/app/util/boton-filtro/boton-filtro.module';
import {FlexLayoutModule, FlexModule} from '@angular/flex-layout';
import {MatInputModule} from '@angular/material/input';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatTabsModule} from '@angular/material/tabs';
import {SpinnerModule} from 'src/app/util/spinner/spinner.module';
import {BotoneraEdicionModule} from 'src/app/util/botonera-edicion/botonera-edicion.module';
import {BotoneraCreacionModule} from 'src/app/util/botonera-creacion/botonera-creacion.module';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DatosNoEncontradosModule} from 'src/app/util/datos-no-encontrados/datos-no-encontrados.module';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {SwalComponentModule} from './../../../util/swal-component/swal-component.module';
import {MatTableModule} from '@angular/material/table';
import {NgxPermissionsModule} from 'ngx-permissions';
import {AngularFontAwesomeModule} from 'angular-font-awesome';
import {WorkingOnItModule} from 'src/app/util/working-on-it/working-on-it.module';
import {NgModule, LOCALE_ID} from '@angular/core';
import {CommonModule} from '@angular/common';

import {GestionProyectoRoutingModule} from './gestion-proyecto-routing.module';
import {FocalizacionGestionProyectoComponent} from './focalizacion-gestion-proyecto/focalizacion-gestion-proyecto.component';
import {DetalleTallerGestionProyectoComponent} from './talleres-gestion-proyecto/detalle-taller-gestion-proyecto/detalle-taller-gestion-proyecto.component';
import {TalleresGestionProyectoComponent} from './talleres-gestion-proyecto/talleres-gestion-proyecto.component';
import {FormacionDigitalGestionProyectoComponent} from './formacion-digital-gestion-proyecto/formacion-digital-gestion-proyecto.component';
import {GrupoAhorroGestionProyectoComponent} from './grupo-ahorro-gestion-proyecto/grupo-ahorro-gestion-proyecto.component';
import {CrearGrupoComponent} from './grupo-ahorro-gestion-proyecto/crear-grupo/crear-grupo.component';
import {DetalleGrupoGestionComponent} from './grupo-ahorro-gestion-proyecto/detalle-grupo-gestion/detalle-grupo-gestion.component';
import {NegocioSeguimientoGestionProyectoComponent} from './negocio-seguimiento-gestion-proyecto/negocio-seguimiento-gestion-proyecto.component';
import {MercadoGestionProyectoComponent} from './mercado-gestion-proyecto/mercado-gestion-proyecto.component';
import {MenuDetalleProyectoModule} from '../menu-detalle-proyecto/menu-detalle-proyecto.module';
import {TallerParticipanteComponent} from './talleres-gestion-proyecto/detalle-taller-gestion-proyecto/taller-participante/taller-participante.component';
import {CrearTallerParticipanteComponent} from './talleres-gestion-proyecto/detalle-taller-gestion-proyecto/taller-participante/crear-taller-participante/crear-taller-participante.component';
import {DetalleTallerParticipanteComponent} from './talleres-gestion-proyecto/detalle-taller-gestion-proyecto/taller-participante/detalle-taller-participante/detalle-taller-participante.component';
import {TallerFormadorComponent} from './talleres-gestion-proyecto/detalle-taller-gestion-proyecto/taller-formador/taller-formador.component';
import {CrearTallerFormadorComponent} from './talleres-gestion-proyecto/detalle-taller-gestion-proyecto/taller-formador/crear-taller-formador/crear-taller-formador.component';
import {DetalleTallerFormadorComponent} from './talleres-gestion-proyecto/detalle-taller-gestion-proyecto/taller-formador/detalle-taller-formador/detalle-taller-formador.component';
import {MatButtonModule, MatCheckboxModule, MatIconModule, MatOptionModule} from '@angular/material';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {DetalleTallerInstanciaComponent} from './talleres-gestion-proyecto/detalle-taller-gestion-proyecto/taller-formador/detalle-taller-instancia/detalle-taller-instancia.component';
import { CrearCicloComponent } from './grupo-ahorro-gestion-proyecto/detalle-grupo-gestion/periodos/crear-ciclo/crear-ciclo.component';
import { CrearMiembroComponent } from './grupo-ahorro-gestion-proyecto/detalle-grupo-gestion/periodos/miembros/crear-miembro/crear-miembro.component';
import { DetalleMiembroComponent } from './grupo-ahorro-gestion-proyecto/detalle-grupo-gestion/periodos/miembros/detalle-miembro/detalle-miembro.component';
import { MiembrosComponent } from './grupo-ahorro-gestion-proyecto/detalle-grupo-gestion/periodos/miembros/miembros.component';
import { PeriodosComponent } from './grupo-ahorro-gestion-proyecto/detalle-grupo-gestion/periodos/detalle-ciclos/periodos.component';
import { MiembrosGruposAhorroComponent } from './grupo-ahorro-gestion-proyecto/detalle-grupo-gestion/miembros-grupo-ahorro/miembros-grupo-ahorro.component';
import { ListaMiembrosPagosComponent } from './grupo-ahorro-gestion-proyecto/detalle-grupo-gestion/periodos/miembros/lista-miembros-pagos/lista-miembros-pagos.component';
import { RegistroPagosMiembroComponent } from './grupo-ahorro-gestion-proyecto/detalle-grupo-gestion/periodos/miembros/registro-pagos-miembro/registro-pagos-miembro.component';
import { ReunionesComponent } from './grupo-ahorro-gestion-proyecto/detalle-grupo-gestion/periodos/reuniones/reuniones.component';
import { CrearReunionComponent } from './grupo-ahorro-gestion-proyecto/detalle-grupo-gestion/periodos/reuniones/crear-reunion/crear-reunion.component';
import { DetalleReunionComponent } from './grupo-ahorro-gestion-proyecto/detalle-grupo-gestion/periodos/reuniones/detalle-reunion/detalle-reunion.component';
import { AsistentesReunionComponent } from './grupo-ahorro-gestion-proyecto/detalle-grupo-gestion/periodos/reuniones/asistentes-reunion/asistentes-reunion.component';
import { DetalleMiembroGrupoAhorroComponent } from './grupo-ahorro-gestion-proyecto/detalle-grupo-gestion/miembros-grupo-ahorro/detalle-miembro-grupo-ahorro/detalle-miembro-grupo-ahorro.component';
import { ApptitudeReportComponent } from './formacion-digital-gestion-proyecto/apptitude-report/apptitude-report.component';
import { CrearFormacionDigitalComponent } from './formacion-digital-gestion-proyecto/crear-formacion-digital/crear-formacion-digital.component';
import { ListadoCabecerasComponent } from './formacion-digital-gestion-proyecto/listado-cabeceras/listado-cabeceras.component';
import { CrearTallerGestionProyectoComponent } from './talleres-gestion-proyecto/crear-taller-gestion-proyecto/crear-taller-gestion-proyecto.component';
import { SubgrupoInstanciaComponent } from './talleres-gestion-proyecto/detalle-taller-gestion-proyecto/taller-formador/detalle-taller-instancia/subgrupo-instancia/subgrupo-instancia.component';
import { CrearSubgrupoMiembroComponent } from './talleres-gestion-proyecto/detalle-taller-gestion-proyecto/taller-formador/detalle-taller-instancia/subgrupo-instancia/crear-subgrupo-miembro/crear-subgrupo-miembro.component';
import { DetalleSubgrupoMiembroComponent } from './talleres-gestion-proyecto/detalle-taller-gestion-proyecto/taller-formador/detalle-taller-instancia/subgrupo-instancia/detalle-subgrupo-miembro/detalle-subgrupo-miembro.component';
import { registerLocaleData } from '@angular/common';
import localePy from '@angular/common/locales/es-PY';
import { ReunionesCrudComponent } from './grupo-ahorro-gestion-proyecto/detalle-grupo-gestion/periodos/detalle-ciclos/reuniones-crud/reuniones-crud.component';
import { CrearReunionDiaComponent } from './grupo-ahorro-gestion-proyecto/detalle-grupo-gestion/periodos/detalle-ciclos/reuniones-crud/crear-reunion-dia/crear-reunion-dia.component';
import { NumberFormatPipe } from 'src/app/util/pipes/numberFormat.pipe';
import { AppPipesModule } from 'src/app/util/pipes/app-pipes.module';
import { EditarGrupoAhorroComponent } from './grupo-ahorro-gestion-proyecto/detalle-grupo-gestion/editar-grupo-ahorro/editar-grupo-ahorro.component';
import { DesactivarMiembroComponent } from './grupo-ahorro-gestion-proyecto/detalle-grupo-gestion/periodos/miembros/desactivar-miembro/desactivar-miembro.component';
import { ExcelErrorViewerComponent } from 'src/app/util/excel-error-viewer/excel-error-viewer.component';
import { FacilitadoresSubgrupoInstanciaComponent } from './talleres-gestion-proyecto/detalle-taller-gestion-proyecto/taller-formador/detalle-taller-instancia/facilitadores-subgrupo-instancia/facilitadores-subgrupo-instancia.component';
import { CrearFacilitadorSubgrupoInstanciaComponent } from './talleres-gestion-proyecto/detalle-taller-gestion-proyecto/taller-formador/detalle-taller-instancia/facilitadores-subgrupo-instancia/crear-facilitador-subgrupo-instancia/crear-facilitador-subgrupo-instancia.component';
import { InformacionMiembroExcelComponent } from './talleres-gestion-proyecto/detalle-taller-gestion-proyecto/taller-participante/informacion-miembro-excel/informacion-miembro-excel.component';
import { TallerFacilitadoresComponent } from './talleres-gestion-proyecto/detalle-taller-gestion-proyecto/taller-facilitadores/taller-facilitadores.component';
import { InformacionExcelMiembroGrupoAhorroComponent } from './grupo-ahorro-gestion-proyecto/detalle-grupo-gestion/periodos/miembros/informacion-excel-miembro-grupo-ahorro/informacion-excel-miembro-grupo-ahorro.component';
import { InformacionReportesExcelComponent } from './formacion-digital-gestion-proyecto/informacion-reportes-excel/informacion-reportes-excel.component';
import {NgxDashboardsBixModule} from '../../../util/ngx-dashboards-bix/ngx-dashboards-bix.module';
registerLocaleData(localePy, 'es-PY');



@NgModule({
  declarations: [
    FocalizacionGestionProyectoComponent,
    FormacionDigitalGestionProyectoComponent,
    GrupoAhorroGestionProyectoComponent,
    CrearGrupoComponent,
    DetalleGrupoGestionComponent,
    NegocioSeguimientoGestionProyectoComponent,
    MercadoGestionProyectoComponent,
    // Talleres
    TalleresGestionProyectoComponent,
    DetalleTallerGestionProyectoComponent,
    TallerParticipanteComponent,
    CrearTallerParticipanteComponent,
    DetalleTallerParticipanteComponent,
    TallerFormadorComponent,
    CrearTallerFormadorComponent,
    DetalleTallerFormadorComponent,
    DetalleTallerInstanciaComponent,
    CrearMiembroComponent,
    DetalleMiembroComponent,
    MiembrosComponent,
    PeriodosComponent,
    CrearCicloComponent,
    MiembrosGruposAhorroComponent,
    ListaMiembrosPagosComponent,
    RegistroPagosMiembroComponent,
    ReunionesComponent,
    CrearReunionComponent,
    DetalleReunionComponent,
    AsistentesReunionComponent,
    DetalleMiembroGrupoAhorroComponent,
    ApptitudeReportComponent,
    CrearFormacionDigitalComponent,
    ListadoCabecerasComponent,
    CrearTallerGestionProyectoComponent,
    SubgrupoInstanciaComponent,
    CrearSubgrupoMiembroComponent,
    DetalleSubgrupoMiembroComponent,
    ReunionesCrudComponent,
    CrearReunionDiaComponent,
    EditarGrupoAhorroComponent,
    DesactivarMiembroComponent,
    FacilitadoresSubgrupoInstanciaComponent,
    CrearFacilitadorSubgrupoInstanciaComponent,
    InformacionMiembroExcelComponent,
    TallerFacilitadoresComponent,
    InformacionExcelMiembroGrupoAhorroComponent,
    InformacionReportesExcelComponent

  ],
    imports: [
        CommonModule,
        GestionProyectoRoutingModule,
        MenuDetalleProyectoModule,
        WorkingOnItModule,
        AngularFontAwesomeModule,
        NgxPermissionsModule.forChild(),
        MatTableModule,
        SwalComponentModule,
        SweetAlert2Module,
        NgbPaginationModule,
        DatosNoEncontradosModule,
        NgSelectModule,
        BotoneraCreacionModule,
        BotoneraEdicionModule,
        FormsModule,
        ReactiveFormsModule,
        SpinnerModule,
        MatTabsModule,
        MatFormFieldModule,
        MatSelectModule,
        MatDialogModule,
        MatInputModule,
        FlexModule,
        BotonFiltroModule,
        MatChipsModule,
        MatSlideToggleModule,
        MatCheckboxModule,
        MatIconModule,
        MatButtonModule,
        FlexLayoutModule,
        AddMiembroModule,
        AppPipesModule,
        ExcelErrorViewerModule,
        LottieAnimationViewModule,
        NgxDashboardsBixModule
    ],
  entryComponents: [
    // Taller Entries
    CrearTallerParticipanteComponent,
    DetalleTallerParticipanteComponent,
    CrearTallerFormadorComponent,
    DetalleTallerFormadorComponent,
    CrearMiembroComponent,
    RegistroPagosMiembroComponent,
    CrearFormacionDigitalComponent,
    CrearSubgrupoMiembroComponent,
    DetalleSubgrupoMiembroComponent,
    CrearReunionDiaComponent,
    EditarGrupoAhorroComponent,
    DesactivarMiembroComponent,
    CrearFacilitadorSubgrupoInstanciaComponent,
    InformacionMiembroExcelComponent,
    InformacionExcelMiembroGrupoAhorroComponent,
    InformacionReportesExcelComponent
  ],
  providers: [ { provide: LOCALE_ID, useValue: 'es-PY' }],
})
export class GestionProyectoModule {
}
