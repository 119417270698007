import { NgxPermissionsService } from 'ngx-permissions';
import { TallerTipoResponse, TalleresTiposResponse } from './interfaces/taller-tipo-response';
import { TallerTipoService } from './services/taller-tipo.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ErrorService } from 'src/app/util/services/error.service';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TallerTipo } from './interfaces/taller-tipo';

@Component({
  selector: 'app-talleres-tipos',
  templateUrl: './talleres-tipos.component.html',
  styleUrls: ['./talleres-tipos.component.sass']
})
export class TalleresTiposComponent implements OnInit {

  heading = 'Talleres tipos';
  subheading = 'En este módulo se encuentra la lista de talleres tipos.';
  icon = 'pe-7s-study icon-gradient bg-ripe-malin';
  cargando: boolean;
  total: number;
  pagination = { page: 1, per_page: 10 };
  selected = null;
  displayedColumns = ['nombre', 'codigo', 'descripcion'];

  private token;
  talleresTipo: TallerTipo[];
  searchForm: FormGroup;
  constructor(private router: Router, private route: ActivatedRoute, private fB: FormBuilder, private errorService: ErrorService,
    private toastr: ToastrService, private localStorage: LocalStorageService, private talleresTiposService: TallerTipoService,
    private permissionService: NgxPermissionsService) { }

  ngOnInit() {
    this.token = this.localStorage.getTokenFromStorage();
    this.makeSearchForm();
    this.getTalleresTipo();
  }

  makeSearchForm() {
    this.searchForm = this.fB.group({
      nombre: '',
      codigo: ''
    });
  }

  getTalleresTipo() {
    this.cargando = true;
    this.talleresTiposService.getTalleresTipos(this.token, this.searchForm.value, this.pagination)
      .subscribe((respuesta: TalleresTiposResponse) => {
        this.talleresTipo = respuesta.data;
        this.total = respuesta.metadata.total;
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
        this.cargando = false;
      });
  }

  crear() {
    this.router.navigate(['./new'], { relativeTo: this.route });
  }

  verDetalle(id: number) {
    this.permissionService.hasPermission('PARPRO/mant_talleres_tipo/get467').then(result => {
      if (result) {
        this.router.navigate(['./', id], { relativeTo: this.route });
      }
    });
  }

  borrar(id: number) {
    this.talleresTiposService.borrarTallerTipo(id, this.token)
      .subscribe((respuesta: TallerTipoResponse) => {
        if (respuesta.success) {
          this.toastr.success('Taller tipo borrado exitosamente', 'Borrar Taller Tipo');
        }
        this.getTalleresTipo();
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
      }, () => { });
  }

  onReset() {
    this.pagination.page = 1;
    this.searchForm.reset();
    this.getTalleresTipo();
  }

  filtrar() {
    this.pagination.page = 1;
    this.getTalleresTipo();
  }

  pageChange() {
    this.getTalleresTipo();
  }
  onClick(event: any) {
    event ? this.filtrar() : this.onReset();
  }

  changeBackGroundColor(event, row) {
    this.selected = event.type == 'mouseover' ? row.id : null;
  }

  evento($event: MouseEvent) {
    $event.preventDefault();
    this.volver();
  }

  volver() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }
}
