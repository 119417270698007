import { TallerTipoService } from './../services/taller-tipo.service';
import { Component, OnInit } from '@angular/core';
import { TallerTipoResponse } from '../interfaces/taller-tipo-response';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { ErrorService } from 'src/app/util/services/error.service';
import { ToastrService } from 'ngx-toastr';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-crear-taller-tipo',
  templateUrl: './crear-taller-tipo.component.html',
  styleUrls: ['./crear-taller-tipo.component.sass']
})
export class CrearTallerTipoComponent implements OnInit {

  public nuevoTallerTipo: FormGroup;
  private token;
  process: boolean;
  constructor(private fB: FormBuilder, private router: Router, private route: ActivatedRoute,
    private localStorage: LocalStorageService, private errorService: ErrorService, private toastr: ToastrService, private fS: FormStatusService,
    private tallerTipoService: TallerTipoService) { }

  ngOnInit() {
    this.token = this.localStorage.getTokenFromStorage();
    this.makeForm();
  }

  makeForm() {
    this.nuevoTallerTipo = this.fB.group({
      nombre: [null, Validators.required],
      descripcion: [null]
    });
  }

  crear() {
    this.process = true;
    const jsonData = this.fS.getJsonFromForm(this.nuevoTallerTipo);
    this.tallerTipoService.crearTallerTipo(this.token, jsonData)
      .subscribe((respuesta: TallerTipoResponse) => {
        if (respuesta.success) {
          this.toastr.success('Taller tipo creado exitosamente', 'Crear Taller tipo');
          this.volver();
        }
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
        this.nuevoTallerTipo.enable();
      }).add(() => { this.process = false; });
  }

  volver() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

}
