import { NgxPermissionsService } from 'ngx-permissions';
import { DimensionResponse } from './../interfaces/dimension-response';
import { DimensionService } from './../services/dimension.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { ErrorService } from 'src/app/util/services/error.service';
import { ToastrService } from 'ngx-toastr';
import { Dimension } from '../interfaces/dimension';

@Component({
  selector: 'app-detalle-dimension',
  templateUrl: './detalle-dimension.component.html',
  styleUrls: ['./detalle-dimension.component.sass']
})
export class DetalleDimensionComponent implements OnInit {

  public dimensionForm: FormGroup;
  editable = true;
  private token;
  dimensionId: number;
  cargando: boolean;
  process: boolean;
  dimension: Dimension;
  tabInicial = 2;
  puedeListarTemas = true;

  constructor(private fB: FormBuilder, private router: Router, private route: ActivatedRoute, private fS: FormStatusService,
    private localStorage: LocalStorageService, private errorService: ErrorService, private dimensionService: DimensionService,
    private toastr: ToastrService, private permissionService: NgxPermissionsService) { }

  ngOnInit() {
    this.dimensionId = +this.route.snapshot.paramMap.get('dimensionId');
    this.token = this.localStorage.getTokenFromStorage();
    this.makeForm();
    this.get();
  }

  makeForm() {
    this.dimensionForm = this.fB.group({
      nombre: [{ value: undefined, disabled: this.editable }, Validators.required],
      codigo: [{ value: undefined, disabled: this.editable }, Validators.required],
      orden: [{ value: undefined, disabled: this.editable }, Validators.required],
      descripcion: [{ value: undefined, disabled: this.editable }]
    });
  }

  get() {
    this.cargando = true;
    this.dimensionService.getDimension(this.dimensionId, this.token)
      .subscribe((respuesta: DimensionResponse) => {
        this.dimension = respuesta.data;
        this.dimensionForm.patchValue(this.dimension);
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
        this.verificarPermisos();
        this.cargando = false;
      });
  }

  verificarPermisos() {
    this.permissionService.hasPermission('"PARPRO/mant_temas/list430"').then(result => {
      if (result) {
        this.puedeListarTemas = result;
        this.tabInicial = 2;
      } else {
        this.tabInicial = 1;
      }
    });
  }

  actualizar() {
    this.process = true;
    const jsonData = this.fS.getJsonFromForm(this.dimensionForm);
    this.dimensionService.actualizarDimension(this.dimensionId, this.token, jsonData)
      .subscribe((respuesta: DimensionResponse) => {
        if (respuesta.success) {
          this.toastr.success('Dimensión actualizada correctamente', 'Actualizar Dimensión');
          this.get();
          this.editableSwitcher();
        }
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => { this.process = false; });
  }

  volver() {
    this.router.navigate(['../../../'], { relativeTo: this.route });
  }

  public editableSwitcher() {
    this.dimensionForm = this.fS.cambiarEstado(this.dimensionForm, this.editable);
    this.editable = !this.editable;
  }

}
