import { ComponenteMonitoreoService } from './../../../../parametricos-proyecto/componentes-monitoreo/services/componente-monitoreo.service';
import { ConfigProyectoMonitoreoService } from './../services/config-proyecto-monitoreo.service';
import { Component, OnInit, Inject } from '@angular/core';
import { ConfigProyectoMonitoreo } from '../interfaces/config-proyecto-monitoreo';
import { ConfigProyectoMonitoreoResponse } from '../interfaces/config-proyecto-monitoreo-response';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Combo } from 'src/app/util/interfaces/combo';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CombosService } from 'src/app/util/services/combos/combos.service';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from 'src/app/util/services/error.service';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { CombosResponse } from 'src/app/util/interfaces/combos-response';

export interface DialogData {
  proyectoId: number;
  componenteMonitoreoId: number;
}
@Component({
  selector: 'app-detalle-proyecto-monitoreo',
  templateUrl: './detalle-proyecto-monitoreo.component.html',
  styleUrls: ['./detalle-proyecto-monitoreo.component.sass']
})
export class DetalleProyectoMonitoreoComponent implements OnInit {

  public proyectoMonitoreoForm: FormGroup;
  editable = true;
  componenteMonitoreoId: number;
  proyectoId: number;
  cargando: boolean;
  process: boolean;
  private token;
  componenteMonitoreo: ConfigProyectoMonitoreo;
  componentesMonitoreos: Combo[];

  constructor(public dialogRef: MatDialogRef<DetalleProyectoMonitoreoComponent>, private fB: FormBuilder, private comboService: CombosService,
    private configProyectoMonitoreoService: ConfigProyectoMonitoreoService, private fS: FormStatusService, private toastr: ToastrService,
    private errorService: ErrorService, private localStorage: LocalStorageService, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
    this.proyectoId = this.data.proyectoId;
    this.componenteMonitoreoId = this.data.componenteMonitoreoId;
    this.token = this.localStorage.getTokenFromStorage();
    this.makeForm();
    this.getComponentesMonitoreoCombo();
    this.get();
  }

  makeForm() {
    this.proyectoMonitoreoForm = this.fB.group({
      pro_componente_monitoreo_id: [{ value: null, disabled: this.editable }, Validators.required],
    });
  }

  get() {
    this.cargando = true;
    this.configProyectoMonitoreoService.getProyectoMonitoreo(this.proyectoId, this.componenteMonitoreoId, this.token)
      .subscribe((respuesta: ConfigProyectoMonitoreoResponse) => {
        this.componenteMonitoreo = respuesta.data;
        this.proyectoMonitoreoForm.patchValue(this.componenteMonitoreo);
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
        this.cargando = false;
      });
  }
  actualizar() {
    this.process = true;
    const jsonData = this.fS.getJsonFromForm(this.proyectoMonitoreoForm);
    this.configProyectoMonitoreoService.actualizarProyectoMonitoreo(this.proyectoId, this.componenteMonitoreoId, this.token, jsonData)
      .subscribe((respuesta: ConfigProyectoMonitoreoResponse) => {
        if (respuesta.success) {
          this.toastr.success('Proyecto Monitoreo actualizado correctamente', 'Actualizar Proyecto Monitoreo');
          this.get();
          this.editableSwitcher();
          this.dialogRef.close('updated');
        }
      }, error => {
        this.errorService.handleError(error);
        this.proyectoMonitoreoForm.enable();
      }).add(() => { this.process = false; });
  }

  volver() {
    this.dialogRef.close();
  }

  public editableSwitcher() {
    this.proyectoMonitoreoForm = this.fS.cambiarEstado(this.proyectoMonitoreoForm, this.editable);
    this.editable = !this.editable;
  }

  getComponentesMonitoreoCombo() {
    this.comboService.getComponentesMonitoreoCombo(this.token)
      .subscribe((respuesta: CombosResponse) => {
        this.componentesMonitoreos = respuesta.data;
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
      });
  }

}
