import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {RespuestaMonitoreoService} from '../../services/respuesta-monitoreo.service';
import {ErrorService} from '../../../../../../util/services/error.service';
import {ToastrService} from 'ngx-toastr';
import {saveAs} from 'file-saver';

@Component({
  selector: 'app-tabla-respuestas-quanti',
  templateUrl: './tabla-respuestas-quanti.component.html',
  styleUrls: ['./tabla-respuestas-quanti.component.scss']
})
export class TablaRespuestasQuantiComponent implements OnInit {

  displayedColumns = ['nombre', 'apellido', 'sexo', 'edad', 'fecha'];
  @Input() encuestaId: number;
  @Input() xmlFormId: string;
  @Input() proyectoId: number;
  total: number;
  selected = null;
  search: FormGroup;
  pagination = {page: 1, per_page: 10};
  cargando: boolean;
  processDownLoadRespuestas: boolean;
  processProcessRespuestas: boolean;
  instancias: any;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private fB: FormBuilder,
              private respuestaMonitoreoService: RespuestaMonitoreoService,
              private errorService: ErrorService,
              private toastr: ToastrService) {
  }

  ngOnInit() {
    this.makeForm();
    this.getInstanciasActores();
  }

  makeForm() {
    this.search = this.fB.group({
      documento: null,
      nombre: null,
      apellido: null,
      sexo: null
    });
  }

  getInstanciasActores() {
    this.cargando = true;
    this.respuestaMonitoreoService.getInstanciasActores(this.encuestaId, this.pagination)
      .subscribe((respuesta: any) => {
        this.instancias = respuesta.data;
        this.total = respuesta.metadata.total;
      }, error => {
        this.errorService.handleError(error);
      }, () => {
        this.cargando = false;
      });
  }

  changeBackGroundColor(event, row) {
    this.selected = event.type == 'mouseover' ? row.id : null;
  }

  pageChange() {
    this.getInstanciasActores();
  }

  onClick(event: any) {
    event ? this.filtrar() : this.onReset();
  }

  onReset() {
    this.pagination.page = 1;
    //  this.search.reset();
    //  this.getEncuestas();
  }

  filtrar() {
    this.pagination.page = 1;
    // this.getEncuestas();
  }

  exportarRespuestas() {
    // TODO: Aquí se debería de descargar desde instancias respuestas
    this.processDownLoadRespuestas = true;
    this.respuestaMonitoreoService.exportarRespuestasEncuesta(this.proyectoId, this.encuestaId)
      .subscribe((response: any) => {
          const newBlob = response.slice(1, response.size, {type: 'application/zip'});
          saveAs(newBlob, `${this.xmlFormId}.zip`);
        }, error => {
          this.errorService.handleError(error);
        }
      ).add(() => {
      this.processDownLoadRespuestas = false;
    });
  }

  editarRespuesta(element) {
    this.router.navigate(['./instancia/', element.id], {relativeTo: this.route});
  }

  procesarRespuetas() {
    this.processProcessRespuestas = true;
    this.respuestaMonitoreoService.procesarRespuestas(this.proyectoId, this.encuestaId)
      .subscribe((respuesta: any) => {
        if (respuesta.success) {
          this.toastr.success('Se han procesado correctamente las respuestas en Quanti', 'Procesar Respuestas');
        }
      }, error => {
        this.errorService.handleError(error);
        this.processProcessRespuestas = false;
      }, () => {
        this.processProcessRespuestas = false;
      }).add(() => {
      this.processProcessRespuestas = false;
    });
  }
}
