import { InversoresTiposService } from './../services/inversores-tipos.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from 'src/app/util/services/error.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-crear-inversores-tipos',
  templateUrl: './crear-inversores-tipos.component.html',
  styleUrls: ['./crear-inversores-tipos.component.sass']
})
export class CrearInversoresTiposComponent implements OnInit {

  public nuevoInversorTipo: FormGroup;
  process: boolean;
  constructor(  private fB: FormBuilder,
                private router: Router,
                private route: ActivatedRoute,
                private inversoresTiposService: InversoresTiposService,
                private fS: FormStatusService,
                private toastr: ToastrService,
                private errorService: ErrorService) { }

  ngOnInit() {
    this.makeForm();
  }

  makeForm() {
    this.nuevoInversorTipo = this.fB.group({
      nombre: [undefined, Validators.required],
      descripcion: [undefined]
    });
  }

  crear() {
    this.process = true;
    const jsonData = this.fS.getJsonFromForm(this.nuevoInversorTipo);
    this.inversoresTiposService.crearInversorTipo(jsonData)
      .subscribe((respuesta: any) => {
        if (respuesta.success) {
          this.toastr.success('Inversor Tipo creado exitosamente', 'Crear Inversor Tipo');
          this.volver();
        }
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
        this.nuevoInversorTipo.enable();
      }).add(() => { this.process = false; });
  }

  volver() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }


}
