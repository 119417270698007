import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-informacion-reportes-excel',
  templateUrl: './informacion-reportes-excel.component.html',
  styleUrls: ['./informacion-reportes-excel.component.sass']
})
export class InformacionReportesExcelComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
