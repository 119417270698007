import { ToastrService } from 'ngx-toastr';
import { ErrorService } from './../../../../util/services/error.service';
import { FormStatusService } from './../../../../util/services/form-status/form-status.service';
import { LoginService } from './../../services/login.service';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-olvide-contrasenha',
  templateUrl: './olvide-contrasenha.component.html',
  styleUrls: ['./olvide-contrasenha.component.sass']
})
export class OlvideContrasenhaComponent implements OnInit {

  forgotPassForm: FormGroup;
  showUsuario = true;
  cargando = false;
  @Output() hide: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() loginType: string;
  constructor(private formBuilder: FormBuilder, private loginService: LoginService,
    private fS: FormStatusService, private errorService: ErrorService, private toastr: ToastrService) { }

  ngOnInit() {
    this.loadForm();
  }

  loadForm() {
    switch (this.loginType) {
      case 'email':
        this.forgotPassForm = this.formBuilder.group({
          email: [undefined, [Validators.required, Validators.email]]
        });
        break;
      case 'doc':
        this.forgotPassForm = this.formBuilder.group({
          doc: [undefined, [Validators.required]]
        });
        break;
    }
  }

  enviarEmail() {
    this.cargando = true;
    const jsonData = this.fS.getJsonFromForm(this.forgotPassForm);
    this.loginService.enviarSolicitudCambioContrasenha(jsonData).subscribe((response: any) => {
      if (response.success) {
        this.toastr.success(response.data, 'Correo enviado correctamente');
      }

    }, err => {
      this.errorService.handleError(err);
    }).add(() => {
      this.cargando = false;
      this.hide.emit(true);
    });
  }

}
