import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TallerParticipante} from '../interfaces/taller-participante.interface';
import {ActivatedRoute, Router} from '@angular/router';
import {FormStatusService} from '../../../../util/services/form-status/form-status.service';
import {LocalStorageService} from '../../../../util/local-storage.service';
import {ErrorService} from '../../../../util/services/error.service';
import {ToastrService} from 'ngx-toastr';
import {TallerParticipanteService} from '../services/taller-participante.service';
import {TallerParticipanteResponse} from '../interfaces/taller-participante-response.interface';

@Component({
  selector: 'app-detalle-taller-participante',
  templateUrl: './detalle-taller-participante.component.html',
  styleUrls: ['./detalle-taller-participante.component.sass']
})
export class DetalleTallerParticipanteComponent implements OnInit {
  public tallerParticipanteForm: FormGroup;
  editable = true;
  tallerParticipanteId: number;
  tallerParticipante: TallerParticipante;
  private token;
  cargando: boolean;
  process: boolean;

  constructor(private fB: FormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private tallerParticipanteService: TallerParticipanteService,
              private fS: FormStatusService,
              private localStorage: LocalStorageService,
              private errorService: ErrorService,
              private toastr: ToastrService) { }

  ngOnInit() {
    this.tallerParticipanteId = +this.route.snapshot.paramMap.get('id');
    this.makeForm();
    this.get();
  }

  makeForm() {
    this.tallerParticipanteForm = this.fB.group({
      nombre: [{ value: undefined, disabled: this.editable }, Validators.required],
      codigo: [{ value: undefined, disabled: this.editable }],
      descripcion: [{ value: undefined, disabled: this.editable }],
      orden: [{ value: undefined, disabled: this.editable }, Validators.required]
    });
  }

  get() {
    this.cargando = true;
    this.tallerParticipanteService.getTallerParticipante(this.tallerParticipanteId)
      .subscribe((respuesta: TallerParticipanteResponse) => {
          this.tallerParticipante = respuesta.data;
          this.tallerParticipanteForm.patchValue(this.tallerParticipante);
        }, error => {
          this.errorService.handleError(error);
        }
      ).add(() => {
      this.cargando = false;
    });
  }
  actualizar() {
    this.process = true;
    const jsonData = this.fS.getJsonFromForm(this.tallerParticipanteForm);
    this.tallerParticipanteService.actualizarTallerParticipante(this.tallerParticipanteId, jsonData)
      .subscribe((respuesta: TallerParticipanteResponse) => {
          if (respuesta.success) {
            this.toastr.success('Taller participante actualizado correctamente', 'Actualizar Taller participante');
            this.get();
            this.editableSwitcher();
          }
        }, error => {
          this.errorService.handleError(error);
        }
      ).add(() => { this.process = false; });
  }

  volver() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  public editableSwitcher() {
    this.tallerParticipanteForm = this.fS.cambiarEstado(this.tallerParticipanteForm, this.editable, ['codigo'], true);
    this.editable = !this.editable;
  }

}
