import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from 'src/app/Globals';

@Injectable({
  providedIn: 'root'
})
export class ActorUbicacionService {

  constructor(private http: HttpClient, private global: Globals) { }

  getActorUbicaciones(token, actorId, search = null, pagination = null) {
    const headers = this.global.getHeader(token);
    const url: string = this.global.makeUrl('/actores/' + actorId + '/ubicaciones', search, pagination);
    return this.http.get(url, { headers });
  }
  getActorUbicacion(actorId, ubicacionId, token) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/actores/' + actorId + '/ubicaciones/' + ubicacionId;
    return this.http.get(url, { headers });
  }
  actualizarActorUbicacion(actorId, ubicacionId, token, json) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/actores/' + actorId + '/ubicaciones/' + ubicacionId;
    return this.http.put(url, json, { headers });
  }
  crearActorUbicacion(token, actorId, json) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/actores/' + actorId + '/ubicaciones';
    return this.http.post(url, json, { headers });
  }
  borrarActorUbicacion(actorId, ubicacionId, token) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/actores/' + actorId + '/ubicaciones/' + ubicacionId;
    return this.http.delete(url, { headers });
  }
}
