import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-config-data-slider',
  templateUrl: './config-data-slider.component.html',
  styleUrls: ['./config-data-slider.component.sass']
})
export class ConfigDataSliderComponent implements OnInit {

  // Datos a ser mostrados, incluir mínimamente: Nombre, Estado y Descripción
  @Input() data: any[];

  // Establece si está o no habilitado para la búsqueda
  @Input() searchable: boolean;
  searchValue = '';

  // Retorna el objeto creado
  @Output() created: EventEmitter<any> = new EventEmitter();
  // Retorna el objeto creado
  @Output() deleted: EventEmitter<any> = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  verifyEvent(event, object) {
    if (event.checked) {
      this.created.emit(object);
    } else {
      this.deleted.emit(object);
    }
  }

}
