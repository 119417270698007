import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {AuthService} from './auth.service';
import {NgxPermissionsService} from 'ngx-permissions';
import {PermissionControllerService} from '../util/permission-controller/permission-controller.service';

@Injectable({
  providedIn: 'root'
})

export class AuthGuardService implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router,
    private ngxPermission: NgxPermissionsService,
    private permissionController: PermissionControllerService
  ) {
  }

  canActivate(): Promise<boolean> {
    if (!this.authService.isLoggedIn) {
      this.router.navigate(['/login']);
      return Promise.reject(false);
    }
    const loadedPer = this.ngxPermission.getPermissions();
    if (Object.keys(loadedPer).length === 0) {
      return this.permissionController.cargarPermisos();
    } else {
      return Promise.resolve(true);
    }
  }
}
