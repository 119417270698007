import { LottieAnimationViewModule } from 'ng-lottie';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatosNoEncontradosComponent } from './datos-no-encontrados.component';

@NgModule({
  declarations: [DatosNoEncontradosComponent],
  imports: [
    CommonModule,
    LottieAnimationViewModule
  ],
  exports: [DatosNoEncontradosComponent]
})
export class DatosNoEncontradosModule { }
