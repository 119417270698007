import { NgxPermissionsService } from 'ngx-permissions';
import { ProgramaService } from './services/programa.service';

import { ToastrService } from 'ngx-toastr';

import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ProgramasResponse, ProgramaResponse } from './interfaces/programa-response';
import { Programa } from './interfaces/programa';
import { HttpErrorResponse } from '@angular/common/http';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { ErrorService } from 'src/app/util/services/error.service';

@Component({
  selector: 'app-programas',
  templateUrl: './programas.component.html',
  styleUrls: ['./programas.component.sass']
})
export class ProgramasComponent implements OnInit {

  heading = 'Programas';
  subheading = 'En este módulo se encuentra la lista de programas.';
  icon = 'pe-7s-note2 icon-gradient bg-ripe-malin';
  cargando: boolean;
  total: number;
  pagination = { page: 1, per_page: 10 };
  selected = null;
  displayedColumns = ['nombre', 'codigo', 'descripcion', 'acciones'];
  @Input() portafolioId: number;
  private token;


  programas: Programa[];
  searchForm: FormGroup;
  constructor(private router: Router, private route: ActivatedRoute, private fB: FormBuilder,
    private localStorage: LocalStorageService, private toastr: ToastrService, private errorService: ErrorService,
    private programaService: ProgramaService, private permissionService: NgxPermissionsService) { }

  ngOnInit() {
    this.token = this.localStorage.getTokenFromStorage();
    this.makeSearchForm();
    this.getProgramas();
  }

  makeSearchForm() {
    this.searchForm = this.fB.group({
      nombre: '',
      codigo: ''
    });
  }

  getProgramas() {
    this.cargando = true;
    this.programaService.getProgramas(this.token, this.portafolioId, this.searchForm.value, this.pagination)
      .subscribe((respuesta: ProgramasResponse) => {
        this.programas = respuesta.data;
        this.total = respuesta.metadata.total;
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
        this.cargando = false;
      });
  }

  crear() {
    this.router.navigate(['./programas/new'], { relativeTo: this.route });
  }

  verDetalle(id: number) {
    this.permissionService.hasPermission('PARPRO/mant_programas/get407').then(result => {
      if(result){
        this.router.navigate(['./programas/', id], { relativeTo: this.route });
      }
    });
  }

  borrar(id: number) {
    this.programaService.borrarPrograma(this.portafolioId, id , this.token)
      .subscribe((respuesta: ProgramaResponse) => {
        if (respuesta.success) {
          this.toastr.success('Programa borrado exitosamente', 'Borrar Programa');
        }
        this.getProgramas();
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
      }, () => { });
  }

  onReset() {
    this.pagination.page = 1;
    this.searchForm.reset();
    this.getProgramas();
  }

  filtrar() {
    this.pagination.page = 1;
    this.getProgramas();
  }

  pageChange() {
    this.getProgramas();
  }
  onClick(event: any) {
    event ? this.filtrar() : this.onReset();
  }

  changeBackGroundColor(event, row) {
    this.selected = event.type == 'mouseover' ? row.id : null;
  }
}
