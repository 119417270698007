import { DimensionTemaResponse } from './../interfaces/dimension-tema-response';
import { DimensionTemaService } from './../services/dimension-tema.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from 'src/app/util/services/error.service';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-crear-dimension-tema',
  templateUrl: './crear-dimension-tema.component.html',
  styleUrls: ['./crear-dimension-tema.component.sass']
})
export class CrearDimensionTemaComponent implements OnInit {

  public nuevoTema: FormGroup;
  private dimensionId: number;
  private token;
  process: boolean;
  constructor(private fB: FormBuilder, private router: Router, private route: ActivatedRoute,
              private dimensionTemaService: DimensionTemaService, private fS: FormStatusService, private toastr: ToastrService,
              private errorService: ErrorService, private localStorage: LocalStorageService) { }

  ngOnInit() {
    this.token = this.localStorage.getTokenFromStorage();
    this.dimensionId = +this.route.snapshot.paramMap.get('dimensionId');
    this.makeForm();
  }

  makeForm() {
    this.nuevoTema = this.fB.group({
      nombre: [null, Validators.required],
      codigo: [null, Validators.required],
      descripcion: [null]
    });
  }

  crear() {
    this.process = true;
    const jsonData = this.fS.getJsonFromForm(this.nuevoTema);
    this.dimensionTemaService.crearDimensionTema(this.dimensionId, this.token, jsonData)
      .subscribe((respuesta: DimensionTemaResponse) => {
        if (respuesta.success) {
          this.toastr.success('Tema creado exitosamente', 'Crear Tema');
          this.volver();
        }
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
        this.nuevoTema.enable();
      }).add(()=>{this.process = false;});
  }

  volver() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

}
