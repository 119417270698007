import { MINPASSWORD, MAXPASSWORD, ParamInicial } from './../interfaces/param-inicial';
import { VerifyTokenResponse } from './interfaces/verify-token-response';
import { VerifyToken } from './interfaces/verify-token';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../../services/login.service';
import { ErrorService } from '../../../../util/services/error.service';
import { ToastrService } from 'ngx-toastr';
import { ParamInicialResponse } from '../interfaces/param-inicial-response';

@Component({
  selector: 'app-recuperar-password',
  templateUrl: './recuperar-password.component.html',
  styleUrls: ['./recuperar-password.component.sass']
})
export class RecuperarPasswordComponent implements OnInit {
  public recuperarForm: FormGroup;
  token: string;
  cargando = false;
  parametrosIniciales: ParamInicial;
  newAccessToken: VerifyToken;
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private errorService: ErrorService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
  ) {
    this.route.queryParams
      .filter(params => params.t)
      .subscribe(params => {
        this.token = params.t;
      });
  }

  ngOnInit() {
    this.verifyToken();
  }

  verifyToken() {
    this.cargando = true;
    this.loginService.verificarTokenChangePassword(this.token).subscribe((response: VerifyTokenResponse) => {
      if (response.success) {
        this.newAccessToken = response.data;
      } else {
        this.toastr.error(response.errors[0], 'Error de Validación');
        this.router.navigate(['../login'], { relativeTo: this.route });
      }
    }, err => {
      this.errorService.handleError(err);
      this.router.navigate(['../login'], { relativeTo: this.route });
    }).add(() => { this.getParametrosInciales(); });
  }
  loadForm() {
    this.recuperarForm = this.formBuilder.group({
      email: [undefined, [Validators.email, Validators.required]],
      token: [undefined, [Validators.required]],
      password: [undefined, [Validators.minLength(+this.parametrosIniciales.MINPASSWORD.MINPASSWORD), Validators.maxLength(+this.parametrosIniciales.MAXPASSWORD.MAXPASSWORD), Validators.required]],
      password_confirmation: [undefined, [Validators.minLength(+this.parametrosIniciales.MINPASSWORD.MINPASSWORD), Validators.maxLength(+this.parametrosIniciales.MAXPASSWORD.MAXPASSWORD), Validators.required]]
    });
    this.recuperarForm.controls.token.setValue(this.newAccessToken.token);
    this.cargando = false;
  }
  actualizarPassword() {
    if (this.recuperarForm.controls.password.value !== this.recuperarForm.controls.password_confirmation.value) {
      this.toastr.error('Las contraseñas no coinciden', 'Error');
    } else {
      this.loginService.recuperarPassword(this.recuperarForm.value).subscribe((response) => {
        this.router.navigate(['../recuperar/exito']);
      },
        error => {
          this.errorService.handleError(error);
        });
    }
  }

  getParametrosInciales() {
    this.loginService.getParametrosLogin().subscribe((respuesta: ParamInicialResponse) => {
      if (respuesta.success) {
        this.parametrosIniciales = respuesta.data;
      }
    }, error => {
      this.errorService.handleError(error);
    }).add(() => { this.loadForm(); });
  }
}
