import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-focalizacion-gestion-proyecto',
  templateUrl: './focalizacion-gestion-proyecto.component.html',
  styleUrls: ['./focalizacion-gestion-proyecto.component.sass']
})
export class FocalizacionGestionProyectoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
