import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from 'src/app/util/services/error.service';
import { ReunionesCrudService } from './../reuniones-crud.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-crear-reunion-dia',
  templateUrl: './crear-reunion-dia.component.html',
  styleUrls: ['./crear-reunion-dia.component.sass']
})
export class CrearReunionDiaComponent implements OnInit {

  form: FormGroup;
  process: boolean;
  cicloId: number;
  constructor(
    private dialogRef: MatDialogRef<CrearReunionDiaComponent>,
    private reunionesCrudService: ReunionesCrudService,
    private errorService: ErrorService,
    private toastr: ToastrService,
    private fS: FormStatusService,
    private fB: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.cicloId = this.data;
    this.makeForm();
  }

  makeForm() {
    this.form = this.fB.group({
      dia: [null, Validators.required],
      hora: [null, Validators.required],
    });
  }

  create() {
    this.process = true;
    const jsonData = this.fS.getJsonFromForm(this.form);
    this.reunionesCrudService.crearDiaReunion(null, this.cicloId, jsonData)
      .subscribe((response: any) => {
        if (response.success) {
          this.toastr.success('Se ha establecido el dia de reunión correctamente', 'Establecer Día de Reunión');
          this.dialogRef.close(true);
        }
      }, error => {
        this.errorService.handleError(error);
      }).add(() => {
        this.process = false;
      });
  }


  volver() {
    this.dialogRef.close();
  }

}
