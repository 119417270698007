import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-gestion-proyecto',
  templateUrl: './gestion-proyecto.component.html',
  styleUrls: ['./gestion-proyecto.component.sass']
})
export class GestionProyectoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
