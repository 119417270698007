import { EstadoActorResponse } from './../interfaces/estado-actor-response';
import { EstadoActoresService } from './../services/estado-actores.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { ErrorService } from 'src/app/util/services/error.service';
import { ToastrService } from 'ngx-toastr';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-crear-estado-actor',
  templateUrl: './crear-estado-actor.component.html',
  styleUrls: ['./crear-estado-actor.component.sass']
})
export class CrearEstadoActorComponent implements OnInit {

  public nuevoEstado: FormGroup;
  private token;
  process: boolean;
  constructor(private fB: FormBuilder, private router: Router, private route: ActivatedRoute,
    private localStorage: LocalStorageService, private errorService: ErrorService, private toastr: ToastrService, private fS: FormStatusService,
    private estadoActorService: EstadoActoresService) { }

  ngOnInit() {
    this.token = this.localStorage.getTokenFromStorage();
    this.makeForm();
  }

  makeForm() {
    this.nuevoEstado = this.fB.group({
      nombre: [null, Validators.required],
      codigo: [null, Validators.required],
      descripcion: [null]
    });
  }

  crear() {
    this.process = true;
    const jsonData = this.fS.getJsonFromForm(this.nuevoEstado);
    this.estadoActorService.crearEstadoActor(this.token, jsonData)
      .subscribe((respuesta: EstadoActorResponse) => {
        if (respuesta.success) {
          this.toastr.success('Estado de actor creado exitosamente', 'Crear Estado de Actor');
          this.volver();
        }
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
        this.nuevoEstado.enable();
      }).add(() => { this.process = false; });
  }

  volver() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
}
