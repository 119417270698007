import { ErrorService } from './../../../../util/services/error.service';
import { ToastrService } from 'ngx-toastr';
import { FormStatusService } from './../../../../util/services/form-status/form-status.service';
import { PortafolioResponse } from './../interfaces/portafolio-response';
import { LocalStorageService } from './../../../../util/local-storage.service';
import { PortafolioService } from './../services/portafolio.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-crear-portafolio',
  templateUrl: './crear-portafolio.component.html',
  styleUrls: ['./crear-portafolio.component.sass']
})
export class CrearPortafolioComponent implements OnInit {
  public nuevoPortafolio: FormGroup;
  private token;
  process: boolean;
  constructor(private fB: FormBuilder, private router: Router, private route: ActivatedRoute, private portafolioService: PortafolioService, private localStorage: LocalStorageService,
    private fS: FormStatusService, private toastr: ToastrService, private errorService: ErrorService) { }

  ngOnInit() {
    this.token = this.localStorage.getTokenFromStorage();
    this.makeForm();
  }

  makeForm() {
    this.nuevoPortafolio = this.fB.group({
      nombre: [undefined, Validators.required],
      descripcion: [undefined]
    });
  }

  crear() {
    this.process = true;
    const jsonData = this.fS.getJsonFromForm(this.nuevoPortafolio);
    this.portafolioService.crearPortafolio(this.token, jsonData)
      .subscribe((respuesta: PortafolioResponse) => {
        if (respuesta.success) {
          this.toastr.success('Portafolio creado exitosamente', 'Crear Portafolio');
          this.volver();
        }
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
        this.nuevoPortafolio.enable();
      }).add(() => { this.process = false; });
  }

  volver() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
}
