import { Injectable } from '@angular/core';
import {Globals} from '../../../../../../../Globals';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {

  constructor(private global: Globals, private http: HttpClient) { }

  getPerfil() {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/profile/users';
    return this.http.get(url, {headers});
  }
  actualizarContrasenia(jsonData) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/profile/changesPasswords';
    return this.http.put(url, jsonData, {headers});
  }
  actualizarDatoPerfil(jsonData) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/profile/users';
    return this.http.post(url, jsonData, {headers});
  }
  editarUsuarioFoto(formData: FormData) {
    const url = this.global.API_ENDPOINT_ES + '/profile/users';
    return this.http.post(url, formData);
  }
}
