import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {CrudUsuariosMovilesService} from '../services/crud-usuarios-moviles.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FormStatusService} from '../../../../../../util/services/form-status/form-status.service';
import {ErrorService} from '../../../../../../util/services/error.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-crear-usuario-movil',
  templateUrl: './crear-usuario-movil.component.html',
  styleUrls: ['./crear-usuario-movil.component.scss']
})
export class CrearUsuarioMovilComponent implements OnInit {

  usuariosForm: FormGroup;
  process = false;
  proyectoId: number;

  constructor(private matDialogRef: MatDialogRef<CrearUsuarioMovilComponent>,
              @Inject(MAT_DIALOG_DATA) private data: any,
              private crudUsuariosMovilesService: CrudUsuariosMovilesService,
              private fB: FormBuilder, private fS: FormStatusService,
              private errorService: ErrorService,
              private toastr: ToastrService) {
  }

  ngOnInit() {
    this.proyectoId = this.data;
    this.makeForm();
  }

  makeForm() {
    this.usuariosForm = this.fB.group({
      displayName: ['', Validators.required]
    });
  }

  onNoClick() {
    this.matDialogRef.close(false);
  }

  crear() {
    this.process = true;
    const jsonData = this.fS.getJsonFromForm(this.usuariosForm);
    this.crudUsuariosMovilesService.create(this.proyectoId, jsonData)
      .subscribe((response: any) => {
        if (response.success) {
          this.toastr.success('Se ha creado el usuario móvil correctamente.', 'Creación de Usuario Móvil');
        }
      }, error => {
        this.errorService.handleError(error);
      }, () => {
        this.matDialogRef.close(true);
      }).add(() => {
      this.process = false;
    });
  }

}
