import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from 'src/app/Globals';

@Injectable({
  providedIn: 'root'
})
export class TallerFormadorService {

  constructor(private http: HttpClient, private global: Globals) { }

  getTallerFormadores(token, tallerId, search = null, pagination = null) {
    const headers = this.global.getHeader(token);
    const url: string = this.global.makeUrl('/gruposTalleres/' + tallerId + '/instanciasTalleres', search, pagination);
    return this.http.get(url, { headers });
  }
  getTallerFormador(tallerId, formadorId, token) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/gruposTalleres/' + tallerId + '/instanciasTalleres/' + formadorId;
    return this.http.get(url, { headers });
  }
  actualizarTallerFormador(tallerId, formadorId, token, json) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/gruposTalleres/' + tallerId + '/instanciasTalleres/' + formadorId;
    return this.http.put(url, json, { headers });
  }
  crearTallerFormador(token, tallerId, json) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/gruposTalleres/' + tallerId + '/instanciasTalleres';
    return this.http.post(url, json, { headers });
  }
  borrarTallerFormador(tallerId, formadorId, token) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/gruposTalleres/' + tallerId + '/instanciasTalleres/' + formadorId;
    return this.http.delete(url, { headers });
  }
}
