import { ConfigDocumentoTipoResponse } from './../interfaces/config-documento-tipo-response';
import { ConfigDocumentoTipoService } from './../services/config-documento-tipo.service';
import { Component, OnInit, Inject } from '@angular/core';
import { CombosResponse } from 'src/app/util/interfaces/combos-response';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Combo } from 'src/app/util/interfaces/combo';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { ToastrService } from 'ngx-toastr';
import { CombosService } from 'src/app/util/services/combos/combos.service';
import { ErrorService } from 'src/app/util/services/error.service';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TipoDocumentoService } from 'src/app/Pages/parametricos-proyecto/tipos-documento/services/tipo-documento.service';

export interface DialogData {
  proyectoId: number;
}
@Component({
  selector: 'app-crear-config-documento-tipo',
  templateUrl: './crear-config-documento-tipo.component.html',
  styleUrls: ['./crear-config-documento-tipo.component.sass']
})
export class CrearConfigDocumentoTipoComponent implements OnInit {

  public nuevoProDocumentoTipo: FormGroup;
  private proyectoId: number;
  private token;
  documentosTipos: Combo[];
  process: boolean;
  constructor(public dialogRef: MatDialogRef<CrearConfigDocumentoTipoComponent>, private fB: FormBuilder,
    private configDocumentoTipoService: ConfigDocumentoTipoService, private fS: FormStatusService, private toastr: ToastrService, private comboService: CombosService,
    private errorService: ErrorService, private localStorage: LocalStorageService, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
    this.token = this.localStorage.getTokenFromStorage();
    this.proyectoId = this.data.proyectoId;
    this.makeForm();
    this.getDocumentosTipo();
  }

  makeForm() {
    this.nuevoProDocumentoTipo = this.fB.group({
      pro_documento_tipo_id: [null, Validators.required],
      activo: [null, Validators.required],
    });
  }

  crear() {
    this.process = true;
    const jsonData = this.fS.getJsonFromForm(this.nuevoProDocumentoTipo);
    this.configDocumentoTipoService.crearProyectoDocumentoTipo(this.token, this.proyectoId, jsonData)
      .subscribe((respuesta: ConfigDocumentoTipoResponse) => {
        if (respuesta.success) {
          this.toastr.success('Tipo de Documento creado exitosamente', 'Crear Tipo de Documento');
          this.dialogRef.close('created');
        }
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
        this.nuevoProDocumentoTipo.enable();
      }).add(() => { this.process = false; });
  }

  getDocumentosTipo() {
    this.comboService.getDocumentoTiposCombo()
      .subscribe((respuesta: CombosResponse) => {
        this.documentosTipos = respuesta.data;
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
      });
  }

  volver() {
    this.dialogRef.close();
  }

}
