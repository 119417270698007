import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from 'src/app/util/services/error.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material';
import { Component, Inject, OnInit } from '@angular/core';
import { GrupoAhorroService } from '../../../../services/grupo-ahorro.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-desactivar-miembro',
  templateUrl: './desactivar-miembro.component.html',
  styleUrls: ['./desactivar-miembro.component.sass']
})
export class DesactivarMiembroComponent implements OnInit {

  idProyecto: number;
  idGrupo: number;
  idCiclo: number;
  idMiembro: number;
  updateForm: FormGroup;
  process: boolean;
  constructor(
    private matDialogRef: MatDialogRef<DesactivarMiembroComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private errorService: ErrorService,
    private toastr: ToastrService,
    private grupoAhorroService: GrupoAhorroService,
    private fB: FormBuilder,
    private fS: FormStatusService
  ) { }

  ngOnInit() {
    this.idProyecto = this.data.idProyecto;
    this.idGrupo = this.data.idGrupo;
    this.idCiclo = this.data.idCiclo;
    this.idMiembro = this.data.idMiembro;
    this.makeForm();
  }

  makeForm() {
    this.updateForm = this.fB.group({
      estado: 'I',
      comentario_inactividad: null
    });
  }

  update() {
    const text = '<b>' + '¿Está seguro que desea desactivar a este miembro? <br> <b>ATENCION:</b> Esta acción es irreversible' + '</b>';
    Swal.fire({
      html: text,
      imageWidth: 50,
      imageHeight: 50,
      showCancelButton: true,
      confirmButtonText: 'Sí, desactivar!',
      confirmButtonColor: '#FF0000',
      cancelButtonText: 'No, cancelar',
      focusCancel: true,
      width: 400
    }).then((result) => {
      if (result.value) {
        this.process = true;
        const jsonData = this.fS.getJsonFromForm(this.updateForm);
        this.grupoAhorroService.updateMiembro(this.idProyecto, this.idGrupo, this.idCiclo, this.idMiembro, jsonData)
          .subscribe((response: any) => {
            if (response.success) {
              this.toastr.success('Se ha desactivado al miembro exitosamente', 'Desactivar Miembro');
            }
          }, error => {
            this.errorService.handleError(error);
          }, () => {
            this.matDialogRef.close(true);
          }).add(() => {
            this.process = false;
          });
      }
    });
  }

  volver() {
    this.matDialogRef.close(false);
  }

}
