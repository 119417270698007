import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from 'src/app/Globals';

@Injectable({
  providedIn: 'root'
})
export class EstadoActoresService {

  constructor(private http: HttpClient, private global: Globals) { }

  getEstadosActores(token, search = null, pagination = null) {
    const headers = this.global.getHeader(token);
    const url: string = this.global.makeUrl('/actoresEstados', search, pagination);
    return this.http.get(url, { headers });
  }
  getEstadoActor(id, token) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/actoresEstados/' + id;
    return this.http.get(url, { headers });
  }
  actualizarEstadoActor(id, token, json) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/actoresEstados/' + id;
    return this.http.put(url, json, { headers });
  }
  crearEstadoActor(token, json) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/actoresEstados';
    return this.http.post(url, json, { headers });
  }
  borrarEstadoActor(id, token) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/actoresEstados/' + id;
    return this.http.delete(url, { headers });
  }
}
