import { ErrorService } from 'src/app/util/services/error.service';
import { ParamInicialResponse } from './interfaces/param-inicial-response';
import { Globals } from './../../../Globals';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../../../auth/user';
import { LoginResponse, UserData } from '../interfaces/login';
import { AuthService } from '../../../auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from '../../../util/local-storage.service';
import { LoginService } from '../services/login.service';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ParamInicial } from './interfaces/param-inicial';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit {

  public loginForm: FormGroup;
  usuario: string;
  password: string;
  tieneRegistroActivado = false;
  loginPor: string;
  iniciando = false;
  registro = false;
  recuperar = false;
  parametrosIniciales: ParamInicial;

  public usuarioDetalle: UserData;
  modalReference: NgbModalRef;
  // activeModal: NgbActiveModal;
  public cargando = false;
  cargandoServicioInicial = false;

  constructor(private router: Router,
    private authService: AuthService,
    private toastr: ToastrService,
    private localStorageService: LocalStorageService,
    private loginService: LoginService,
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private global: Globals,
    private errorService: ErrorService
  ) {
  }

  ngOnInit() {
    this.tieneRegistroActivado = this.global.registroDeUsuarios;
    this.getParametrosInciales();
    this.loadForm();
  }

  loadForm() {
    this.loginForm = this.formBuilder.group({
      username: [undefined, [Validators.required]],
      password: [undefined, Validators.required]
    });
  }

  iniciarSesion() {
    this.cargando = true;
    const username = this.loginForm.controls.username.value; // 'lance09@towne.org';
    const password = this.loginForm.controls.password.value; // '12345';
    const application = 'app1';

    if (username !== '' && password !== '') {
      this.loginService.loginUsuario(username, password, application)
        .subscribe(data => {
          this.cargando = false;
          const respuesta = data as LoginResponse;

          if (respuesta.success) {
            const usuario = new User(username, password, respuesta.data);
            this.authService.login(usuario);
          } else {
            console.error('No se pudo obtener datos de Login: ' + JSON.stringify(respuesta));
          }

        },
          error => {
            const respuesta = error;
            if (respuesta.error.success === false) {
              const mensaje = respuesta.error.errors.join('<br>');
              this.toastr.error(mensaje, 'Error: ' + error.status);
            } else {
              this.toastr.error('Ocurrio un error', 'Error: ' + error.status);
            }
          }
        ).add(() => {  // Se llama a esta parte del codigo al finalizar la ejecución. Sea exitosa o no
          this.cargando = false;
        });
    }
  }

  cambiarInicioRegistro(ver) {
    if (ver === 'inicio') {
      this.iniciando = true;
      this.recuperar = false;
      this.registro = false;
    }
    if (ver === 'registro') {
      this.iniciando = false;
      this.recuperar = false;
      this.registro = true;
    }

    if (ver === 'recuperar') {
      this.iniciando = false;
      this.recuperar = true;
      this.registro = false;
    }
  }

  getParametrosInciales() {
    this.cargandoServicioInicial = true;
    this.loginService.getParametrosLogin().subscribe((respuesta: ParamInicialResponse) => {
      if (respuesta.success) {
        this.tieneRegistroActivado = respuesta.data.REGUSER.REGUSER;
        this.loginPor = respuesta.data.LOGINTYPE.LOGINTYPE;
        this.parametrosIniciales = respuesta.data;
      }
    }, error => {
      this.errorService.handleError(error);
    }).add(() => {
      if (this.loginPor === 'email') {
        this.loginForm.get('username').setValidators(Validators.email);
      }
      this.cargandoServicioInicial = false; this.iniciando = true;
    });
  }

}
