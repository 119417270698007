import { GrupoAhorroService } from './../../../../services/grupo-ahorro.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from 'src/app/util/services/error.service';
import { LocalStorageService } from 'src/app/util/local-storage.service';


@Component({
  selector: 'app-detalle-miembro',
  templateUrl: './detalle-miembro.component.html',
  styleUrls: ['./detalle-miembro.component.sass']
})
export class DetalleMiembroComponent implements OnInit {

  selected = null;
  pagos = [];
  searchForm: FormGroup;
  pagination = {page: 1, per_page: 10};
  cargando: boolean;
  total: number;
  displayedColumns = ['fecha_reunion', 'presencia', 'fondo_social', 'fondo_prestamo', 'multas'];
  idProyecto: number;
  idGrupo: number;
  miembroId: number;
  idCiclo: number;
  miembro: any;
  miembroDataSource = [];

  constructor(private fB: FormBuilder,
              private toastr: ToastrService,
              private errorService: ErrorService,
              private localStorage: LocalStorageService,
              private route: ActivatedRoute,
              private grupoAhorroService: GrupoAhorroService,
              private router: Router){
    
  }

  ngOnInit(){
    this.idProyecto = +this.route.snapshot.paramMap.get('idProyecto');
    this.idGrupo = +this.route.snapshot.paramMap.get('idGrupo');
    this.idCiclo = +this.route.snapshot.paramMap.get('idCiclo');
    this.miembroId = +this.route.snapshot.paramMap.get('id');
    this.makeForm();
    this.getMiembroCiclo();
  }

  makeForm(){
    this.searchForm = this.fB.group({
      fecha_reunion: '',
      codigo: ''
    });
  }

  getMiembroCiclo(){
    this.cargando = true;
    this.grupoAhorroService.getMiembroCiclo(this.idProyecto, this.idGrupo, this.idCiclo, this.miembroId)
      .subscribe((response: any) => {
        if (response.success) {
          this.miembro = response.data;
          this.miembroDataSource = response.data.grupo_ciclo_reuniones_detalles;
          this.total = response.metadata.total;
        }
      }, error => {
        this.errorService.handleError(error);
      }, ()=> {
        this.cargando = false;
      });
  }

  borrar(){
    return
  }

  verDetalle(){
    return
  }

  onClick(event: any) {
    event ? this.filtrar() : this.onReset();
  }

  filtrar() {
    this.pagination.page = 1;
    this.getMiembroCiclo();
  }

  onReset() {
    this.pagination.page = 1;
    this.searchForm.reset();
    this.getMiembroCiclo();
  }

  pageChange() {
    this.getMiembroCiclo();
  }

  changeBackGroundColor(event, row) {
    this.selected = event.type == 'mouseover' ? row.id : null;
  }

  volver() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

  volverGrupo() {
    this.router.navigate(['../../../../../'], { relativeTo: this.route });
  }

  volverCiclos() {
    this.router.navigate(['../../../../'], { relativeTo: this.route });
  }

}