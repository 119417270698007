import {Injectable} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormStatusService {

  constructor() {
  }

  // Método que permite el cambio de estado (enabled o disabled) a los campos de un formulario
  /* Parámetros:
      - formulario: es el formulario al cual se le aplicará el cambio de estado.
      - estadoActual: se refiere al estado en el cual se encuentra el formulario enviado ( disabled = true o enabled = true).
      - cambiar: es un array de string con los nombres de los campos que son excepciones, es decir, los que cambian o no.
      - excluyente: establece si el array "cambiar" deberá ser modificado o no.
   */
  cambiarEstado(formulario: FormGroup, estadoActual: boolean, cambiar?: string[], exluyente?: boolean) {
    estadoActual = !estadoActual;
    const state = estadoActual ? 'disable' : 'enable';
    if (cambiar) {
      if (!exluyente) { // Se modificará solo los campos incluidos en el array "cambiar"
        Object.keys(formulario.controls).forEach((controlName) => {
          if (cambiar.includes(controlName)) {
            formulario.controls[controlName][state]();
          }
        });
      } else if (exluyente) { // Se modificará todos los campos excluyendo los que están en el array "cambiar"
        Object.keys(formulario.controls).forEach((controlName) => {
          if (!cambiar.includes(controlName)) {
            formulario.controls[controlName][state]();
          }
        });
      }
    } else { // Si no se le pasó el array "cambiar" realizará el cambio a todos los campos
      Object.keys(formulario.controls).forEach((controlName) => {
        formulario.controls[controlName][state]();
      });
    }

    return formulario;
  }

  // Este método excluye a los campos del formulario con valor NULL o UNDEFINED para convertir a JSON.
  getJsonFromForm(formulario: FormGroup): string {
    Object.keys(formulario.controls).forEach((controlName) => {
      if (formulario.controls[controlName].value === null || formulario.controls[controlName].value === undefined) {
        formulario.controls[controlName].disable();
      }
    });
    return JSON.stringify(formulario.value);
  }
}
