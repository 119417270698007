import { ConfigTallerParticipante } from './interfaces/config-taller-participante';
import { ConfigTalleresParticipantesService } from './services/config-talleres-participantes.service';
import { Component, OnInit, Input } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { CombosService } from 'src/app/util/services/combos/combos.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from 'src/app/util/services/error.service';
import { MatDialog } from '@angular/material';
import { HttpErrorResponse } from '@angular/common/http';
import { ComboComponentes } from 'src/app/util/interfaces/combo';
import { ConfigTalleresParticipanteResponse, ConfigTallerParticipanteResponse } from './interfaces/config-taller-participante-response';

@Component({
  selector: 'app-config-talleres-participantes',
  templateUrl: './config-talleres-participantes.component.html',
  styleUrls: ['./config-talleres-participantes.component.sass']
})
export class ConfigTalleresParticipantesComponent implements OnInit {

  @Input() proyectoId: number;
  cargando: boolean;
  talleresActivos: ConfigTallerParticipante[];
  displayedColumns = ['nombre', 'codigo', 'acciones'];
  selected = null;
  talleresDisponibles: any[];
  allSelected: boolean;

  // Creación & Edición de componentes de gestión
  process: boolean;
  talleresForm: FormArray;

  constructor(
    private fB: FormBuilder,
    private configTalleresParticipantesService: ConfigTalleresParticipantesService,
    private combosService: CombosService,
    private toastr: ToastrService,
    private errorService: ErrorService,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.getTalleresActivos();
    this.makeForm();
  }

  getTalleresActivos() {
    this.cargando = true;
    this.configTalleresParticipantesService.getProyectoTalleresFacilitadores(this.proyectoId)
      .subscribe((respuesta: ConfigTalleresParticipanteResponse) => {
        this.talleresActivos = respuesta.data;
      }, error => {
        this.errorService.handleError(error);
      }, () => {
        this.getTalleresDisponibles();
      });
  }

  makeForm() {
    this.talleresForm = this.fB.array([]);
  }

  crear(taller?: FormArray) {
    this.process = true;
    let jsonData;
    if (!taller) {
      // Incluye TODOS los talleres cuando NO tiene ninguno asociado
      const objToSend = this.talleresForm.value;
      jsonData = JSON.stringify(objToSend);
    } else {
      // Incluye UN taller en específico
      const objToSend = taller.value;
      jsonData = JSON.stringify(objToSend);
    }
    this.configTalleresParticipantesService.crearProyectoTalleresFacilitadores(this.proyectoId, jsonData)
      .subscribe((respuesta: ConfigTallerParticipanteResponse) => {
        if (respuesta.success && JSON.parse(jsonData).length > 0) {
          this.toastr.success('Talleres creados exitosamente', 'Crear Configuración de Talleres');
        } else {
          this.toastr.warning('No ha seleccionado ningún taller. Si usted no desea configurar talleres, simplemente no ejecute la acción.', 'Atención');
        }
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
      }, () => {
        this.getTalleresActivos();
      }).add(() => { this.process = false; });
  }

  changeBackGroundColor(event, row) {
    this.selected = event.type == 'mouseover' ? row.id : null;
  }

  getTalleresDisponibles() {
    this.combosService.getTalleresParticipantesCombo()
      .subscribe((respuesta: any) => {
        this.talleresDisponibles = respuesta.data;
      }, error => {
        this.errorService.handleError(error);
      }, () => {
        this.verificarActivos();
      });
  }

  verificarActivos() {
    this.talleresForm.reset();
    this.talleresDisponibles.forEach(tallerDisponible => {
      this.talleresActivos.forEach(tallerActivo => {
        if (tallerDisponible.id === tallerActivo.pro_taller_participante_id) {
          tallerDisponible.activo = true;
          tallerDisponible.relacion_id = tallerActivo.id;
          this.incluirTallerActivo(tallerActivo);
        }
      });
    });

    if (this.talleresActivos.length === 0) {
      this.marcarTodos();
    }
    this.cargando = false;
  }

  marcarTodos() {
    this.talleresDisponibles.forEach(tallerDisponible => {
      tallerDisponible.activo = true;
      this.incluirTaller(tallerDisponible);
    });
    this.allSelected = true;
  }

  desMarcarTodo() {
    this.talleresDisponibles.forEach(tallerDisponible => {
      tallerDisponible.activo = false;
      this.borrar(tallerDisponible.id);
    });
    this.allSelected = false;
  }

  // Agrega un nuevo taller para el proyecto
  incluirTaller(value: ComboComponentes) {
    const incluir = this.fB.group({
      pro_taller_participante_id: value.id
    });
    this.talleresForm.push(incluir);
    if (this.talleresActivos.length > 0) {
      // Tiene talleres asociados, por ende se procede a una creación individual
      const tallerAsArray = this.fB.array([]);
      tallerAsArray.push(incluir);
      this.crear(tallerAsArray);
    }
  }

  // Al verificar los talleres activos, los agrega al taller Form
  incluirTallerActivo(value: any) {
    const incluir = this.fB.group({
      pro_taller_participante_id: value.pro_taller_participante_id
    });
    this.talleresForm.push(incluir);
  }

  // Validará si es que tiene que hacer una CREACION
  validarAccion() {
    if (this.talleresActivos.length === 0) {
      this.crear();
    }
  }

  borrar(id: any) {
    const arr = this.talleresForm.value;
    const item = arr.find(x => x.pro_taller_participante_id === id);
    const index = arr.indexOf(item);
    this.talleresForm.removeAt(index);
    if (this.talleresActivos.length > 0) {
      const arrToSend = [];
      arrToSend.push(item);
      const jsonData = JSON.stringify(arrToSend);
      this.configTalleresParticipantesService.borrarProyectoTallerFacilitador(this.proyectoId, jsonData)
        .subscribe((respuesta: ConfigTallerParticipanteResponse) => {
          if (respuesta.success) {
            this.toastr.success('Taller borrado exitosamente', 'Borrar Configuración de Taller');
          }
        }, (error: HttpErrorResponse) => {
          this.errorService.handleError(error);
        }, () => { this.getTalleresActivos(); }).add(() => { });
    }
  }

}
