import {CombosResponse} from 'src/app/util/interfaces/combos-response';
import {CombosService} from 'src/app/util/services/combos/combos.service';
import {ToastrService} from 'ngx-toastr';
import {DatePipe} from '@angular/common';
import {PaisProyecto} from './pais-proyecto/interfaces/pais-proyecto';
import {ProyectosResponse, ProyectoResponse} from './interfaces/proyecto-response';
import {ErrorService} from 'src/app/util/services/error.service';
import {LocalStorageService} from 'src/app/util/local-storage.service';
import {FormGroup, FormBuilder} from '@angular/forms';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ProyectosService} from './services/proyectos.service';
import {Proyecto} from './interfaces/proyecto';
import {HttpErrorResponse} from '@angular/common/http';
import {Combo} from 'src/app/util/interfaces/combo';
import {NgxPermissionsService} from 'ngx-permissions';
import {Globals} from '../../Globals';

@Component({
  selector: 'app-proyectos',
  templateUrl: './proyectos.component.html',
  styleUrls: ['./proyectos.component.sass'],
  providers: [DatePipe]
})
export class ProyectosComponent implements OnInit {
  selected = null;
  public pagination = {page: 1, per_page: 10};
  public total: number;
  search: FormGroup;
  private token;
  estadosProyecto: Combo[];
  programas: Combo[];


  cargando: boolean;
  heading = 'Proyectos';
  subheading: string;
  icon = 'pe-7s-portfolio icon-gradient bg-ripe-malin';
  displayedColumns = ['nombre', 'codigo', 'fecha', 'estado', 'portafolio', 'acciones'];
  pais: PaisProyecto;

  proyectos: Proyecto[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private localStorage: LocalStorageService,
    private fB: FormBuilder,
    private proyectosService: ProyectosService,
    private errorService: ErrorService,
    private datePipe: DatePipe,
    private toastr: ToastrService,
    private comboService: CombosService,
    private ngxPermission: NgxPermissionsService,
    public globals: Globals
  ) {
  }

  ngOnInit() {
    this.makeForm();
    if (this.localStorage.getStoreSearchFrom() === 'mant_proyectos') {
      this.search.patchValue(this.localStorage.getStoreSearch());
      this.pais = this.localStorage.getSavedItem('pais_proyecto');
      if (this.pais) {
        this.getProyectos(this.pais);
      }
    }
    this.getComboEstado();
    this.getComboProgramas();
  }

  makeForm() {
    this.search = this.fB.group({
      nombre: undefined,
      codigo: undefined,
      pro_programa_id: undefined,
      pro_portafolio_id: undefined,
      adm_pais_id: undefined,
      pro_proyecto_estado_id: undefined,
      fecha_inicio: undefined,
      fecha_fin: undefined
    });
  }

  getProyectos(event) {
    this.cargando = true;
    this.pais = event;
    this.subheading = 'En este módulo se encuentran todos los proyectos de ' + this.pais.nombre + ' del sistema';
    this.search.controls.adm_pais_id.patchValue(event.id);
    this.proyectosService.getProyectos(this.search.value, this.pagination)
      .subscribe((respuesta: ProyectosResponse) => {
          this.proyectos = respuesta.data;
          this.total = respuesta.metadata.total;
        }, error => {
          this.errorService.handleError(error);
        }, () => {
          this.globals.getPowerBiHeaders();
        }).add(() => {
      this.cargando = false;
    });
  }

  verDetalle(id) {
    this.ngxPermission.hasPermission('PRO/mant_proyectos/get602')
      .then(result => {
        if (result) {
          this.localStorage.saveItem('pais_proyecto', this.pais);
          this.localStorage.storeSearch(this.search, 'mant_proyectos');
          this.router.navigate(['./', id], {relativeTo: this.route});
        }
      });
  }

  crearProyecto() {
    this.localStorage.saveItem('pais_proyecto', this.pais);
    this.localStorage.storeSearch(this.search, 'mant_proyectos');
    this.router.navigate(['./new'], {relativeTo: this.route});
  }

  editarDetalle(id) {
    this.localStorage.saveItem('pais_proyecto', this.pais);
    this.localStorage.storeSearch(this.search, 'mant_proyectos');
    this.router.navigate(['./', id, 'editar-proyecto'], {relativeTo: this.route});
  }

  pageChange() {
    this.getProyectos(this.pais);
  }

  onClick(event: any) {
    event ? this.filtrar() : this.onReset();
  }

  changeBackGroundColor(event, row) {
    this.selected = event.type == 'mouseover' ? row.id : null;
  }

  changeCountry() {
    this.proyectos = undefined;
  }

  onReset() {
    this.pagination.page = 1;
    this.search.reset();
    this.getProyectos(this.pais);
  }

  filtrar() {
    this.pagination.page = 1;
    this.search.controls.fecha_inicio.setValue(this.datePipe.transform(this.search.value.fecha_inicio, 'yyyy-MM-dd'));
    this.search.controls.fecha_fin.setValue(this.datePipe.transform(this.search.value.fecha_fin, 'yyyy-MM-dd'));
    this.getProyectos(this.pais);
  }

  borrar(id: number) {
    this.proyectosService.borrarProyecto(id)
      .subscribe((respuesta: ProyectoResponse) => {
        if (respuesta.success) {
          this.toastr.success('Proyecto borrado exitosamente', 'Borrar Proyecto');
        }
        this.getProyectos(this.pais);
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
      }, () => {
      });
  }

  getComboEstado() {
    this.comboService.getProyectoEstados()
      .subscribe((respuesta: CombosResponse) => {
          this.estadosProyecto = respuesta.data;
        }, error => {
          this.errorService.handleError(error);
        }
      ).add(() => {
    });
  }

  getComboProgramas() {
    this.comboService.getProgramas()
      .subscribe((respuesta: CombosResponse) => {
          this.programas = respuesta.data;
        }, error => {
          this.errorService.handleError(error);
        }
      ).add(() => {
    });
  }
}
