import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import { Globals } from '../../../Globals';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import {LocalStorageService} from '../../../util/local-storage.service';
import {ToastrService} from 'ngx-toastr';
import {ErrorService} from '../../../util/services/error.service';
import {ActorService} from './services/actor.service';
import {PersonasResponse} from '../../Administracion/personas/interfaces/personas-response.interface';
import {Actor} from './interfaces/actor.interface';
import {ActoresResponse} from './interfaces/actor-response.interface';
import {HttpErrorResponse} from '@angular/common/http';
import {Persona} from '../../Administracion/personas/interfaces/persona.interface';
import {map, startWith} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {CombosService} from '../../../util/services/combos/combos.service';
import {CombosResponse} from '../../../util/interfaces/combos-response';
import {Combo} from '../../../util/interfaces/combo';
import {DatePipe} from '@angular/common';
import {NgxPermissionsService} from 'ngx-permissions';

@Component({
  selector: 'app-actores-proyecto',
  templateUrl: './actores-proyecto.component.html',
  styleUrls: ['./actores-proyecto.component.sass'],
  providers: [DatePipe]
})
export class ActoresProyectoComponent implements OnInit {
  personasControl = new FormControl();
  estadoActorControl = new FormControl();
  rolActorControl = new FormControl();
  filteredOptionsPersona: Observable<object[]>;
  filteredOptionsEstadoControl: Observable<object[]>;
  filteredOptionsRolControl: Observable<object[]>;
  private token;
  idProyecto: number;
  actores: Actor[];
  personas: Persona[];
  estadoActores: Combo[];
  rolActores: Combo[];
  cargando: boolean;
  total: number;
  imageURL: string;
  searchForm: FormGroup;
  pagination = { page: 1, per_page: 10 };
  public lottieConfig = {
    path: 'assets/lottie/empty-box.json',
    renderer: 'canvas',
    autoplay: true,
    loop: true
  };

  constructor(
    private actoresService: ActorService,
    private datePite: DatePipe,
    private comboService: CombosService,
    private localStorage: LocalStorageService,
    private toastr: ToastrService,
    private errorService: ErrorService,
    private route: ActivatedRoute,
    private router: Router,
    private globals: Globals,
    private formBuilder: FormBuilder,
    private ngxPermissionService: NgxPermissionsService
  ) {
    this.token = this.localStorage.getTokenFromStorage();
    this.imageURL = this.globals.FILE_ENDPOINT + '/usuarios/uploads/';
    this.searchForm = this.formBuilder.group({
      adm_persona_id: undefined,
      pro_actor_estado_id: undefined,
      pro_actor_rol_id: undefined,
      fecha_entrada_ini: undefined,
      fecha_entrada_fin: undefined,
      fecha_salida_ini: undefined,
      fecha_salida_fin: undefined
    });
  }

  ngOnInit() {
    this.idProyecto = +this.route.snapshot.paramMap.get('idProyecto');
    this.getActores();
    this.getPersonasCombo();
    this.getEstadoActoresCombo();
    this.getRolActoresCombo();
  }
  displayFnPersona(persona?: Persona): string | undefined {
    return persona ? persona.nombre + ' ' + persona.apellido : undefined;
  }
  displayFnEstadoActor(estado?: Combo): string | undefined {
    return estado ? estado.nombre : undefined;
  }
  displayFnRolProyecto(rol?: Combo): string | undefined {
    return rol ? rol.nombre : undefined;
  }
  public funcionRecorrer(aEvaluar: string[], objeto) {
    let cadenaSplitted = '';
    aEvaluar.forEach((element) => {
      cadenaSplitted = cadenaSplitted + objeto[element].split(' ').join('');
    });
    return cadenaSplitted;
  }
  private filter(value, combo, aEvaluar: string[]): object[] {
    let filterValue;
    if (value) {
      if (typeof value === 'object' && value.constructor === Object) {
        return combo.filter(objeto => objeto.id === value.id);
      } else {
        filterValue = value.toLowerCase();
        return combo.filter(
          objeto => {
            const cadenaSplitted = this.funcionRecorrer(aEvaluar, objeto);
            const filterValueSplitted = filterValue.split(' ').join('');
            return cadenaSplitted.toLowerCase().includes(filterValueSplitted);
          });
      }
    } else {
      filterValue = '';
      return combo.filter(objeto => {
        const cadenaSplitted = this.funcionRecorrer(aEvaluar, objeto);
        return cadenaSplitted.toLowerCase().includes(filterValue);
      });
    }
  }
  funcionAutocompletePersonas() {
    this.filteredOptionsPersona = this.personasControl.valueChanges
      .pipe(
        startWith(''),
        map(value => {
          if (value) {
            if (typeof value === 'object' && value.constructor === Object) {
              this.searchForm.controls.adm_persona_id.setValue(value.id);
            }
          }
          return this.filter(value, this.personas, ['nombre', 'apellido']);
        }),
      );
  }
  funcionAutocompleteEstado() {
    this.filteredOptionsEstadoControl = this.estadoActorControl.valueChanges
      .pipe(
        startWith(''),
        map(value => {
          if (value) {
            if (typeof value === 'object' && value.constructor === Object) {
              this.searchForm.controls.pro_actor_estado_id.setValue(value.id);
            }
          }
          return this.filter(value, this.estadoActores, ['nombre']);
        }),
      );
  }
  funcionAutocompleteRol() {
    this.filteredOptionsRolControl = this.rolActorControl.valueChanges
      .pipe(
        startWith(''),
        map(value => {
          if (value) {
            if (typeof value === 'object' && value.constructor === Object) {
              this.searchForm.controls.pro_actor_rol_id.setValue(value.id);
            }
          }
          return this.filter(value, this.rolActores, ['nombre']);
        }),
      );
  }
  filtrar() {
    this.pagination.page = 1;
    this.getActores();
  }
  pageChange() {
    this.getActores();
  }
  onReset() {
    this.pagination = { page: 1, per_page: 10 };
    this.personasControl.reset();
    this.rolActorControl.reset();
    this.estadoActorControl.reset();
    this.searchForm.reset();
    this.getActores();
  }

  getActores() {
    this.cargando = true;
    this.actoresService.getActores(this.token, this.idProyecto, this.searchForm.value, this.pagination)
      .subscribe((respuesta: ActoresResponse) => {
          this.actores = respuesta.data;
          this.total = respuesta.metadata.total;
        }, error => {
          this.errorService.handleError(error);
        }
      ).add(() => {
      this.cargando = false;
    });
  }
  private getPersonasCombo() {
    this.comboService.getPersonas()
      .subscribe((respuesta: PersonasResponse) => {
        this.personas = respuesta.data;
        this.funcionAutocompletePersonas();
      }, error => {
        this.errorService.handleError(error);
      }).add(() => {});
  }
  private getEstadoActoresCombo() {
    this.comboService.getEstadoActoresCombo(this.token)
      .subscribe((respuesta: CombosResponse) => {
        this.estadoActores = respuesta.data;
        this.funcionAutocompleteEstado();
      }, error => {
        this.errorService.handleError(error);
      }).add(() => {});
  }
  private getRolActoresCombo() {
    this.comboService.getRolActoresCombo(this.token)
      .subscribe((respuesta: CombosResponse) => {
        this.rolActores = respuesta.data;
        this.funcionAutocompleteRol();
      }, error => {
        this.errorService.handleError(error);
      }).add(() => {});
  }
  borrarActor(id) {
    this.actoresService.borrarActor(this.token, this.idProyecto, id)
      .subscribe((respuesta: PersonasResponse) => {
        this.toastr.success('Actor borrado exitosamente', 'Borrar Actor');
        this.getActores();
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
      }, () => {
      });
  }
  verDetalle(id) {
    this.ngxPermissionService.hasPermission('PRO/mant_actores_proyectos/get613')
      .then(result => {
        if (result) {
          this.router.navigate(['./', id], { relativeTo: this.route });
        }
      });
  }

  crearActor() {
    this.router.navigate(['./new'], { relativeTo: this.route });
  }
  onClick(event: any) {
    event ? this.filtrar() : this.onReset();
  }

}
