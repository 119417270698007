import { ProgramaResponse } from './../interfaces/programa-response';
import { ProgramaService } from './../services/programa.service';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { Programa } from '../interfaces/programa';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { ErrorService } from 'src/app/util/services/error.service';

@Component({
  selector: 'app-detalle-programa',
  templateUrl: './detalle-programa.component.html',
  styleUrls: ['./detalle-programa.component.sass']
})
export class DetalleProgramaComponent implements OnInit {
  public programaForm: FormGroup;
  editable = true;
  portafolioId: number;
  programaId: number;
  cargando: boolean;
  private token;
  programa: Programa;

  constructor(private fB: FormBuilder, private router: Router, private route: ActivatedRoute, private fS: FormStatusService,
    private localStorage: LocalStorageService, private errorService: ErrorService, private toastr: ToastrService, private programaService: ProgramaService) { }

  ngOnInit() {
    this.portafolioId = +this.route.snapshot.paramMap.get('portafolioId');
    this.programaId = +this.route.snapshot.paramMap.get('programaId');
    this.token = this.localStorage.getTokenFromStorage();
    this.makeForm();
    this.get();
  }

  makeForm() {
    this.programaForm = this.fB.group({
      nombre: [{ value: null, disabled: this.editable }, Validators.required],
      codigo: [{ value: null, disabled: this.editable }, Validators.required],
      descripcion: [{ value: null, disabled: this.editable }]
    });
  }

  get() {
    this.cargando = true;
    this.programaService.getPrograma(this.portafolioId, this.programaId, this.token)
      .subscribe((respuesta: ProgramaResponse) => {
        this.programa = respuesta.data;
        this.programaForm.patchValue(this.programa);
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
        this.cargando = false;
      });
  }
  actualizar() {
    const jsonData = this.fS.getJsonFromForm(this.programaForm);
    this.programaService.actualizarPrograma(this.portafolioId, this.programaId, this.token, jsonData)
      .subscribe((respuesta: ProgramaResponse) => {
        if (respuesta.success) {
          this.toastr.success('Programa actualizado correctamente', 'Actualizar programa');
          this.get();
          this.editableSwitcher();
        }
      }, error => {
        this.errorService.handleError(error);
        this.programaForm.enable();
      }
      );
  }

  volver() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

  public editableSwitcher() {
    this.programaForm = this.fS.cambiarEstado(this.programaForm, this.editable, ['nombre', 'descripcion'], false);
    this.editable = !this.editable;
  }
}
