import { MatDialogRef, MatPaginator, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { INJECTOR } from '@angular/core/src/render3/interfaces/view';

@Component({
  selector: 'app-excel-error-viewer',
  templateUrl: './excel-error-viewer.component.html',
  styleUrls: ['./excel-error-viewer.component.sass']
})
export class ExcelErrorViewerComponent implements OnInit {

  errorData = new MatTableDataSource<any>();
  private pagination = { page: 1, per_page: 10 };
  displayedColumns = ['dato', 'fila', 'mensaje'];
  cargando: boolean;
  selected = null;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private dialogRef: MatDialogRef<ExcelErrorViewerComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) { }

  ngOnInit() {
    this.setData()
  }

  setData() {
    this.errorData.data = this.data;
    this.errorData.paginator = this.paginator;
  }

  changeBackGroundColor(event, row) {
    this.selected = event.type == 'mouseover' ? row.id : null;
  }

  onNoClick(){
    this.dialogRef.close();
  }

}
