import { DimensionTemaService } from './../services/dimension-tema.service';
import { DimensionTemaResponse } from './../interfaces/dimension-tema-response';
import { DimensionTema } from './../interfaces/dimension-tema';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { ErrorService } from 'src/app/util/services/error.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-detalle-dimension-tema',
  templateUrl: './detalle-dimension-tema.component.html',
  styleUrls: ['./detalle-dimension-tema.component.sass']
})
export class DetalleDimensionTemaComponent implements OnInit {

  public dimensionForm: FormGroup;
  editable = true;
  dimensionId: number;
  temaId: number;
  cargando: boolean;
  private token;
  dimension: DimensionTema;
  process: boolean;

  constructor(private fB: FormBuilder, private router: Router, private route: ActivatedRoute, private fS: FormStatusService,
    private localStorage: LocalStorageService, private errorService: ErrorService, private toastr: ToastrService,
    private dimensionTemaService: DimensionTemaService) { }

  ngOnInit() {
    this.dimensionId = +this.route.snapshot.paramMap.get('dimensionId');
    this.temaId = +this.route.snapshot.paramMap.get('temaId');
    this.token = this.localStorage.getTokenFromStorage();
    this.makeForm();
    this.get();
  }

  makeForm() {
    this.dimensionForm = this.fB.group({
      nombre: [{ value: null, disabled: this.editable }, Validators.required],
      codigo: [{ value: null, disabled: this.editable }, Validators.required],
      descripcion: [{ value: null, disabled: this.editable }]
    });
  }

  get() {
    this.cargando = true;
    this.dimensionTemaService.getDimensionTema(this.dimensionId, this.temaId, this.token)
      .subscribe((respuesta: DimensionTemaResponse) => {
        this.dimension = respuesta.data;
        this.dimensionForm.patchValue(this.dimension);
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
        this.cargando = false;
      });
  }
  actualizar() {
    this.process = true;
    const jsonData = this.fS.getJsonFromForm(this.dimensionForm);
    this.dimensionTemaService.actualizarDimensionTema(this.dimensionId, this.temaId, this.token, jsonData)
      .subscribe((respuesta: DimensionTemaResponse) => {
        if (respuesta.success) {
          this.toastr.success('Tema actualizado correctamente', 'Actualizar Tema');
          this.get();
          this.editableSwitcher();
        }
      }, error => {
        this.errorService.handleError(error);
        this.dimensionForm.enable();
      }
      ).add(() => { this.process = false; });
  }

  volver() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

  public editableSwitcher() {
    this.dimensionForm = this.fS.cambiarEstado(this.dimensionForm, this.editable);
    this.editable = !this.editable;
  }

}
