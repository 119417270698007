import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {RespuestaMonitoreoService} from '../../../../services/respuesta-monitoreo.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {FormStatusService} from '../../../../../../../../util/services/form-status/form-status.service';
import {ErrorService} from '../../../../../../../../util/services/error.service';
import {ToastrService} from 'ngx-toastr';
import {PoolPreguntasService} from '../../../../../../../parametricos-proyecto/pool-preguntas/services/pool-preguntas.service';

@Component({
  selector: 'app-editar-respuesta-actor',
  templateUrl: './editar-respuesta-actor.component.html',
  styleUrls: ['./editar-respuesta-actor.component.scss']
})
export class EditarRespuestaActorComponent implements OnInit {

  editForm: FormGroup;
  cargando: boolean;
  process: boolean;
  private encuestaId: number;
  encuestaRespuesta: any;
  editable = true;
  opciones = [];

  constructor(private matDialogRef: MatDialogRef<EditarRespuestaActorComponent>,
              @Inject(MAT_DIALOG_DATA) private data: any,
              private respuestaMonitoreoService: RespuestaMonitoreoService,
              private fB: FormBuilder, private fS: FormStatusService,
              private errorService: ErrorService, private toastr: ToastrService,
              private poolPreguntasService: PoolPreguntasService) {
  }

  ngOnInit() {
    this.encuestaId = this.data.encuestaId;
    this.encuestaRespuesta = this.data.element;
    this.getOpciones();
    this.makeForm();
  }

  makeForm() {
    this.editForm = this.fB.group({
      enc_opcion_id: [{value: null, disabled: this.editable}],
      respuesta_libre: [{value: null, disabled: this.editable}],
      latitud: [{value: null, disabled: this.editable}],
      longitud: [{value: null, disabled: this.editable}]
    });
    this.editForm.patchValue(this.encuestaRespuesta);
    console.log(this.editForm);
  }

  getOpciones() {
    // Solo se traerán las opciones si la pregunta lo requiere
    if (this.encuestaRespuesta.enc_opcion_id) {
      this.cargando = true;
      this.poolPreguntasService.obtenerPregunta(this.encuestaRespuesta.enc_pregunta_id)
        .subscribe((response: any) => {
          this.opciones = response.data.opciones;
        }, error => {
          this.errorService.handleError(error);
        }, () => {
          this.cargando = false;
        });
    }
  }

  onNoClick() {
    this.matDialogRef.close(false);
  }

  actualizar() {
    this.process = true;
    const jsonData = this.fS.getJsonFromForm(this.editForm);
    this.respuestaMonitoreoService.editarRespuestaInstancia(this.encuestaId, this.encuestaRespuesta.enc_instancia_id,
      this.encuestaRespuesta.id, jsonData)
      .subscribe((response: any) => {
        if (response.success) {
          this.toastr.success('La respuesta ha sido editada correctamente', 'Editar Respuesta');
        }
      }, error => {
        this.errorService.handleError(error);
      }, () => {
        this.matDialogRef.close(true);
      }).add(() => {
      this.process = false;
    });
  }

  editableSwitcher() {
    this.editForm = this.fS.cambiarEstado(this.editForm, this.editable);
    this.editable = !this.editable;
  }
}
