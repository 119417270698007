import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-recuperar-exito',
  templateUrl: './recuperar-exito.component.html',
  styleUrls: ['./recuperar-exito.component.sass']
})
export class RecuperarExitoComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    setTimeout(() => { this.goToLogin(); }, 3000);
  }

  goToLogin() {
    this.router.navigate(['../login'], { relativeTo: this.route });
  }



}
