import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mercado-gestion-proyecto',
  templateUrl: './mercado-gestion-proyecto.component.html',
  styleUrls: ['./mercado-gestion-proyecto.component.sass']
})
export class MercadoGestionProyectoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
