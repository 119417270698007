import { DetalleConfigProyectoInversoresComponent } from './detalle-config-proyecto-inversores/detalle-config-proyecto-inversores.component';
import { CrearConfigProyectoInversoresComponent } from './crear-config-proyecto-inversores/crear-config-proyecto-inversores.component';
import { ConfigProyectoInversoresService } from './services/config-proyecto-inversores.service';
import { Component, OnInit, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from 'src/app/util/services/error.service';
import { MatDialog } from '@angular/material';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-config-proyecto-inversores',
  templateUrl: './config-proyecto-inversores.component.html',
  styleUrls: ['./config-proyecto-inversores.component.sass']
})
export class ConfigProyectoInversoresComponent implements OnInit {

  @Input() proyectoId: number;
  cargando: boolean;
  proyectoInversores = [
    {
      id: 1,
      nombre: 'Inversor 1'
    }
  ];
  displayedColumns = ['nombre', 'acciones'];
  selected = null;
  total: number;

  constructor(  private configProyectoInversoresService: ConfigProyectoInversoresService,
                private toastr: ToastrService,
                private errorService: ErrorService,
                public dialog: MatDialog ) { }

  ngOnInit() {
    this.getProyectoInversores();
  }

  getProyectoInversores() {
    this.cargando = true;
    this.configProyectoInversoresService.getInversores(this.proyectoId)
      .subscribe((respuesta: any) => {
        this.proyectoInversores = respuesta.data;
        this.total = respuesta.metadata.total;
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
        this.cargando = false;
      });
  }

  crear() {
    const dialogRef = this.dialog.open(CrearConfigProyectoInversoresComponent, {
      width: '700px',
      data: { proyectoId: this.proyectoId }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'created') {
        this.getProyectoInversores();
      }
    });
  }

  verDetalle(id: number) {
    const dialogRef = this.dialog.open(DetalleConfigProyectoInversoresComponent, {
      width: '700px',
      data: { proyectoId: this.proyectoId, inversorId: id}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'updated') {
        this.getProyectoInversores();
      }
    });
  }

  changeBackGroundColor(event, row) {
    this.selected = event.type == 'mouseover' ? row.id : null;
  }

  borrar(id: number) {
    this.configProyectoInversoresService.borrarInversor(this.proyectoId, id)
      .subscribe((respuesta: any) => {
        if (respuesta.success) {
          this.toastr.success('Inversor borrado exitosamente', 'Borrar Inversor');
        }
        this.getProyectoInversores();
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
      }, () => { });
  }
}
