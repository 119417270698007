import { CombosService } from 'src/app/util/services/combos/combos.service';
import { ConfiguracionProyectoResponse } from './../interfaces/configuracion-proyecto-response';
import { Component, OnInit, Inject } from '@angular/core';
import { ConfiguracionProyecto } from '../interfaces/configuracion-proyecto';
import { ConfiguracionesProyectoService } from '../services/configuraciones-proyecto.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DetalleTemaIndicadorComponent } from 'src/app/Pages/parametricos-proyecto/dimensiones/dimension-temas/tema-indicadores/detalle-tema-indicador/detalle-tema-indicador.component';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from 'src/app/util/services/error.service';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { Combo } from 'src/app/util/interfaces/combo';
import { CombosResponse } from 'src/app/util/interfaces/combos-response';

export interface DialogData {
  proyectoId: number;
  configuracionId: number;
}
@Component({
  selector: 'app-detalle-configuracion-proyecto',
  templateUrl: './detalle-configuracion-proyecto.component.html',
  styleUrls: ['./detalle-configuracion-proyecto.component.sass']
})
export class DetalleConfiguracionProyectoComponent implements OnInit {

  public configuracionForm: FormGroup;
  editable = true;
  configuracionId: number;
  proyectoId: number;
  cargando: boolean;
  process: boolean;
  private token;
  configuracion: ConfiguracionProyecto;
  configuraciones: Combo[];

  constructor(public dialogRef: MatDialogRef<DetalleConfiguracionProyectoComponent>, private fB: FormBuilder, private comboService: CombosService,
    private configuracionProyectoService: ConfiguracionesProyectoService, private fS: FormStatusService, private toastr: ToastrService,
    private errorService: ErrorService, private localStorage: LocalStorageService, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
    this.proyectoId = this.data.proyectoId;
    this.configuracionId = this.data.configuracionId;
    this.token = this.localStorage.getTokenFromStorage();
    this.makeForm();
    this.getConfiguracionesCombo();
    this.get();
  }

  makeForm() {
    this.configuracionForm = this.fB.group({
      pro_configuracion_id: [{ value: null, disabled: this.editable }, Validators.required],
    });
  }

  get() {
    this.cargando = true;
    this.configuracionProyectoService.getConfiguracion(this.proyectoId, this.configuracionId, this.token)
      .subscribe((respuesta: ConfiguracionProyectoResponse) => {
        this.configuracion = respuesta.data;
        this.configuracionForm.patchValue(this.configuracion);
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
        this.cargando = false;
      });
  }
  actualizar() {
    this.process = true;
    const jsonData = this.fS.getJsonFromForm(this.configuracionForm);
    this.configuracionProyectoService.actualizarConfiguracion(this.proyectoId, this.configuracionId, this.token, jsonData)
      .subscribe((respuesta: ConfiguracionProyectoResponse) => {
        if (respuesta.success) {
          this.toastr.success('Configuración actualizada correctamente', 'Actualizar Configuración');
          this.get();
          this.editableSwitcher();
          this.dialogRef.close('updated');
        }
      }, error => {
        this.errorService.handleError(error);
        this.configuracionForm.enable();
      }).add(() => { this.process = false; });
  }

  volver() {
    this.dialogRef.close();
  }

  public editableSwitcher() {
    this.configuracionForm = this.fS.cambiarEstado(this.configuracionForm, this.editable);
    this.editable = !this.editable;
  }

  getConfiguracionesCombo() {
    this.comboService.getConfiguracionesCombo(this.token)
      .subscribe((respuesta: CombosResponse) => {
        this.configuraciones = respuesta.data;
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
      });
  }

}
