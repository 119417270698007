import { NumberFormatPipe } from 'src/app/util/pipes/numberFormat.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterPipePipe } from './filter-pipe.pipe';
import { TypeofPipe } from './typeof.pipe';
import { HasValuePipe } from './has-value.pipe';

@NgModule({
  declarations: [
    NumberFormatPipe,
    FilterPipePipe,
    TypeofPipe,
    HasValuePipe
  ],
  imports: [
    CommonModule
  ],
    exports: [
        NumberFormatPipe,
        FilterPipePipe,
        TypeofPipe,
        HasValuePipe
    ]
})
export class AppPipesModule { }
