import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-botonera-edicion',
  templateUrl: './botonera-edicion.component.html',
  styleUrls: ['./botonera-edicion.component.sass']
})
export class BotoneraEdicionComponent implements OnInit {

  @Output() clickCancelEvent = new EventEmitter();
  @Output() clickEditEvent = new EventEmitter();
  @Output() clickUpdateEvent = new EventEmitter();
  @Output() clickGoBackEvent = new EventEmitter();

  @Input() isModal: boolean;
  @Output() clickCloseEvent = new EventEmitter();

  @Input() editable: boolean;
  @Input() process: boolean;
  @Input() valid: boolean;
  @Input() buttonTextActualizar = 'Actualizar';

  // Se le pasa el nombre del permiso de la funcionalidad, para verificar si tiene el permiso para ejecutar la operación
  @Input() permissionUpdate: string;
  // Permite establecer si se controlará el permiso o no
  @Input() controlarPermiso = true;
  showEditarButton: boolean;

  constructor(private permissionService: NgxPermissionsService) { }

  ngOnInit() {
    if(this.controlarPermiso) {
      this.verificarPermiso();
    } else {
      this.showEditarButton = true;
    }
  }

  editar() {
    this.clickEditEvent.emit(true);
  }

  cancelar() {
    this.clickCancelEvent.emit(true);
  }

  actualizar() {
    this.clickUpdateEvent.emit(true);
  }

  volver() {
    this.clickGoBackEvent.emit(true);
  }

  cerrar() {
    this.clickCloseEvent.emit(true);
  }

  verificarPermiso() {
    if (this.permissionUpdate) {
      this.permissionService.hasPermission(this.permissionUpdate).then(result => {
        if (result) {
          this.showEditarButton = true;
        } else {
          this.showEditarButton = false;
        }
      });
    }
  }

}
