import { DetalleEncuestaTipoComponent } from './encuestas-tipos/detalle-encuesta-tipo/detalle-encuesta-tipo.component';
import { CrearEncuestaTipoComponent } from './encuestas-tipos/crear-encuesta-tipo/crear-encuesta-tipo.component';
import { EncuestasTiposComponent } from './encuestas-tipos/encuestas-tipos.component';
import { DetalleInversoresTiposComponent } from './inversores-tipos/detalle-inversores-tipos/detalle-inversores-tipos.component';
import { CrearInversoresTiposComponent } from './inversores-tipos/crear-inversores-tipos/crear-inversores-tipos.component';
import { InversoresTiposComponent } from './inversores-tipos/inversores-tipos.component';
import { DetalleInversorComponent } from './inversores/detalle-inversor/detalle-inversor.component';
import { CrearInversorComponent } from './inversores/crear-inversor/crear-inversor.component';
import { InversoresComponent } from './inversores/inversores.component';
import { DetallePreguntaTipoComponent } from './preguntas-tipos/detalle-pregunta-tipo/detalle-pregunta-tipo.component';
import { CrearPreguntaTipoComponent } from './preguntas-tipos/crear-pregunta-tipo/crear-pregunta-tipo.component';
import { PreguntasTiposComponent } from './preguntas-tipos/preguntas-tipos.component';
import { PoolDetallePreguntaComponent } from './pool-preguntas/pool-detalle-pregunta/pool-detalle-pregunta.component';
import { PoolCrearPreguntaComponent } from './pool-preguntas/pool-crear-pregunta/pool-crear-pregunta.component';
import { PoolPreguntasComponent } from './pool-preguntas/pool-preguntas.component';
import { DetalleProyectoGobiernoComponent } from './proyectos-gobierno/detalle-proyecto-gobierno/detalle-proyecto-gobierno.component';
import { CrearProyectoGobiernoComponent } from './proyectos-gobierno/crear-proyecto-gobierno/crear-proyecto-gobierno.component';
import { ProyectosGobiernoComponent } from './proyectos-gobierno/proyectos-gobierno.component';
import { DetalleTallerTipoComponent } from './talleres-tipos/detalle-taller-tipo/detalle-taller-tipo.component';
import { CrearTallerTipoComponent } from './talleres-tipos/crear-taller-tipo/crear-taller-tipo.component';
import { TalleresTiposComponent } from './talleres-tipos/talleres-tipos.component';
import { DetalleDimensionTemaComponent } from './dimensiones/dimension-temas/detalle-dimension-tema/detalle-dimension-tema.component';
import { CrearDimensionTemaComponent } from './dimensiones/dimension-temas/crear-dimension-tema/crear-dimension-tema.component';
import { DetalleDocumentoEstadoComponent } from './documentos-estado/detalle-documento-estado/detalle-documento-estado.component';
import { CrearDocumentoEstadoComponent } from './documentos-estado/crear-documento-estado/crear-documento-estado.component';
import { DocumentosEstadoComponent } from './documentos-estado/documentos-estado.component';
import { DetalleConfiguracionParaproComponent } from './configuraciones/detalle-configuracion-parapro/detalle-configuracion-parapro.component';
import { CrearConfiguracionParaproComponent } from './configuraciones/crear-configuracion-parapro/crear-configuracion-parapro.component';
import { ConfiguracionesComponent } from './configuraciones/configuraciones.component';
import { DetalleRolActorComponent } from './roles-actores/detalle-rol-actor/detalle-rol-actor.component';
import { CrearRolActorComponent } from './roles-actores/crear-rol-actor/crear-rol-actor.component';
import { DetalleDimensionComponent } from './dimensiones/detalle-dimension/detalle-dimension.component';
import { CrearDimensionComponent } from './dimensiones/crear-dimension/crear-dimension.component';
import { DetalleTipoDocumentoComponent } from './tipos-documento/detalle-tipo-documento/detalle-tipo-documento.component';
import { CrearTipoDocumentoComponent } from './tipos-documento/crear-tipo-documento/crear-tipo-documento.component';
import { DetalleComponenteMonitoreoComponent } from './componentes-monitoreo/detalle-componente-monitoreo/detalle-componente-monitoreo.component';
import { CrearComponenteMonitoreoComponent } from './componentes-monitoreo/crear-componente-monitoreo/crear-componente-monitoreo.component';
import { DetalleComponenteGestionComponent } from './componentes-gestion/detalle-componente-gestion/detalle-componente-gestion.component';
import { CrearComponenteGestionComponent } from './componentes-gestion/crear-componente-gestion/crear-componente-gestion.component';
import { DetalleEstadoActorComponent } from './estados-actores/detalle-estado-actor/detalle-estado-actor.component';
import { CrearEstadoActorComponent } from './estados-actores/crear-estado-actor/crear-estado-actor.component';
import { DetalleEstadoProyectoComponent } from './estados-proyecto/detalle-estado-proyecto/detalle-estado-proyecto.component';
import { CrearEstadoProyectoComponent } from './estados-proyecto/crear-estado-proyecto/crear-estado-proyecto.component';
import { DetallePortafolioComponent } from './portafolios/detalle-portafolio/detalle-portafolio.component';
import { CrearPortafolioComponent } from './portafolios/crear-portafolio/crear-portafolio.component';
import { DimensionesComponent } from './dimensiones/dimensiones.component';
import { TiposDocumentoComponent } from './tipos-documento/tipos-documento.component';
import { ComponentesMonitoreoComponent } from './componentes-monitoreo/componentes-monitoreo.component';
import { ComponentesGestionComponent } from './componentes-gestion/componentes-gestion.component';
import { EstadosActoresComponent } from './estados-actores/estados-actores.component';
import { EstadosProyectoComponent } from './estados-proyecto/estados-proyecto.component';
import { PortafoliosComponent } from './portafolios/portafolios.component';
import { ParametricosProyectoComponent } from './parametricos-proyecto.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RolesActoresComponent } from './roles-actores/roles-actores.component';
import { CrearProgramaComponent } from './portafolios/programas/crear-programa/crear-programa.component';
import { DetalleProgramaComponent } from './portafolios/programas/detalle-programa/detalle-programa.component';
import {TalleresParticipantesComponent} from './talleres-participantes/talleres-participantes.component';
import {CrearTallerParticipanteComponent} from './talleres-participantes/crear-taller-participante/crear-taller-participante.component';
import {DetalleTallerParticipanteComponent} from './talleres-participantes/detalle-taller-participante/detalle-taller-participante.component';
import { TalleresFacilitadoresComponent } from './talleres-facilitadores/talleres-facilitadores.component';
import { DetalleTallerFacilitadorComponent } from './talleres-facilitadores/detalle-taller-facilitador/detalle-taller-facilitador.component';
import { CrearTallerFacilitadorComponent } from './talleres-facilitadores/crear-taller-facilitador/crear-taller-facilitador.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: ParametricosProyectoComponent
      },
      // Portafolios
      {
        path: 'portafolios',
        children: [
          {
            path: '',
            component: PortafoliosComponent
          },
          {
            path: 'new',
            component: CrearPortafolioComponent
          }, {
            path: ':portafolioId',
            children: [
              {
                path: '',
                component: DetallePortafolioComponent
              },
              // Programas
              {
                path: 'programas',
                children: [
                  {
                    path: 'new',
                    component: CrearProgramaComponent
                  }, {
                    path: ':programaId',
                    component: DetalleProgramaComponent
                  }
                ]
              }
            ]
          }
        ]
      },
      // Estados de Proyectos
      {
        path: 'estados-proyectos',
        children: [
          {
            path: '',
            component: EstadosProyectoComponent
          },
          {
            path: 'new',
            component: CrearEstadoProyectoComponent
          }, {
            path: ':id',
            component: DetalleEstadoProyectoComponent
          }
        ]
      },
      // Estado de Actores
      {
        path: 'estados-actores',
        children: [
          {
            path: '',
            component: EstadosActoresComponent,
          },
          {
            path: 'new',
            component: CrearEstadoActorComponent
          }, {
            path: ':id',
            component: DetalleEstadoActorComponent
          }
        ]
      },
      // Componentes de Gestión
      {
        path: 'componentes-gestion',
        children: [
          {
            path: '',
            component: ComponentesGestionComponent
          },
          {
            path: 'new',
            component: CrearComponenteGestionComponent
          }, {
            path: ':id',
            component: DetalleComponenteGestionComponent
          }
        ]
      },
      // Componentes de Monitoreo
      {
        path: 'componentes-monitoreo',
        children: [
          {
            path: '',
            component: ComponentesMonitoreoComponent
          },
          {
            path: 'new',
            component: CrearComponenteMonitoreoComponent
          }, {
            path: ':id',
            component: DetalleComponenteMonitoreoComponent
          }
        ]
      },
      // Tipos de Documento
      {
        path: 'tipos-documento',
        children: [
          {
            path: '',
            component: TiposDocumentoComponent
          },
          {
            path: 'new',
            component: CrearTipoDocumentoComponent
          }, {
            path: ':id',
            component: DetalleTipoDocumentoComponent
          }
        ]
      },
      // Dimensiones
      {
        path: 'dimensiones',
        children: [
          {
            path: '',
            component: DimensionesComponent
          },
          {
            path: 'new',
            component: CrearDimensionComponent
          }, {
            path: ':dimensionId',
            children: [
              {
                path: '',
                component: DetalleDimensionComponent
              },
              // Temas
              {
                path: 'temas',
                children: [
                  {
                    path: 'new',
                    component: CrearDimensionTemaComponent
                  },
                  {
                    path: ':temaId',
                    component: DetalleDimensionTemaComponent
                  }
                ]
              }
            ]
          }
        ]
      },
      // Roles de Actores
      {
        path: 'roles-actores',
        children: [
          {
            path: '',
            component: RolesActoresComponent
          },
          {
            path: 'new',
            component: CrearRolActorComponent
          }, {
            path: ':id',
            component: DetalleRolActorComponent
          }
        ]
      },
      // Configuraciones
      {
        path: 'configuraciones',
        children: [
          {
            path: '',
            component: ConfiguracionesComponent
          },
          {
            path: 'new',
            component: CrearConfiguracionParaproComponent
          },
          {
            path: ':id',
            component: DetalleConfiguracionParaproComponent
          }
        ]
      },
      // Documento Estados
      {
        path: 'documento-estados',
        children: [
          {
            path: '',
            component: DocumentosEstadoComponent
          },
          {
            path: 'new',
            component: CrearDocumentoEstadoComponent
          },
          {
            path: ':id',
            component: DetalleDocumentoEstadoComponent
          }
        ]
      },
      // Talleres Tipo
      {
        path: 'talleres-tipos',
        children: [
          {
            path: '',
            component: TalleresTiposComponent
          },
          {
            path: 'new',
            component: CrearTallerTipoComponent,
          },
          {
            path: ':id',
            component: DetalleTallerTipoComponent
          }
        ]
      },
      // ProyectosGobierno
      {
        path: 'proyectos-gobierno',
        children: [
          {
            path: '',
            component: ProyectosGobiernoComponent
          },
          {
            path: 'new',
            component: CrearProyectoGobiernoComponent
          },
          {
            path: ':id',
            component: DetalleProyectoGobiernoComponent
          }
        ]
      },
      // Pool de Preguntas
      {
        path: 'pool-preguntas',
        children: [
          {
            path: '',
            component: PoolPreguntasComponent
          }, {
            path: 'new',
            component: PoolCrearPreguntaComponent
          }, {
            path: ':id',
            component: PoolDetallePreguntaComponent
          }
        ]
      },
      // Preguntas Tipo
      {
        path: 'preguntas-tipo',
        children: [
          {
            path: '',
            component: PreguntasTiposComponent
          }, {
            path: 'new',
            component: CrearPreguntaTipoComponent
          }, {
            path: ':id',
            component: DetallePreguntaTipoComponent
          }
        ]
      },
      // Inversores
      {
        path: 'inversores',
        children: [
          {
            path: '',
            component: InversoresComponent,
          },
          {
            path: 'new',
            component: CrearInversorComponent
          },
          {
            path: ':id',
            component: DetalleInversorComponent
          }
        ]
      },
      // Inversores Tipos
      {
        path: 'inversores-tipos',
        children: [
          {
            path: '',
            component: InversoresTiposComponent
          },
          {
            path: 'new',
            component: CrearInversoresTiposComponent
          },
          {
            path: ':id',
            component: DetalleInversoresTiposComponent
          }
        ]
      },
      // Encuestas tipos
      {
        path: 'encuestas-tipos',
        children: [
          {
            path: '',
            component: EncuestasTiposComponent
          },
          {
            path: 'new',
            component: CrearEncuestaTipoComponent
          },
          {
            path: ':id',
            component: DetalleEncuestaTipoComponent
          }
        ]
      },
      // Talleres Participantes
      {
        path: 'talleres-participantes',
        children: [
          {
            path: '',
            component: TalleresParticipantesComponent
          },
          {
            path: 'new',
            component: CrearTallerParticipanteComponent,
          },
          {
            path: ':id',
            component: DetalleTallerParticipanteComponent
          }
        ]
      },
      // Talleres Facilitadores
      {
        path: 'talleres-facilitadores',
        children: [
          {
            path: '',
            component: TalleresFacilitadoresComponent
          },
          {
            path: 'new',
            component: CrearTallerFacilitadorComponent,
          },
          {
            path: ':id',
            component: DetalleTallerFacilitadorComponent
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ParametricosProyectoRoutingModule { }
