import { DatosNoEncontradosModule } from './../datos-no-encontrados/datos-no-encontrados.module';
import { SpinnerModule } from 'src/app/util/spinner/spinner.module';
import { BotonFiltroModule } from 'src/app/util/boton-filtro/boton-filtro.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddMiembroComponent } from './add-miembro.component';
import { SearchMiembroComponent } from './search-miembro/search-miembro.component';
import { MatButtonModule, MatTableModule, MatFormFieldModule, MatInputModule } from '@angular/material';

@NgModule({
  declarations: [AddMiembroComponent, SearchMiembroComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatTableModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    FlexLayoutModule,
    BotonFiltroModule,
    SpinnerModule,
    DatosNoEncontradosModule
  ],
  exports: [AddMiembroComponent],
  entryComponents: [
    SearchMiembroComponent
  ]
})
export class AddMiembroModule { }
