import { ErrorService } from './../../services/error.service';
import { CombosService } from './../../services/combos/combos.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-search-miembro',
  templateUrl: './search-miembro.component.html',
  styleUrls: ['./search-miembro.component.sass']
})
export class SearchMiembroComponent implements OnInit {

  cargando: boolean;
  proyectoId: number;
  searchForm: FormGroup;
  miembros: any[];
  displayedColumns: string[] = ['nombre', 'edad', 'sexo', 'acciones'];
  constructor(
    private matDialogRef: MatDialogRef<SearchMiembroComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fB: FormBuilder,
    private combosService: CombosService,
    private errorService: ErrorService
  ) { }

  ngOnInit() {
    this.proyectoId = this.data.proyectoId;
    this.makeForm();
    this.get();
  }

  makeForm() {
    this.searchForm = this.fB.group({
      pro_proyecto_id: this.proyectoId,
      nombre_completo: '',
      documento: ''
    });
  }

  get() {
    this.cargando = true;
    this.combosService.getActoresXProyectoEspecial(this.searchForm.value)
      .subscribe((response: any) => {
        if (response.success) {
          this.miembros = response.data;
        }
      }, error => {
        this.errorService.handleError(error);
      }, () => {
        this.cargando = false;
      });
  }

  sendElement(object) {
    this.matDialogRef.close(object)
  }

  onClick(event: any) {
    event ? this.filtrar() : this.onReset();
  }

  filtrar() {
    this.get();
  }


  onReset() {
    this.searchForm.reset();
    this.searchForm.get('pro_proyecto_id').setValue(this.proyectoId);
    this.get();
  }

}
