import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from 'src/app/Globals';

@Injectable({
  providedIn: 'root'
})
export class InversoresService {

  constructor(private http: HttpClient, private global: Globals) { }

  obtenerInversores(search = null, pagination = null) {
    const headers = this.global.getHeader();
    const url: string = this.global.makeUrl('/inversores', search, pagination);
    return this.http.get(url, { headers });
  }
  obtenerInversor(id) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/inversores/' + id;
    return this.http.get(url, { headers });
  }
  actualizarInversor(id, json) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/inversores/' + id;
    return this.http.put(url, json, { headers });
  }
  crearInversor(json) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/inversores';
    return this.http.post(url, json, { headers });
  }
  borrarInversor(id) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/inversores/' + id;
    return this.http.delete(url, { headers });
  }
}
