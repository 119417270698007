import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-detalle-indicador-monitoreo-proyecto',
  templateUrl: './detalle-indicador-monitoreo-proyecto.component.html',
  styleUrls: ['./detalle-indicador-monitoreo-proyecto.component.sass']
})
export class DetalleIndicadorMonitoreoProyectoComponent implements OnInit {

  public ocultarConfig = true;
  constructor( private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit() {
  }

  
  agregarProyecto() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
  cambiarEstadoConfig() {
    this.ocultarConfig = !this.ocultarConfig;
  }

  volver() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

}
