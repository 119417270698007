import { Inversor } from './interfaces/inversor';
import { InversoresService } from './services/inversores.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ErrorService } from 'src/app/util/services/error.service';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-inversores',
  templateUrl: './inversores.component.html',
  styleUrls: ['./inversores.component.sass']
})
export class InversoresComponent implements OnInit {

  heading = 'Aliados/Actores Institucionales';
  subheading = 'En este módulo se encuentra la lista de Aliados/Actores Institucionales.';
  icon = 'pe-7s-pen icon-gradient bg-ripe-malin';
  cargando: boolean;
  total: number;
  pagination = { page: 1, per_page: 10 };
  selected = null;
  displayedColumns = ['nombre', 'codigo', 'tipo', 'descripcion', 'acciones'];

  inversores: Inversor[];
  searchForm: FormGroup;
  constructor(private router: Router,
    private route: ActivatedRoute,
    private fB: FormBuilder,
    private inversoresService: InversoresService,
    private errorService: ErrorService, private toastr: ToastrService) { }

  ngOnInit() {
    this.makeSearchForm();
    this.getInversores();
  }

  makeSearchForm() {
    this.searchForm = this.fB.group({
      nombre: '',
      codigo: ''
    });
  }

  getInversores() {
    this.cargando = true;
    this.inversoresService.obtenerInversores(this.searchForm.value, this.pagination)
      .subscribe((respuesta: any) => {
        this.inversores = respuesta.data;
        this.total = respuesta.metadata.total;
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
        this.cargando = false;
      });
  }

  crear() {
    this.router.navigate(['./new'], { relativeTo: this.route });
  }

  verDetalle(id: number) {
    this.router.navigate(['./', id], { relativeTo: this.route });
  }

  borrar(id: number) {
    this.inversoresService.borrarInversor(id)
      .subscribe((respuesta: any) => {
        if (respuesta.success) {
          this.toastr.success('Aliado/Actor Institucional borrado exitosamente', 'Borrar Aliado/Actor Institucional');
        }
        this.getInversores();
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
      }, () => { });
  }

  onReset() {
    this.pagination.page = 1;
    this.searchForm.reset();
    this.getInversores();
  }

  filtrar() {
    this.pagination.page = 1;
    this.getInversores();
  }

  pageChange() {
    this.getInversores();
  }
  onClick(event: any) {
    event ? this.filtrar() : this.onReset();
  }

  changeBackGroundColor(event, row) {
    this.selected = event.type == 'mouseover' ? row.id : null;
  }

  evento($event: MouseEvent) {
    $event.preventDefault();
    this.volver();
  }

  volver() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

}
