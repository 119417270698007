import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { ErrorService } from 'src/app/util/services/error.service';
import { ToastrService } from 'ngx-toastr';
import { TalleresFacilitadores } from '../interfaces/talleres-facilitadores'
import { TalleresFacilitadoresService } from '../services/talleres-facilitadores.service'

@Component({
  selector: 'app-detalle-taller-facilitador',
  templateUrl: './detalle-taller-facilitador.component.html',
  styleUrls: ['./detalle-taller-facilitador.component.sass']
})
export class DetalleTallerFacilitadorComponent implements OnInit {


  public tallerFacilitadorForm: FormGroup;
  editable = true;
  cargando: boolean;
  tallerFacilitadorId: number;
  tallerFacilitador: TalleresFacilitadores;
  process: boolean;



  constructor(private fB: FormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private fS: FormStatusService,
              private tallerFacilitadoresService: TalleresFacilitadoresService,
              private errorService: ErrorService,
              private toastr: ToastrService) { }

  ngOnInit() {
    this.tallerFacilitadorId = +this.route.snapshot.paramMap.get('id');
    this.makeForm();
    this.get();
  }

  makeForm() {
    this.tallerFacilitadorForm = this.fB.group({
      nombre: [{ value: '', disabled: this.editable }, Validators.required],
      codigo: [{ value: '', disabled: this.editable }],
      orden: [{ value: '', disabled: this.editable }, Validators.required],
      descripcion: [{ value: '', disabled: this.editable }]
    });
  }

  get() {

    this.cargando = true;
    this.tallerFacilitadoresService.obtenerTallerFacilitador(this.tallerFacilitadorId)
      .subscribe((respuesta: any) => {
        this.tallerFacilitador = respuesta.data;
        this.tallerFacilitadorForm.patchValue(this.tallerFacilitador);
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
        this.cargando = false;
      }); 
  }

  actualizar() {
    this.process = true;
    const jsonData = this.fS.getJsonFromForm(this.tallerFacilitadorForm);
    this.tallerFacilitadoresService.actualizarTallerFacilitador(this.tallerFacilitadorId, jsonData)
      .subscribe((respuesta: any) => {
        if (respuesta.success) {
          this.toastr.success('Taller Facilitador actualizado correctamente', 'Actualizar Taller Facilitador');
           this.get();
           this.editableSwitcher();
        }
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => { this.process = false; });
  }


  volver() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  public editableSwitcher() {
    this.tallerFacilitadorForm = this.fS.cambiarEstado(this.tallerFacilitadorForm, this.editable, ['codigo'], true);
    this.editable = !this.editable;
  }

}
