import {FormStatusService} from '../../../../../util/services/form-status/form-status.service';
import {ToastrService} from 'ngx-toastr';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {ErrorService} from '../../../../../util/services/error.service';
import {DetalleRespuestaMonitoreoResponse} from './interfaces/detalle-respuesta-monitoreo-response';
import {RespuestaMonitoreoService} from '../services/respuesta-monitoreo.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Component, OnInit} from '@angular/core';
import {DetalleRespuestaMonitoreo} from './interfaces/detalle-respuesta-monitoreo';
import {RespuestaMonitoreoProyectoResponse} from '../interfaces/respuesta-monitoreo-proyecto-response';
import {saveAs} from 'file-saver';
import {Globals} from '../../../../../Globals';
import {environment} from '../../../../../../environments/environment';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {LocalStorageService} from '../../../../../util/local-storage.service';

@Component({
  selector: 'app-detalle-respuesta-monitoreo',
  templateUrl: './detalle-respuesta-monitoreo.component.html',
  styleUrls: ['./detalle-respuesta-monitoreo.component.sass']
})
export class DetalleRespuestaMonitoreoComponent implements OnInit {

  cargando: boolean;
  encuestaId: number;
  proyecctoId: number;
  encuesta: DetalleRespuestaMonitoreo;
  encuestaCabeceraForm: FormGroup;
  editable = true;
  process: boolean;
  processDownLoad: boolean;
  processPublish: boolean;
  processStatus: boolean;

  // Dashboard
  // dashboardId: string;
  trustedUrl: SafeUrl;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private respuestaMonitoreoService: RespuestaMonitoreoService,
              private errorService: ErrorService,
              private toastr: ToastrService,
              private fB: FormBuilder,
              private fS: FormStatusService, private sanitizer: DomSanitizer, private localStorage: LocalStorageService) {
  }

  ngOnInit() {
    this.proyecctoId = +this.route.snapshot.paramMap.get('idProyecto');
    this.encuestaId = +this.route.snapshot.paramMap.get('idEncuesta');
    // this.getDashboard();
    this.makeForm();
    this.getEncuesta();
  }

  // getDashboard() {
  //   this.dashboardId = this.localStorage.getParametrosFromStorage().DASHBOARD5_ID;
  // }

  makeForm() {
    this.encuestaCabeceraForm = this.fB.group({
      activo: [{value: null, disabled: this.editable}, Validators.required],
      codigo: [{value: null, disabled: this.editable}, Validators.required],
      descripcion: [{value: null, disabled: this.editable}],
      fecha_desde: [{value: null, disabled: this.editable}, Validators.required],
      fecha_hasta: [{value: null, disabled: this.editable}],
      nombre: [{value: null, disabled: this.editable}, Validators.required],
      enc_encuesta_tipo_id: [{value: null, disabled: this.editable}, Validators.required],
    });

  }

  getEncuesta() {
    this.cargando = true;
    this.respuestaMonitoreoService.getEncuesta(this.proyecctoId, this.encuestaId)
      .subscribe((respuesta: DetalleRespuestaMonitoreoResponse) => {
        this.encuesta = respuesta.data;
        this.encuestaCabeceraForm.patchValue(this.encuesta.cabecera);
      }, error => {
        this.errorService.handleError(error);
      }, () => {
        this.cargando = false;
      });
  }

  volver() {
    this.router.navigate(['../../../../../'], {relativeTo: this.route});
  }

  public editableSwitcher() {
    this.encuestaCabeceraForm = this.fS.cambiarEstado(this.encuestaCabeceraForm, this.editable, ['enc_encuesta_tipo_id', 'activo'], true);
    this.editable = !this.editable;
  }

  actualizar() {
    this.process = true;
    const jsonData = this.fS.getJsonFromForm(this.encuestaCabeceraForm);
    this.respuestaMonitoreoService.editarEncuesta(this.proyecctoId, this.encuestaId, jsonData)
      .subscribe((respuesta: RespuestaMonitoreoProyectoResponse) => {
        this.toastr.success('Encuesta actualizada exitosamente', 'Actualizar Encuesta');
        this.getEncuesta();
        this.editableSwitcher();
      }, error => {
        this.errorService.handleError(error);
      }, () => {
        this.process = false;
      });
  }

  cambiarEstado(status: boolean) {
    this.processStatus = true;
    const jsonData = JSON.stringify({activo: status});
    this.respuestaMonitoreoService.cambiarEstadoEncuesta(this.proyecctoId, this.encuesta.cabecera.id, jsonData)
      .subscribe((response: any) => {
        if (response.success) {
          if (status) {
            this.toastr.success('Se ha abierto la encuesta correctamente', 'Abrir Encuesta');
          } else {
            this.toastr.success('Se ha cerrado la encuesta correctamente', 'Cerrar Encuesta');
          }
        }
      }, error => {
        this.errorService.handleError(error);
      }, () => {
        this.getEncuesta();
      }).add(() => {
      this.processStatus = false;
    });
  }

  publicarEncuesta() {
    this.processPublish = true;
    this.respuestaMonitoreoService.publicarEncuesta(this.proyecctoId, this.encuesta.cabecera.id)
      .subscribe((response: any) => {
        if (response.success) {
          this.toastr.success('La encuesta ha sido publicada correctamente', 'Publicar Encuesta');
        }
      }, error => {
        this.errorService.handleError(error);
      }, () => {
        this.getEncuesta();
      }).add(() => {
      this.processPublish = false;
    });
  }

  descargarXLSXForm() {
    this.processDownLoad = true;
    this.respuestaMonitoreoService.exportarEncuestaXls(this.proyecctoId, this.encuesta.cabecera.id)
      .subscribe((response: any) => {
          const newBlob = response.slice(1, response.size, {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
          saveAs(newBlob, `${this.encuesta.cabecera.enc_encuesta_odk_id}-form.xlsx`);
        }, error => {
          this.errorService.handleError(error);
        }
      ).add(() => {
      this.processDownLoad = false;
    });
  }

  responderEncuesta() {
    window.open(`${environment.ODK_CENTRAL_ENDPOINTENKETO}-/single/${this.encuesta.cabecera.enketoId}?st=${this.encuesta.cabecera.public_link_st}`, '_blank');
  }
}
