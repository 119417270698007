import { NgxPermissionsService } from 'ngx-permissions';
import { TiposDocumentoResponse, TipoDocumentoResponse } from './interfaces/tipo-documento-response';
import { TipoDocumento } from './interfaces/tipo-documento';
import { TipoDocumentoService } from './services/tipo-documento.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { ErrorService } from 'src/app/util/services/error.service';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-tipos-documento',
  templateUrl: './tipos-documento.component.html',
  styleUrls: ['./tipos-documento.component.sass']
})
export class TiposDocumentoComponent implements OnInit {
  heading = 'Tipos de Documento';
  subheading = 'En este módulo se encuentra la lista de componentes de tipos de documentos.';
  icon = 'pe-7s-id icon-gradient bg-ripe-malin';
  cargando: boolean;
  total: number;
  pagination = { page: 1, per_page: 10 };
  selected = null;
  displayedColumns = ['nombre', 'codigo', 'descripcion', 'acciones'];
  private token;

  tiposDocumento: TipoDocumento[];
  searchForm: FormGroup;
  constructor(private router: Router, private route: ActivatedRoute, private fB: FormBuilder,
    private localStorage: LocalStorageService, private errorService: ErrorService, private toastr: ToastrService,
    private tipoDocumentoService: TipoDocumentoService, private permissionService: NgxPermissionsService) { }

  ngOnInit() {
    this.token = this.localStorage.getTokenFromStorage();
    this.makeSearchForm();
    this.getTipoDocumentos();
  }

  makeSearchForm() {
    this.searchForm = this.fB.group({
      nombre: '',
      codigo: ''
    });
  }

  getTipoDocumentos() {
    this.cargando = true;
    this.tipoDocumentoService.getTiposDocumento(this.token, this.searchForm.value, this.pagination)
      .subscribe((respuesta: TiposDocumentoResponse) => {
        this.tiposDocumento = respuesta.data;
        this.total = respuesta.metadata.total;
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
        this.cargando = false;
      });
  }

  crear() {
    this.router.navigate(['./new'], { relativeTo: this.route });
  }

  verDetalle(id: number) {
    this.permissionService.hasPermission('PARPRO/mant_documentos_proyectos_tipos/get442').then(result => {
      if (result) {
        this.router.navigate(['./', id], { relativeTo: this.route });
      }
    });
  }

  borrar(id: number) {
    this.tipoDocumentoService.borrarTipoDocumento(id, this.token)
      .subscribe((respuesta: TipoDocumentoResponse) => {
        if (respuesta.success) {
          this.toastr.success('Tipo de Documento borrado exitosamente', 'Borrar Tipo de Documento');
        }
        this.getTipoDocumentos();
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
      }, () => { });
  }

  onReset() {
    this.pagination.page = 1;
    this.searchForm.reset();
    this.getTipoDocumentos();
  }

  filtrar() {
    this.pagination.page = 1;
    this.getTipoDocumentos();
  }

  pageChange() {
    this.getTipoDocumentos();
  }
  onClick(event: any) {
    event ? this.filtrar() : this.onReset();
  }

  changeBackGroundColor(event, row) {
    this.selected = event.type == 'mouseover' ? row.id : null;
  }

  evento($event: MouseEvent) {
    $event.preventDefault();
    this.volver();
  }

  volver() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

}
