import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import {ErrorService} from '../../../../util/services/error.service';
import {LocalStorageService} from '../../../../util/local-storage.service';
import {FormBuilder} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {DimensionesService} from './services/dimensiones.service';
import {Dimension} from './interfaces/dimension.interface';
import {DimensionesResponse} from './interfaces/dimension-response.interface';
import {PersonasResponse} from '../../../Administracion/personas/interfaces/personas-response.interface';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'app-dimension-monitoreo-proyecto',
  templateUrl: './dimension-monitoreo-proyecto.component.html',
  styleUrls: ['./dimension-monitoreo-proyecto.component.sass']
})
export class DimensionMonitoreoProyectoComponent implements OnInit {
  private token;
  cargando: boolean;
  dimensiones: Dimension[];
  total: number;
  idProyecto: number;
  pagination = { page: 1, per_page: 10 };
  public lottieConfig = {
    path: 'assets/lottie/empty-box.json',
    renderer: 'canvas',
    autoplay: true,
    loop: true
  };
  constructor(
    private dimensionesServicio: DimensionesService,
    private router: Router,
    private route: ActivatedRoute,
    private errorService: ErrorService,
    private localStorage: LocalStorageService,
    private fB: FormBuilder,
    private toastr: ToastrService
  ) {
    this.token = this.localStorage.getTokenFromStorage();
  }

  ngOnInit() {
    this.idProyecto = +this.route.snapshot.paramMap.get('idProyecto');
    this.getDimensiones();
  }
  private getDimensiones() {
    this.cargando = true;
    this.dimensionesServicio.getDimensiones(this.token, this.idProyecto, null, this.pagination)
      .subscribe((respuesta: DimensionesResponse) => {
          this.dimensiones = respuesta.data;
          this.total = respuesta.metadata.total;
        }, error => {
          this.errorService.handleError(error);
        }, () => {
          this.cargando = false;
        }
      );
  }
  verDetalle(id) {
    this.router.navigate(['./', id], { relativeTo: this.route });
  }
  crearDimension() {
    this.router.navigate(['./new'], { relativeTo: this.route });
  }
  pageChange() {
    this.getDimensiones();
  }
  borrarDimension(id: number) {
    this.dimensionesServicio.borrarDimension(this.token, this.idProyecto, id)
    .subscribe((respuesta: PersonasResponse) => {
      this.toastr.success('Dimensión borrada exitosamente', 'Borrar Dimensión');
      this.getDimensiones();
    }, (error: HttpErrorResponse) => {
      this.errorService.handleError(error);
    }, () => {
    });
  }
}
