import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ErrorService} from '../../../../../util/services/error.service';
import {ToastrService} from 'ngx-toastr';
import {LocalStorageService} from '../../../../../util/local-storage.service';
import {TallerTipoService} from '../../../../parametricos-proyecto/talleres-tipos/services/taller-tipo.service';
import {NgxPermissionsService} from 'ngx-permissions';
import {
  TalleresTiposResponse,
  TallerTipoResponse
} from '../../../../parametricos-proyecto/talleres-tipos/interfaces/taller-tipo-response';
import {MatDialog} from '@angular/material';
import {CrearAccesoEncuestaComponent} from './crear-acceso-encuesta/crear-acceso-encuesta.component';
import {CrudUsuariosMovilesService} from '../crud-usuarios-moviles/services/crud-usuarios-moviles.service';
import {HttpErrorResponse} from '@angular/common/http';
import {CrudAccessoEncuestaService} from './services/crud-accesso-encuesta.service';

@Component({
  selector: 'app-crud-acceso-encuestas',
  templateUrl: './crud-acceso-encuestas.component.html',
  styleUrls: ['./crud-acceso-encuestas.component.scss']
})
export class CrudAccesoEncuestasComponent implements OnInit {

  cargando: boolean;
  total = 1;
  pagination = {page: 1, per_page: 10};
  selected = null;
  displayedColumns = ['nombre', 'codigo', 'acciones'];

  private token;
  accessosMoviles: any[];
  searchForm: FormGroup;
  @Input() proyectoId: number;


  constructor(private router: Router, private route: ActivatedRoute, private fB: FormBuilder, private errorService: ErrorService,
              private toastr: ToastrService, private localStorage: LocalStorageService,
              private permissionService: NgxPermissionsService, private crudAccesoEncuestaService: CrudAccessoEncuestaService,
              private matDialog: MatDialog) {
  }

  ngOnInit() {
    this.makeSearchForm();
    this.get();
  }

  makeSearchForm() {
    this.searchForm = this.fB.group({
      nombre: '',
      codigo: ''
    });
  }

  get() {
    this.cargando = true;
    this.crudAccesoEncuestaService.getList(this.proyectoId, this.searchForm.value, this.pagination)
      .subscribe((respuesta: any) => {
        this.accessosMoviles = respuesta.data;
        this.total = respuesta.metadata.total;
      }, error => {
        this.errorService.handleError(error);
      }, () => {
        this.cargando = false;
      });
  }

  crear() {
    this.router.navigate(['./new'], {relativeTo: this.route});
  }

  borrar(element) {
    this.crudAccesoEncuestaService.delete(this.proyectoId, element.xmlFormId, element.actor.id)
      .subscribe((respuesta: TallerTipoResponse) => {
        if (respuesta.success) {
          this.toastr.success('Acceso borrado exitosamente', 'Borrar Acceso');
        }
        this.get();
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
      }, () => {
      });
  }

  onReset() {
    this.pagination.page = 1;
    this.searchForm.reset();
    this.get();
  }

  filtrar() {
    this.pagination.page = 1;
    this.get();
  }

  pageChange() {
    this.get();
  }

  onClick(event: any) {
    event ? this.filtrar() : this.onReset();
  }

  changeBackGroundColor(event, row) {
    this.selected = event.type == 'mouseover' ? row.id : null;
  }

  evento($event: MouseEvent) {
    $event.preventDefault();
    this.volver();
  }

  volver() {
    this.router.navigate(['../../'], {relativeTo: this.route});
  }

  crearAccesoMovil() {
    this.matDialog.open(CrearAccesoEncuestaComponent, {
      width: '600px',
      data: this.proyectoId
    }).afterClosed().subscribe(result => {
      if (result) {
        this.get();
      }
    });
  }


}
