import { Injectable } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {ErrorResponse} from './error-response';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(private route: ActivatedRoute,
              private router: Router,
              private toastr: ToastrService) { }

  handleError(error: any) {

    if (error.status === 409 || error.status === 422 || error.status === 403 || error.status === 500) {
      const mensaje: ErrorResponse = error.error;
      if (mensaje.errors && mensaje.errors.length >= 0) {
        mensaje.errors.forEach(mensajeError => {
          this.toastr.error(mensajeError, 'Atención', {toastClass: 'toastr-custom-error'});
        });
      } else {
        if (mensaje.message) {
          this.toastr.error(mensaje.message, 'Atención', {toastClass: 'toastr-custom-error'});
        } else {
          this.toastr.error('Respuesta de servidor no esperada. Contacte con el administrador', 'Atención',
            {toastClass: 'toastr-custom-error'});
        }
      }
    } else {
      this.toastr.error('Error desconocido. Contacte con el administrador.', 'Atención', {toastClass: 'toastr-custom-error'});
    }
  }
}
