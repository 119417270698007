import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { ErrorService } from 'src/app/util/services/error.service';
import { GrupoAhorroService } from '../../services/grupo-ahorro.service';
import * as XLSX from 'xlsx';
import { ReportesGrupoAhorroService } from '../../services/reportes-grupo-ahorro.service';

@Component({
  selector: 'app-miembros-grupo-ahorro',
  templateUrl: './miembros-grupo-ahorro.component.html',
  styleUrls: ['./miembros-grupo-ahorro.component.sass']
})
export class MiembrosGruposAhorroComponent implements OnInit {
  heading = 'Miembros';
  subheading = 'En este módulo se encuentra la lista de miembros';
  icon = 'pe-7s-study icon-gradient bg-ripe-malin';
  cargando: boolean;
  total: number;
  pagination = { page: 1, per_page: 10 };
  selected = null;
  displayedColumns = ['nombre', 'info', 'estado'];
  miembros;
  searchForm: FormGroup;
  idEmpresa: any;
  idProyecto: any;
  idGrupo: any;
  downloading: boolean;
  reporteData: any;

  constructor(private router: Router, 
              private route: ActivatedRoute, 
              private fB: FormBuilder, 
              public dialog: MatDialog,
              private localStorageService: LocalStorageService,
              private toastr: ToastrService,
              private errorService: ErrorService,
              private grupoAhorroService: GrupoAhorroService,
              private reportesGrupoAhorroService: ReportesGrupoAhorroService
    ) { 

                this.searchForm = this.fB.group({
                  nombre: '',
                  codigo: ''
                });
              }

  ngOnInit(): void {
    this.idEmpresa = +this.route.snapshot.paramMap.get('idEmpresa');
    if (this.localStorageService.getStoreSearchFrom() === 'mant_acti_laboral') {
      this.searchForm.patchValue(this.localStorageService.getStoreSearch());
    }
    this.idProyecto = +this.route.snapshot.paramMap.get('idProyecto');
    this.idGrupo = +this.route.snapshot.paramMap.get('idGrupo');
    //this.getMiembros();
    this.getMiembros();
  }

  getMiembros(){
    this.cargando = true;
    this.grupoAhorroService.getMiembros(this.idProyecto, this.idGrupo)
      .subscribe((respuesta: any) => {
        this.miembros = respuesta.data;
        console.log(this.miembros);
        this.total = respuesta.metadata.total;
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
        this.cargando = false;
      });
  }
  
  /*getMiembros(){
      this.miembros = [];
      this.miembros.push(
        {
          id: 1,
          nombre: 'Jorge',
          apellido: 'Ruiz Diaz',
          aportes_social: '650.000',
          aportes_prestamo: '2.000.000',
          estado: 'Activo',
          acciones: 40,
        });
        this.miembros.push(
          {
          id: 2,
          nombre: 'Rodrigo',
          apellido: 'Gamarra',
          aportes_social: '300.000',
          aportes_prestamo: '450.000',
          estado: 'Inactivo',
          acciones: 10,
          });
          this.miembros.push(
            {
          id: 3,
          nombre: 'Miguel',
          apellido: 'Acuña',
          aportes_social: '1.200.000',
          aportes_prestamo: '1.800.000',
          estado: 'Activo',              
          acciones: 35,
            });
  }*/

  crear() {
    this.router.navigate(['./miembros/new'], { relativeTo: this.route });
  }

  borrar(){}

  verDetalle(id: number) {
    this.router.navigate(['./miembro-grupo-ahorro', id], {relativeTo: this.route});
  }

  evento($event: MouseEvent) {
    $event.preventDefault();
    this.volver();
  }

  onClick(event: any) {
    return;
  }

  pageChange() {
    return;
  }

  changeBackGroundColor(event, row) {
    this.selected = event.type == 'mouseover' ? row.id : null;
  }

  volver() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

  descargarReporte() {
    this.downloading = true;
    this.reportesGrupoAhorroService.getGruposAhorroMiembrosReporte(this.idGrupo)
      .subscribe((response: any) => {
        this.reporteData = response.data;
      }, error => {
        this.errorService.handleError(error);
        this.downloading = false;
      }, () => {
        this.exportAsExcel();
      });
  }

  exportAsExcel() {
    const exportedData = [];
    const excelFileName =  'grupos-ahorro-miembros'
    this.reporteData.forEach(value => {
      const obj = {
        nombre: `${value.nombre} ${value.apellido}`,
        documento: value.documento_completo,
        monto_fondo_social: (value.monto_fondo_social) ? value.monto_fondo_social : '0',
        monto_fondo_prestamo: (value.monto_fondo_prestamo) ? value.monto_fondo_prestamo : '0',
        cantidad_acciones: (value.cantidad_acciones) ? value.cantidad_acciones : '0',
        monto_multas: (value.monto_multas) ? value.monto_multas : '0',
        cantidad_multas: (value.cantidad_multas) ? value.cantidad_multas : '0',
        estado: (value.estado === 'A') ? 'Activo' : 'Inactivo'
      }
      exportedData.push(obj);
    });
    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.json_to_sheet(exportedData);
    XLSX.utils.book_append_sheet(workBook, workSheet, 'data');
    XLSX.writeFile(workBook, `${excelFileName}.xlsx`);
    this.downloading = false;
  }


}
