import { NgxPermissionsService } from 'ngx-permissions';
import { OpcionesPredeterminadasService } from './services/opciones-predeterminadas.service';
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { OpcionPregunta } from '../../../pool-preguntas/pool-detalle-pregunta/opciones-pregunta/interfaces/opcion-pregunta';
import { OpcionesPreguntaResponse, OpcionPreguntaResponse } from '../../../pool-preguntas/pool-detalle-pregunta/opciones-pregunta/interfaces/opcion-pregunta-response';
import { ErrorService } from 'src/app/util/services/error.service';
import { ToastrService } from 'ngx-toastr';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';

@Component({
  selector: 'app-crud-opciones-predeterminadas',
  templateUrl: './crud-opciones-predeterminadas.component.html',
  styleUrls: ['./crud-opciones-predeterminadas.component.sass']
})
export class CrudOpcionesPredeterminadasComponent implements OnInit {

  @Input() preguntaTipoId: number;
  opcionesForm: FormGroup;
  opciones: OpcionPregunta[];
  cargando: boolean;
  public borradoTextoOpcion = '¿Desea borrar una Opción?';
  constructor(private opcionesPredeterminadasService: OpcionesPredeterminadasService,
    private errorService: ErrorService, private toastr: ToastrService, private fB: FormBuilder,
    private fS: FormStatusService, private permissionService: NgxPermissionsService) { }

  ngOnInit() {
    this.cargarFormulario();
    this.getOpcionesPredeterminadas();
  }

  cargarFormulario() {
    this.opcionesForm = this.fB.group({
      opciones: this.fB.array([])
    });
  }
  getOpcionesPredeterminadas() {
    this.cargando = true;
    this.opcionesPredeterminadasService.obtenerOpcionesPredeterminadas(this.preguntaTipoId)
      .subscribe((respuesta: OpcionesPreguntaResponse) => {
        this.opciones = respuesta.data;
        if (respuesta.metadata.total > 0) {
          this.opcionesForm.setControl('opciones', this.setOpciones(this.opciones));
          this.opcionesForm.controls.opciones.disable();
        } else {
          this.opcionesForm.removeControl('opciones');
          this.opcionesForm.addControl('opciones', this.fB.array([]));
          this.opcionesForm.controls.opciones.disable();
        }
      }, error => {
        this.errorService.handleError(error);
      }, () => { this.cargando = false; });

  }

  private setOpciones(opciones: OpcionPregunta[]) {
    const formArray = new FormArray([]);
    opciones.forEach(opcion => {
      formArray.push(this.fB.group({
        codigo: opcion.codigo,
        enc_pregunta_tipo_id: opcion.enc_pregunta_id,
        id: opcion.id,
        texto: opcion.texto
      }));
    });
    return formArray;
  }

  addOpcionBtnClick() {
    (this.opcionesForm.controls.opciones as FormArray).push(this.addOpcionesForm());
  }

  addOpcionesForm(): FormGroup {
    return this.fB.group({
      codigo: undefined,
      enc_pregunta_tipo_id: this.preguntaTipoId,
      texto: undefined
    });
  }


  insertarOpcion(nuevaOpcion) {

    const formGroup = this.addOpcionesForm();

    formGroup.patchValue(nuevaOpcion);

    const jsonData = this.fS.getJsonFromForm(formGroup);

    if (nuevaOpcion.id) {
      this.opcionesPredeterminadasService.editarOpcionPredeterminada(this.preguntaTipoId, nuevaOpcion.id, jsonData)
        .subscribe((respuesta: OpcionPreguntaResponse) => {
          if (respuesta.success) {
            this.toastr.success('Opcion editada exitosamente', 'Edición de Opción');
            this.getOpcionesPredeterminadas();
          } else {
            this.toastr.error('Error al editar opción', 'Edición de Opción', { toastClass: 'toastr-custom-error' });
          }
        },
          error => {
            this.errorService.handleError(error);
          }).add(() => {
          });
    } else {
      this.opcionesPredeterminadasService.crearOpcionPredeterminada(this.preguntaTipoId, jsonData)
        .subscribe((respuesta: OpcionPreguntaResponse) => {
          if (respuesta.success) {
            this.toastr.success('Opción creada exitosamente', 'Creación de Opción');
            this.getOpcionesPredeterminadas();
          } else {
            this.toastr.error('Error al crear opción', 'Creación de Opción', { toastClass: 'toastr-custom-error' });
          }
        },
          error => {
            this.errorService.handleError(error);
          }).add(() => {
          });
    }
  }

  borrarOpcion(opcionId, index) {
    this.permissionService.hasPermission('PARPRO/mant_opciones_predeterminadas/delete220').then(result => {
      if (result) {
        if (opcionId) {
          this.opcionesPredeterminadasService.borrarOpcionPredeterminada(this.preguntaTipoId, opcionId)
            .subscribe((respuesta: OpcionPreguntaResponse) => {
              if (respuesta.success) {
                this.toastr.success('Opción borrada exitosamente', 'Borrar Opción');
                (this.opcionesForm.controls.opciones as FormArray).removeAt(index);
                this.getOpcionesPredeterminadas();
              } else {
                this.toastr.error('Error al borrar opción', 'Borrar Opción', { toastClass: 'toastr-custom-error' });
              }
            },
              error => {
                this.errorService.handleError(error);
              }).add(() => {
              });
        } else {
          (this.opcionesForm.controls.opciones as FormArray).removeAt(index);
        }
      }
    });
  }

  editarOpcion(index) {
    this.permissionService.hasPermission('PARPRO/mant_opciones_predeterminadas/update220').then(result => {
      if (result) {
        (this.opcionesForm.controls.opciones as FormArray).at(index).enable();
      }
    });
  }
}
