import { ComboComponentesResponse } from './../../../../util/interfaces/combos-response';
import { ConfigProyectosGestionResponse, ConfigProyectoGestionResponse } from './interfaces/config-proyecto-gestion-response';
import { DetalleConfigProyectoGestionComponent } from './detalle-config-proyecto-gestion/detalle-config-proyecto-gestion.component';
import { CrearConfigProyectoGestionComponent } from './crear-config-proyecto-gestion/crear-config-proyecto-gestion.component';
import { ConfigProyectoGestionService } from './services/config-proyecto-gestion.service';
import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from 'src/app/util/services/error.service';
import { CombosService } from 'src/app/util/services/combos/combos.service';
import { MatDialog } from '@angular/material';
import { HttpErrorResponse } from '@angular/common/http';
import { ConfigProyectoGestion } from './interfaces/config-proyecto-gestion';
import { ComboComponentes } from 'src/app/util/interfaces/combo';

@Component({
  selector: 'app-config-proyecto-gestion',
  templateUrl: './config-proyecto-gestion.component.html',
  styleUrls: ['./config-proyecto-gestion.component.sass']
})
export class ConfigProyectoGestionComponent implements OnInit {

  @Input() proyectoId: number;
  cargando: boolean;
  private token;
  proyectosGestion: ConfigProyectoGestion[];
  displayedColumns = ['nombre', 'codigo', 'acciones'];
  selected = null;
  componentesGestion: ComboComponentes[];

  // Creación & Edición de componentes de gestión
  public componenteGestionForm: FormGroup;
  process: boolean;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private localStorage: LocalStorageService,
    private fB: FormBuilder,
    private fS: FormStatusService,
    private configProyectoGestionService: ConfigProyectoGestionService,
    private toastr: ToastrService,
    private errorService: ErrorService,
    private comboService: CombosService,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.token = this.localStorage.getTokenFromStorage();
    this.getProyectosGestion();
    this.makeForm();
  }

  getProyectosGestion() {
    this.cargando = true;
    this.configProyectoGestionService.getProyectosGestion(this.token, this.proyectoId)
      .subscribe((respuesta: ConfigProyectosGestionResponse) => {
        this.proyectosGestion = respuesta.data;
      }, error => {
        this.errorService.handleError(error);
      }, () => {
        this.getComponentesGestion();
      });
  }

  makeForm() {
    this.componenteGestionForm = this.fB.group({
      pro_componente_gestion_id: [null, Validators.required],
    });
  }

  crear(value: ComboComponentes) {
    this.process = true;
    this.componenteGestionForm.get('pro_componente_gestion_id').setValue(value.id);
    const jsonData = this.fS.getJsonFromForm(this.componenteGestionForm);
    this.configProyectoGestionService.crearProyectoGestion(this.token, this.proyectoId, jsonData)
      .subscribe((respuesta: ConfigProyectoGestionResponse) => {
        if (respuesta.success) {
          this.toastr.success('Proyecto Gestión creado exitosamente', 'Crear Proyecto Gestión');
          this.getProyectosGestion();
        }
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
        this.componenteGestionForm.enable();
      }).add(() => { this.process = false; });
  }

  changeBackGroundColor(event, row) {
    this.selected = event.type == 'mouseover' ? row.id : null;
  }

  borrar(id: number) {
    this.configProyectoGestionService.borrarProyectoGestion(this.proyectoId, id, this.token)
      .subscribe((respuesta: ConfigProyectoGestionResponse) => {
        if (respuesta.success) {
          this.toastr.success('Proyecto gestión borrado exitosamente', 'Borrar Proyecto Gestión');
        }
        this.getProyectosGestion();
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
      }, () => { });
  }

  getComponentesGestion() {
    this.comboService.getComponentesGestionCombo(this.token)
      .subscribe((respuesta: ComboComponentesResponse) => {
        this.componentesGestion = respuesta.data;
      }, error => {
        this.errorService.handleError(error);
      }, () => {
        this.verificarActivos()
      });
  }

  verificarActivos() {
    this.componentesGestion.forEach(componenteGestionCombo => {
      this.proyectosGestion.forEach(proyectoGestion => {
        if (componenteGestionCombo.id === proyectoGestion.pro_componente_gestion_id) {
          componenteGestionCombo.activo = true;
          componenteGestionCombo.relacion_id = proyectoGestion.id;
        }
      });
    });
    this.cargando = false;
  }

}
