import { ParametrosData } from './../../../../../../../Pages/Login/interfaces/parametros';
import { ErrorService } from 'src/app/util/services/error.service';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { ToastrService } from 'ngx-toastr';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UsuarioPerfilResponse } from 'src/app/Pages/Administracion/usuarios/interfaces/usuarios-response.interface';
import { User } from 'src/app/auth/user';
import {UserProfileService} from '../services/user-profile.service';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.sass']
})
export class UserSettingsComponent implements OnInit {
  @Input() data: string;
  changePasswordForm: FormGroup;
  private token;
  usuario: User;
  public disabled = true;
  private noEditable;
  parametros: ParametrosData;
  @Output() close: EventEmitter<any> = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private formStatus: FormStatusService,
    private localStorage: LocalStorageService,
    private errorService: ErrorService,
    private userProfileServices: UserProfileService
  ) { }

  ngOnInit() {
    this.noEditable = ['email'];
    this.parametros = this.localStorage.getParametrosFromStorage();
    this.token = this.localStorage.getTokenFromStorage();
    this.usuario = this.localStorage.getUserFromStorage();
    this.loadForm();
  }
  loadForm() {
    this.changePasswordForm = this.formBuilder.group({
      email: { value: '', disabled: this.disabled },
      password_current: { value: '', disabled: this.disabled },
      password: [{ value: '', disabled: this.disabled }, [Validators.minLength(this.parametros.MINPASSWORD), Validators.maxLength(this.parametros.MAXPASSWORD)]],
      password_confirmation: [{ value: '', disabled: this.disabled }, [Validators.minLength(this.parametros.MINPASSWORD), Validators.maxLength(this.parametros.MAXPASSWORD)]],
    });
  }

  validar() {
    if (!this.changePasswordForm.value.password && !this.changePasswordForm.value.password_confirmation) {
      this.changePasswordForm.controls.password_current.disable();
      this.changePasswordForm.controls.password.disable();
      this.changePasswordForm.controls.password_confirmation.disable();
      this.updateUsuario();
    } else {
      if (this.changePasswordForm.value.password === this.changePasswordForm.value.password_confirmation) {
        this.updateUsuario();
      } else {
        this.toastr.error('Las contraseñas no coinciden. Ingrese las contraseñas de nuevo', 'Cambio de Contraseña');
      }
    }
  }

  public editableSwitcher(estado: string) {
    this.changePasswordForm = this.formStatus.cambiarEstado(this.changePasswordForm, this.disabled);
    this.disabled = !this.disabled;
    this.changePasswordForm.controls.email.setValue(undefined);
    this.changePasswordForm.controls.password_current.setValue(undefined);
    this.changePasswordForm.controls.password.setValue(undefined);
    this.changePasswordForm.controls.password_confirmation.setValue(undefined);
  }


  updateUsuario() {
    this.changePasswordForm.controls.password_current.enable();
    this.changePasswordForm.controls.password.enable();
    this.changePasswordForm.controls.password_confirmation.enable();
    const jsonData = this.formStatus.getJsonFromForm(this.changePasswordForm);
    this.userProfileServices.actualizarContrasenia(jsonData)
      .subscribe((respuesta: UsuarioPerfilResponse) => {
        if (respuesta.success) {
          this.toastr.success('Contraseña cambiada exitosamente', 'Cambio de Contraseña');
          this.editableSwitcher('E');
        } else {
          this.toastr.error('Error al cambiar contraseña', 'Cambio de Contraseña');
        }
      }, error => {
        this.errorService.handleError(error);
      }
      );
  }

  volver() {
    this.close.emit();
  }

}
