import { Globals } from './../../../../../../../../../Globals';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FacilitadoresInstanciaService {

  constructor(private http: HttpClient, private global: Globals) { }

  getList(instanciaId, search = null, pagination = null) {
    const headers = this.global.getHeader();
    const url: string = this.global.makeUrl(`/instanciasTalleres/${instanciaId}/gruposInstanciasFacilitadores`, search, pagination);
    return this.http.get(url, { headers });
  }
  getById(instanciaId, miembroId) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + `/instanciasTalleres/${instanciaId}/gruposInstanciasFacilitadores/${miembroId}`;
    return this.http.get(url, { headers });
  }
  actualizar(instanciaId, miembroId, json) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + `/instanciasTalleres/${instanciaId}/gruposInstanciasFacilitadores/${miembroId}`;
    return this.http.put(url, json, { headers });
  }
  crear(instanciaId, json) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + `/instanciasTalleres/${instanciaId}/gruposInstanciasFacilitadores`;
    return this.http.post(url, json, { headers });
  }
  borrar(instanciaId, miembroId) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + `/instanciasTalleres/${instanciaId}/gruposInstanciasFacilitadores/${miembroId}`;
    return this.http.delete(url, { headers });
  }
}
