import { CombosService } from 'src/app/util/services/combos/combos.service';
import { TallerParticipanteResponse } from './../interfaces/taller-participante-response';
import { TallerParticipantesService } from './../services/taller-participantes.service';
import { Component, OnInit, Inject } from '@angular/core';
import { TallerParticipante } from '../interfaces/taller-participante';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from 'src/app/util/services/error.service';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { ComboActoresResponse } from 'src/app/util/interfaces/combos-response';
import { ComboActores } from 'src/app/util/interfaces/combo';

export interface DialogData {
  tallerId: number;
  participanteId: number;
  proyectoId: number;
}
@Component({
  selector: 'app-detalle-taller-participante',
  templateUrl: './detalle-taller-participante.component.html',
  styleUrls: ['./detalle-taller-participante.component.sass']
})
export class DetalleTallerParticipanteComponent implements OnInit {

  public participanteForm: FormGroup;
  editable = true;
  participanteId: number;
  private proyectoId: number;
  tallerId: number;
  cargando: boolean;
  private token;
  participante: TallerParticipante;
  actores: ComboActores[];
  process: boolean;
  constructor(public dialogRef: MatDialogRef<DetalleTallerParticipanteComponent>, private fB: FormBuilder, private comboService: CombosService,
    private tallerParticipanteService: TallerParticipantesService, private fS: FormStatusService, private toastr: ToastrService,
    private errorService: ErrorService, private localStorage: LocalStorageService, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
    this.token = this.localStorage.getTokenFromStorage();
    this.tallerId = this.data.tallerId;
    this.participanteId = this.data.participanteId;
    this.proyectoId = this.data.proyectoId;
    this.getActores();
    this.makeForm();
  }

  makeForm() {
    this.participanteForm = this.fB.group({
      tipo: [{ value: null, disabled: this.editable }, Validators.required],
      pro_actor_id: [{ value: undefined, disabled: this.editable }, Validators.required],
    });
    this.get();
  }

  get() {
    this.cargando = true;
    this.tallerParticipanteService.getTallerParticipante(this.tallerId, this.participanteId, this.token)
      .subscribe((respuesta: TallerParticipanteResponse) => {
        this.participante = respuesta.data;
        this.participanteForm.patchValue(this.participante);
      }, error => {
        this.errorService.handleError(error);
      }, () => {
        this.cargando = false;
      }
      );
  }

  getActores() {
    this.comboService.getProyectoActoresCombo(this.token, this.proyectoId)
      .subscribe((respuesta: ComboActoresResponse) => {
        this.actores = respuesta.data;
      }, error => {
        this.errorService.handleError(error);
      });
  }

  actualizar() {
    this.process = true;
    const jsonData = this.fS.getJsonFromForm(this.participanteForm);
    this.tallerParticipanteService.actualizarTallerParticipante(this.tallerId, this.participanteId, this.token, jsonData)
      .subscribe((respuesta: TallerParticipanteResponse) => {
        if (respuesta.success) {
          this.toastr.success('Participante actualizado correctamente', 'Actualizar Participante');
          this.dialogRef.close('updated');
        }
      }, error => {
        this.errorService.handleError(error);
        this.participanteForm.enable();
      }, () => {
        this.get();
        this.editableSwitcher();
      }).add(() => { this.process = false; });
  }

  volver() {
    this.dialogRef.close();
  }

  public editableSwitcher() {
    this.participanteForm = this.fS.cambiarEstado(this.participanteForm, this.editable);
    this.editable = !this.editable;
  }

}
