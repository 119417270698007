import { NumberFormatPipe } from './../../../../../../../util/pipes/numberFormat.pipe';
import { Location } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Combo } from 'src/app/util/interfaces/combo';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { CombosService } from 'src/app/util/services/combos/combos.service';
import { ErrorService } from 'src/app/util/services/error.service';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { CicloGrupoAhorro } from '../../../interfaces/grupo-ahorro.interface';
import { GrupoAhorroService } from '../../../services/grupo-ahorro.service'
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'app-periodos',
  templateUrl: './periodos.component.html',
  styleUrls: ['./periodos.component.sass'],
  providers: [NumberFormatPipe]
})
export class PeriodosComponent implements OnInit, AfterViewInit {

  @Output() selectedTabChange: EventEmitter<MatTabChangeEvent>
  @ViewChild("tab") tab: MatTabGroup;
  idProyecto: number;
  idGrupo: number;
  idCiclo: number;
  periodos = [];
  private token;
  cargando: boolean;
  periodo: CicloGrupoAhorro;
  editable = true;
  process = false;
  selected = null;
  searchForm: FormGroup;
  periodosForm: FormGroup;
  comunidades: Combo[];
  items;
  public selectedIndex: number;

  private valorSocial: number;
  private valorPrestamo: number;
  private valorMulta: number;
  constructor(
    private gruposAhorroService: GrupoAhorroService,
    private toastr: ToastrService,
    private errorService: ErrorService,
    private localStorage: LocalStorageService,
    private fB: FormBuilder,
    private fs: FormStatusService,
    private comboService: CombosService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private change: ChangeDetectorRef,
    private formatPipe: NumberFormatPipe
  ) {
    this.token = this.localStorage.getTokenFromStorage();
    this.periodosForm = fB.group({
      nombre: [{ value: null, disabled: this.editable }],
      aporte_fondo_social: [{ value: null, disabled: this.editable }],
      aporte_fondo_prestamo: [{ value: null, disabled: this.editable }],
      limite_acciones: [{ value: null, disabled: this.editable }],
      nombre_presidenta: [{ value: null, disabled: this.editable }],
      apellido_presidenta: [{ value: null, disabled: this.editable }],
      telefono_presidenta: [{ value: null, disabled: this.editable }],
      nombre_registradora: [{ value: null, disabled: this.editable }],
      apellido_registradora: [{ value: null, disabled: this.editable }],
      telefono_registradora: [{ value: null, disabled: this.editable }],
      // ver
      periodo_ciclo: [{ value: null, disabled: this.editable }],
      periodicidad_reuniones: [{ value: null, disabled: this.editable }],
      // dia_reuniones: [{value: '', disabled: this.editable}],
      // hora_reuniones: [{value: '', disabled: this.editable}],
      fecha_apertura_ciclo: [{ value: null, disabled: this.editable }],
      fecha_cierre_ciclo: [{ value: null, disabled: this.editable }],
      cantidad_miembros: [{ value: null, disabled: this.editable }],
      notas_adicionales: [{ value: null, disabled: this.editable }],
      estado_pago: [{ value: null, disabled: this.editable }],
      observaciones_pago: [{ value: null, disabled: this.editable }],
      monto_acumulado_social: [{ value: null, disabled: this.editable }],
      estado_reapertura: [{ value: null, disabled: this.editable }],
      estado: [{ value: null, disabled: this.editable }],
      estado_pago_kit: [{ value: null, disabled: this.editable }],
      valor_multa: [{ value: null, disabled: this.editable }],
      periodicidad_ciclo: [{ value: null, disabled: this.editable }]
    });
  }

  ngOnInit() {
    this.idProyecto = +this.route.snapshot.paramMap.get('idProyecto');
    this.idGrupo = +this.route.snapshot.paramMap.get('idGrupo');
    this.idCiclo = +this.route.snapshot.paramMap.get('idCiclo');
    // this.getGrupoGestion();
    // this.getComunidadesCombo();
    this.get();
  }

  ngAfterViewInit() {
    this.selectedIndex = this.localStorage.getSavedItem('ultimoTabUser') || 0;
    this.change.detectChanges();
  }


  get() {
    this.cargando = true;
    this.gruposAhorroService.getCiclo(this.idProyecto, this.idGrupo, this.idCiclo)
      .subscribe((response: any) => {
        if (response.success) {
          this.periodo = response.data;
          this.periodosForm.patchValue(this.periodo);
          this.setFormatedNumbers();
        }
      }, error => {
        this.errorService.handleError(error);
      }, () => {
        this.cargando = false;
      });
  }

  setFormatedNumbers() {
    if (this.periodo.aporte_fondo_social) {
      this.periodosForm.get('aporte_fondo_social').setValue(this.formatPipe.transform(this.periodo.aporte_fondo_social));
    }
    if (this.periodo.aporte_fondo_prestamo) {
      this.periodosForm.get('aporte_fondo_prestamo').setValue(this.formatPipe.transform(this.periodo.aporte_fondo_prestamo));
    }
    if (this.periodo.valor_multa) {
      this.periodosForm.get('valor_multa').setValue(this.formatPipe.transform(this.periodo.valor_multa));
    }
  }

  public onTabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.selectedIndex = tabChangeEvent.index;
    this.localStorage.saveItem('ultimoTabUser', this.selectedIndex);
    this.change.markForCheck();
  }

  public editableSwitcher() {
    this.periodosForm = this.fs.cambiarEstado(this.periodosForm, this.editable,
      ['cantidad_miembros', 'monto_acumulado_social', 'periodo_ciclo', 'fecha_apertura_ciclo', 'fecha_cierre_ciclo'], true);
    this.editable = !this.editable;
  }

  evento($event: MouseEvent) {
    $event.preventDefault();
    this.volver();
  }

  volver() {
    this.localStorage.saveItem('ultimoTabUser', 0);
    this.router.navigate(['../../../../../../../../'], { relativeTo: this.route });
  }

  volverGrupo() {
    this.router.navigate(['../../../../../../../../../'], { relativeTo: this.route });
  }

  actualizar() {
    this.process = true;
    this.getRealValues();
    const jsonData = this.fs.getJsonFromForm(this.periodosForm);
    this.gruposAhorroService.actualizarCiclo(this.idProyecto, this.idGrupo, this.idCiclo, jsonData)
      .subscribe((response: any) => {
        if (response.success) {
          this.toastr.success('Se actualizó correctamente el ciclo', 'Ciclos');
        }
      }, error => {
        this.errorService.handleError(error);
      }, () => {
        this.get();
        this.editableSwitcher();
      }).add(() => {
        this.process = false;
      });
  }


  getRealValues() {
    if (this.valorMulta) {
      this.periodosForm.get('valor_multa').setValue(this.valorMulta);
    }
    if (this.valorSocial) {
      this.periodosForm.get('aporte_fondo_social').setValue(this.valorSocial);
    }
    if (this.valorPrestamo) {
      this.periodosForm.get('aporte_fondo_prestamo').setValue(this.valorPrestamo);
    }
  }

  numberSeparator(event, controlName) {
    const realValue = +event.target.value;
    switch (controlName) {
      case 'aporte_fondo_social':
        this.valorSocial = realValue;
        break;
      case 'aporte_fondo_prestamo':
        this.valorPrestamo = realValue;
        break;
      case 'valor_multa':
        this.valorMulta = realValue;
        break;
    }
    this.periodosForm.get(controlName).setValue(this.formatPipe.transform(realValue));
  }
}
