import { Injectable } from '@angular/core';
import {Globals} from '../../../../../Globals';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GrupoAhorroService {

  constructor(
    private global: Globals,
    private http: HttpClient
  ) { }

  getGruposAhorro(token, idProyecto, search = null, pagination = null) {
    const headers = this.global.getHeader(token);
    const url = this.global.makeUrl('/proyectos/' + idProyecto + '/gruposAhorros', search, pagination);
    return this.http.get(url, {headers});
  }

  getGrupoAhorro(token, idProyecto, idGrupo, search = null, pagination = null) {
    const headers = this.global.getHeader(token);
    const url = this.global.makeUrl('/proyectos/' + idProyecto + '/gruposAhorros/' + idGrupo, search, pagination);
    return this.http.get(url, {headers});
  }

  editarGrupoAhorro(token, idProyecto, idGrupo, jsonData, search = null, pagination = null) {
    const headers = this.global.getHeader(token);
    const url = this.global.makeUrl('/proyectos/' + idProyecto + '/gruposAhorros/' + idGrupo, search, pagination);
    return this.http.put(url, jsonData, {headers});
  }

  borrarGrupoAhorro(token, idProyecto, idGrupo) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/proyectos/' + idProyecto + '/gruposAhorros';
    //const url = this.global.makeUrl('/proyectos/' + idProyecto + '/grupos/' + idGrupo, search, pagination);
    return this.http.delete(url, {headers});
  }

  crearGrupoAhorro(token, json, idProyecto) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/proyectos/' + idProyecto + '/gruposAhorros';
    return this.http.post(url, json, { headers });
  }

  // CICLOS

  getCiclos(idProyecto, idGrupo, search = null, pagination = null) {
    const headers = this.global.getHeader();
    const url = this.global.makeUrl('/proyectos/' + idProyecto + '/gruposAhorros/' + idGrupo + '/gruposCiclos', search, pagination);
    return this.http.get(url, {headers});
  }

  getCiclo(idProyecto, idGrupo, idCiclo) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/proyectos/' + idProyecto + '/gruposAhorros/' + idGrupo + '/gruposCiclos/' + idCiclo;
    return this.http.get(url, {headers});
  }

  crearCiclo(idProyecto, idGrupo, jsonData) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/proyectos/' + idProyecto + '/gruposAhorros/' + idGrupo + '/gruposCiclos';
    return this.http.post(url, jsonData, {headers});
  }

  crearMiembroCiclos(token, idProyecto, idGrupo, idGrupoCiclo, json){
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/proyectos/' + idProyecto + '/gruposAhorros/' + idGrupo + '/gruposCiclos/' + idGrupoCiclo + '/gruposCiclosMiembros';
    return this.http.post(url, json, { headers });
  }

  getMiembrosCiclos(idProyecto, idGrupo, idGrupoCiclo, search = null, pagination = null){
    const headers = this.global.getHeader();
    const url = this.global.makeUrl('/proyectos/' + idProyecto + '/gruposAhorros/' + idGrupo + '/gruposCiclos/' + idGrupoCiclo + '/gruposCiclosMiembros', search, pagination) ;
    return this.http.get(url, { headers });
  }

  actualizarCiclo(idProyecto, idGrupo, idCiclo, jsonData) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/proyectos/' + idProyecto + '/gruposAhorros/' + idGrupo + '/gruposCiclos/' + idCiclo;
    return this.http.put(url, jsonData, {headers});
  }

  // MIEMBROS

  getMiembros(idProyecto, idGrupo, search = null, pagination = null) {
    const headers = this.global.getHeader();
    const url = this.global.makeUrl('/proyectos/' + idProyecto + '/gruposAhorros/' + idGrupo + '/gruposAhorrosMiembros', search, pagination);
    return this.http.get(url, {headers});
  }

  getMiembroGrupoAhorro(idProyecto, idGrupo, grupoAhorroMiembroId){

    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/proyectos/' + idProyecto + '/gruposAhorros/' + idGrupo + '/gruposAhorrosMiembros/' + grupoAhorroMiembroId;
    return this.http.get(url, {headers});
  }

  getMiembroCiclo(idProyecto, idGrupo, grupoCicloId, grupoCicloMiembroId){
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/proyectos/' + idProyecto + '/gruposAhorros/' + idGrupo + '/gruposCiclos/' + grupoCicloId + '/gruposCiclosMiembros/' + grupoCicloMiembroId;
    return this.http.get(url, {headers});
  }

  uploadMiembros(idProyecto, idGrupo, idCiclo, formData) {
    const url = this.global.API_ENDPOINT_ES + `/proyectos/${idProyecto}/gruposAhorros/${idGrupo}/gruposCiclos/${idCiclo}/gruposCiclosMiembrosExcel`;
    return this.http.post(url, formData);
  }

  deleteMiembro(idProyecto, idGrupo, idCiclo, idMiembro) {
    const  headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + `/proyectos/${idProyecto}/gruposAhorros/${idGrupo}/gruposCiclos/${idCiclo}/gruposCiclosMiembros/${idMiembro}`;
    return this.http.delete(url, {headers});
  }

  updateMiembro(idProyecto, idGrupo, idCiclo, idMiembro, jsonData) {
    const  headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + `/proyectos/${idProyecto}/gruposAhorros/${idGrupo}/gruposCiclos/${idCiclo}/gruposCiclosMiembros/${idMiembro}`;
    return this.http.put(url, jsonData, {headers});
  }

  // REUNIONES

  getReuniones(idProyecto, idGrupo, idGrupoCiclo, search = null, pagination = null){
    const headers = this.global.getHeader();
    const url = this.global.makeUrl('/proyectos/' + idProyecto + '/gruposAhorros/' + idGrupo + '/gruposCiclos/' + idGrupoCiclo + '/gruposCiclosReunionesCabeceras', search, pagination);
    return this.http.get(url, { headers });
  }

  crearReuniones(token, idProyecto, idGrupo, idGrupoCiclo, json){
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/proyectos/' + idProyecto + '/gruposAhorros/' + idGrupo + '/gruposCiclos/' + idGrupoCiclo + '/gruposCiclosReunionesCabeceras';
    return this.http.post(url, json, { headers });
  }

  getReunion(idProyecto, idGrupo, idCiclo, idReunionesCabecera){
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/proyectos/' + idProyecto + '/gruposAhorros/' + idGrupo + '/gruposCiclos/' + idCiclo + '/gruposCiclosReunionesCabeceras/' + idReunionesCabecera + '/gruposCiclosReunionesDetalles';
    return this.http.get(url, {headers});
  }

  getReunionCabecera(idProyecto, idGrupo, idCiclo, idReunionesCabecera){
    const headers = this.global.getHeader();
    // const url = this.global.API_ENDPOINT_ES + '/proyectos/' + idProyecto + '/gruposAhorros/' + idGrupo + '/gruposCiclos/' + idCiclo + '/gruposCiclosReunionesCabeceras/' + idReunionesCabecera;
    const url = this.global.API_ENDPOINT_ES + '/proyectos/' + idProyecto + '/gruposCiclosReunionesCabeceras/' + idReunionesCabecera;
    return this.http.get(url, {headers});
  }

  editarReunion(idProyecto, idGrupo, idCiclo, idReunionCabecera, jsonData) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/proyectos/' + idProyecto + '/gruposAhorros/' + idGrupo + '/gruposCiclos/' + idCiclo + '/gruposCiclosReunionesCabeceras/' + idReunionCabecera + '/gruposCiclosReunionesDetalles';
    return this.http.put(url, jsonData, {headers});
  }


}
