import { ActivatedRoute, Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { TallerFormadoresResponse, TallerFormadorResponse } from './interfaces/taller-formador-response';
import { TallerFormador } from './interfaces/taller-formador';
import { DetalleTallerFormadorComponent } from './detalle-taller-formador/detalle-taller-formador.component';
import { CrearTallerFormadorComponent } from './crear-taller-formador/crear-taller-formador.component';
import { TallerFormadorService } from './services/taller-formador.service';
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { ErrorService } from 'src/app/util/services/error.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-taller-formador',
  templateUrl: './taller-formador.component.html',
  styleUrls: ['./taller-formador.component.sass']
})
/* INSTANCIAS */
export class TallerFormadorComponent implements OnInit {

  @Input() tallerId: number;
  @Input() proyectoId: number;
  // TIPO: P - F
  @Input() tipo: string;
  private token;
  pagination = { page: 1, per_page: 10 };
  selected = null;
  displayedColumns = ['taller', 'lugar' , 'fecha', 'facilitadores', 'acciones'];
  cargando: boolean;
  uploading: boolean;
  total: number;
  formadores: any[];
  file: any[];
  searchForm: FormGroup;

  constructor(private localStorage: LocalStorageService, private errorService: ErrorService, private toastr: ToastrService,
    private tallerFormadorService: TallerFormadorService, public dialog: MatDialog, private fB: FormBuilder,
    private permissionService: NgxPermissionsService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    console.log(this.tipo);
    this.token = this.localStorage.getTokenFromStorage();
    this.makeSearchForm();
    this.getFormadores();
  }

  makeSearchForm() {
    this.searchForm = this.fB.group({
      nombre: '',
      codigo: ''
    });
  }

  getFormadores() {
    this.cargando = true;
    this.tallerFormadorService.getTallerFormadores(this.token, this.tallerId, this.searchForm.value, this.pagination)
      .subscribe((respuesta: TallerFormadoresResponse) => {
        this.formadores = respuesta.data;
        this.total = respuesta.metadata.total;
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
        this.cargando = false;
      });
  }

  crear() {
    const dialogRef = this.dialog.open(CrearTallerFormadorComponent, {
      width: '700px',
      data: { tallerId: this.tallerId, proyectoId: this.proyectoId, tipo: this.tipo }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'created') {
        this.getFormadores();
      }
    });
  }

  verDetalle(id: number) {
    // this.permissionService.hasPermission('PRO/mant_talleres_formadores/get311').then((result) => {
    //   if (result) {
    //     const dialogRef = this.dialog.open(DetalleTallerFormadorComponent, {
    //       width: '700px',
    //       data: { tallerId: this.tallerId, formadorId: id, proyectoId: this.proyectoId }
    //     });
    //     dialogRef.afterClosed().subscribe(result => {
    //       if (result === 'updated') {
    //         this.getFormadores();
    //       }
    //     });
    //   }
    // });
    this.router.navigate(['./instancia', id], { relativeTo: this.route });
  }

  borrar(id: number) {
    this.tallerFormadorService.borrarTallerFormador(this.tallerId, id, this.token)
      .subscribe((respuesta: TallerFormadorResponse) => {
        if (respuesta.success) {
          this.toastr.success('Formador borrado exitosamente', 'Borrar Formador');
        }
        this.getFormadores();
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
      }, () => { });
  }

  onReset() {
    this.pagination.page = 1;
    this.searchForm.reset();
    this.getFormadores();
  }

  filtrar() {
    this.pagination.page = 1;
    this.getFormadores();
  }

  pageChange() {
    this.getFormadores();
  }
  onClick(event: any) {
    event ? this.filtrar() : this.onReset();
  }

  changeBackGroundColor(event, row) {
    this.selected = event.type == 'mouseover' ? row.id : null;
  }

  fileChangeEvent(files): void {
    this.uploading = true;
    // this.file = files[0];
    this.formadores = [
      {
        id: 1,
        nombre: 'Grupo 1',
        taller_nombre: 'Plan de Vida',
        lugar: 'Bo. Colón',
        fecha: '24/08/2020',
        integrantes: [
          {
            actor_nombre: 'Sofía Caceres'
          },
          {
            actor_nombre: 'Matias Orué'
          }
        ]
      },
      {
        id: 2,
        nombre: 'Grupo 2',
        taller_nombre: 'Plan de Vida',
        lugar: 'Capilla Natividad',
        fecha: '04/08/2020',
        integrantes: [
          {
            actor_nombre: 'Elias Caballero'
          },
          {
            actor_nombre: 'John Silver'
          },
          {
            actor_nombre: 'Josefina Barrios'
          }
        ]
      },
      {
        id: 3,
        nombre: 'Grupo 3',
        taller_nombre: 'GAI - Conformación y apertura grupos de ahorro',
        lugar: 'Seccional 1',
        fecha: '07/08/2020',
        integrantes: [
          {
            actor_nombre: 'Inocencio Martinez'
          },
          {
            actor_nombre: 'Beatriz Osorio'
          },
          {
            actor_nombre: 'Juan Carlos Benitez'
          },
          {
            actor_nombre: 'Y 2 personas más...'
          }
        ]
      }
    ];
    this.toastr.success('Instancias importados exitosamente', 'Importar Instancias');
    this.uploading = false;
  }

}
