import {Component, Input, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { CombosService } from 'src/app/util/services/combos/combos.service';
import { ErrorService } from 'src/app/util/services/error.service';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { GrupoAhorroService } from '../../../../services/grupo-ahorro.service';

@Component({
  selector: 'app-asistentes-reunion',
  templateUrl: './asistentes-reunion.component.html',
  styleUrls: ['./asistentes-reunion.component.sass']
})
export class AsistentesReunionComponent implements OnInit {

  asistenciaForm: FormGroup;
  cargando: boolean;
  process: boolean;
  editable = true;
  token;
  idProyecto;
  idGrupo;
  idCiclo;
  idReunion;
  miembros = [];
  reunion_detalle;
  valorCheck;

  constructor(
    private fB: FormBuilder,
    private fS: FormStatusService,
    private errorService: ErrorService,
    private toastr: ToastrService,
    private combosService: CombosService,
    private localStorage: LocalStorageService,
    private route: ActivatedRoute,
    private gruposAhorrosService: GrupoAhorroService
  ) {
    this.asistenciaForm = this.fB.group({
      miembros: this.fB.array([
        fB.group({
          id: [{ value: null, disabled: this.editable }, Validators.required],
          presente: [{ value: null, disabled: this.editable }, Validators.required],
          monto_fondo_social: [{ value: null, disabled: this.editable }, Validators.required],
          monto_fondo_prestamo: [{ value: null, disabled: this.editable }, Validators.required],
          cantidad_acciones: [{ value: null, disabled: this.editable }, Validators.required],
        })
      ])
    });
  }

  ngOnInit() {
    this.idProyecto = +this.route.snapshot.paramMap.get('idProyecto');
    this.idGrupo = +this.route.snapshot.paramMap.get('idGrupo');
    this.idCiclo = +this.route.snapshot.paramMap.get('idCiclo');
    this.idReunion = +this.route.snapshot.paramMap.get('idReunion');
    this.token = this.localStorage.getTokenFromStorage();
    this.idProyecto = +this.route.snapshot.paramMap.get('idProyecto');
    //this.getActores();
    this.get();
  }

  get() {
    this.cargando = true;
    this.gruposAhorrosService.getReunion(this.idProyecto, this.idGrupo, this.idCiclo, this.idReunion)
      .subscribe((response: any) => {
        if (response.success) {
          this.reunion_detalle = response.data;
          this.asistenciaForm.setControl('miembros', this.setMiembros(this.miembros));
          this.asistenciaForm.controls.descripciones.disable();
        }
      }, error => {
        this.errorService.handleError(error);
      }, ()=> {
        this.cargando = false;
      });
  }

  getActores(){
    this.combosService.getMiembrosGruposCiclos()
      .subscribe( (res:any) => {
        this.miembros = res.data;
        this.asistenciaForm.setControl('descripciones', this.setMiembros(this.miembros));
        console.log(this.miembros)
      })
  }

  private setMiembros(miembros) {
    const formArray = new FormArray([]);
    miembros.forEach(miembro => {
      formArray.push(this.fB.group({
        id: miembro.id,
        presente: miembro.presente,
        monto_fondo_social: miembro.monto_fondo_social,
        monto_fondo_prestamo: miembro.monto_fondo_prestamo,
        cantidad_acciones: miembro.cantidad_acciones,
      }));
    });
    return formArray;
  }

  borrarColumnaDescripcion(index: number) {
      (this.asistenciaForm.controls.descripciones as FormArray).removeAt(index);
  }

  addDescripcionBtnClick() {
    (this.asistenciaForm.controls.descripciones as FormArray).push(this.addDocumentoForm());
  }

  addDocumentoForm(): FormGroup {
    return this.fB.group({
      id: undefined,
      presente: undefined,
      monto_fondo_social: undefined,
      monto_fondo_prestamo: undefined,
      cantidad_acciones: undefined,
    });
  }

  insertarDocumento(nuevoDocumento) {
    const formGroup = this.addDocumentoForm();
    formGroup.patchValue(nuevoDocumento);
    console.log(`El nuevo documento ${nuevoDocumento}`)
    const jsonData = this.fS.getJsonFromForm(formGroup);
    console.log(jsonData);
    if (nuevoDocumento.id) {
      this.gruposAhorrosService.editarReunion(this.idProyecto, this.idGrupo, this.idCiclo, this.idReunion, jsonData)
        .subscribe((response: any) => {
            if (response.success) {
              this.toastr.success('Ítem editado exitosamente', 'Edición de Ítem');

            } else {
              this.toastr.error('Error al editar ítem', 'Edición de Ítem', { toastClass: 'toastr-custom-error' });
            }
          },
          error => {
            this.errorService.handleError(error);
          }).add(() => {
      });
    }
  }


  editarDocumento(index) {
    (this.asistenciaForm.controls.descripciones as FormArray).at(index).enable();
  }

  onCheckboxChange(e) {
    console.log(e.target.checked)
    if (e.target.checked === true) {
      this.valorCheck = 'P'
    } else {
       this.valorCheck = 'A'
    }
  }

}
