import {CombosResponse, ComboPaisesResponse} from './../../../util/interfaces/combos-response';
import {CombosService} from './../../../util/services/combos/combos.service';
import {PaisProyecto} from './../pais-proyecto/interfaces/pais-proyecto';
import {PaisesProyectoService} from './../pais-proyecto/services/paises-proyecto.service';
import {ErrorService} from 'src/app/util/services/error.service';
import {ToastrService} from 'ngx-toastr';
import {ProyectoResponse} from './../interfaces/proyecto-response';
import {ProyectosService} from './../services/proyectos.service';
import {FormStatusService} from 'src/app/util/services/form-status/form-status.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LocalStorageService} from 'src/app/util/local-storage.service';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';
import {PaisesProyectoResponse} from '../pais-proyecto/interfaces/pais-proyecto-response';
import {Combo, ComboPais} from 'src/app/util/interfaces/combo';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-crear-proyecto',
  templateUrl: './crear-proyecto.component.html',
  styleUrls: ['./crear-proyecto.component.sass'],
  providers: [DatePipe]
})
export class CrearProyectoComponent implements OnInit {

  public ocultarConfig = true;
  private token;
  nuevoProyecto: FormGroup;
  paises: ComboPais[];
  proyectoEstados: Combo[];
  programas: Combo[];
  portafolios: Combo[];
  process: boolean;
  pais: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private localStorage: LocalStorageService,
    private fB: FormBuilder,
    private fS: FormStatusService,
    private proyectoService: ProyectosService,
    private toastr: ToastrService,
    private errorService: ErrorService,
    private paisProyectoService: PaisesProyectoService,
    private comboService: CombosService,
    private datePipe: DatePipe
  ) {
  }

  ngOnInit() {
    this.token = this.localStorage.getTokenFromStorage();
    this.pais = this.localStorage.getSavedItem('pais_proyecto');
    this.makeForm();
    this.getPaises();
    this.getProyectoEstados();
    this.getPorfatolios();
  }

  getPaises() {
    this.comboService.getPaisesCombo()
      .subscribe((respuesta: ComboPaisesResponse) => {
        this.paises = respuesta.data;
      }, error => {
        this.errorService.handleError(error);
      }, () => {
        if (this.pais) {
          this.nuevoProyecto.get('adm_pais_id').setValue(this.pais.id);
          this.obtenerMoneda(this.pais.id);
        }
      }).add(() => {
    });
  }

  obtenerMoneda(paisId) {
    const moneda = this.paises.find(pais => pais.id === +paisId);
    this.nuevoProyecto.get('moneda').setValue(moneda.nombre_moneda);
  }

  getProyectoEstados() {
    this.comboService.getProyectoEstados()
      .subscribe((respuesta: CombosResponse) => {
          this.proyectoEstados = respuesta.data;
        }, error => {
          this.errorService.handleError(error);
        }
      ).add(() => {
    });
  }

  getProgramas(option) {
    const search = {pro_portafolio_id: option.value};
    this.comboService.getProgramas(search)
      .subscribe((respuesta: CombosResponse) => {
          this.programas = respuesta.data;
        }, error => {
          this.errorService.handleError(error);
        }
      ).add(() => {
      if (this.programas.length === 0) {
          this.nuevoProyecto.get('pro_programa_id').reset();
          this.nuevoProyecto.get('pro_programa_id').setValue(null);
          this.nuevoProyecto.get('pro_programa_id').disable();
      } else {
        this.nuevoProyecto.get('pro_programa_id').reset();
        this.nuevoProyecto.get('pro_programa_id').setValue(this.programas[0].id);
        this.nuevoProyecto.get('pro_programa_id').enable();
      }
    });
  }

  getPorfatolios() {
    this.comboService.getPortafoliosCombo()
      .subscribe((respuesta: CombosResponse) => {
          this.portafolios = respuesta.data;
        }, error => {
          this.errorService.handleError(error);
        }
      ).add(() => {
    });
  }

  makeForm() {
    this.nuevoProyecto = this.fB.group({
      pro_programa_id: [{value: null, disabled: true}, Validators.required],
      pro_portafolio_id: [null, Validators.required],
      adm_pais_id: [null, Validators.required],
      pro_proyecto_estado_id: [null, Validators.required],
      moneda: [{value: undefined, disabled: true}],
      nombre: [null, Validators.required],
      fecha_inicio: [null, Validators.required],
      fecha_fin: [null, Validators.required]
    });
  }

  agregarProyecto() {
    this.process = true;
    this.nuevoProyecto.controls.fecha_inicio.setValue(this.datePipe.transform(this.nuevoProyecto.controls.fecha_inicio.value, 'yyyy-MM-dd'));
    this.nuevoProyecto.controls.fecha_fin.setValue(this.datePipe.transform(this.nuevoProyecto.controls.fecha_fin.value, 'yyyy-MM-dd'));
    const jsonData = this.fS.getJsonFromForm(this.nuevoProyecto);
    this.proyectoService.crearProyecto(this.token, jsonData)
      .subscribe((respuesta: ProyectoResponse) => {
        if (respuesta.success) {
          this.toastr.success('Proyecto creado exitosamente', 'Crear Proyecto');
          this.goToProyecto(respuesta.data.id);
        }
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
        this.nuevoProyecto.enable();
      }).add(() => {
      this.process = false;
    });
  }

  cambiarEstadoConfig() {
    this.ocultarConfig = !this.ocultarConfig;
  }

  volver() {
    this.router.navigate(['../'], {relativeTo: this.route});
  }

  goToProyecto(id) {
    this.router.navigate(['../', id, 'editar-proyecto'], {relativeTo: this.route});
  }

  evento($event: MouseEvent) {
    $event.preventDefault();
    this.volver();
  }
}
