import { Proyecto, Componentes } from './../interfaces/proyecto';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { ErrorService } from 'src/app/util/services/error.service';
import { ToastrService } from 'ngx-toastr';
import { ProyectosService } from '../services/proyectos.service';
import { ProyectoResponse } from '../interfaces/proyecto-response';
import {Globals} from '../../../Globals';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-menu-detalle-proyecto',
  templateUrl: './menu-detalle-proyecto.component.html',
  styleUrls: ['./menu-detalle-proyecto.component.sass']
})
export class MenuDetalleProyectoComponent implements OnInit {
  public subruta: string;
  cargando: boolean;
  private token;
  componentesGestion: Componentes[];
  componentesMonitoreo: Componentes[];
  constructor(
    private route: ActivatedRoute,
    private localStorage: LocalStorageService, private errorService: ErrorService, private toastr: ToastrService,
    private proyectoService: ProyectosService, private globals: Globals
  ) { }
  @Input() activo: string;
  @Input() ruta: string;
  @Input() showBreadcrum: boolean;
  proyectoId: number;
  proyecto: Proyecto;
  bannerUrl: string;
  @Output() loaded?: EventEmitter<Proyecto> = new EventEmitter<Proyecto>();


  ngOnInit() {
    this.proyectoId = +this.route.snapshot.paramMap.get('idProyecto');
    this.token = this.localStorage.getTokenFromStorage();
    this.get();
    this.obtenerRuta();
  }
  get() {
    this.cargando = true;
    this.proyectoService.getProyecto(this.proyectoId, this.token)
      .subscribe((respuesta: ProyectoResponse) => {
        this.proyecto = respuesta.data;
        this.componentesGestion = this.proyecto.gestion;
        this.componentesMonitoreo = this.proyecto.monitoreo;
        this.bannerUrl =  `${this.globals.FILE_ENDPOINT}/storage/documentos/${this.proyecto.path_banner}`;
      }, error => {
        this.errorService.handleError(error);
      }, () => { this.loaded.emit(this.proyecto); }
      ).add(() => {
        this.cargando = false;
      });
  }

  obtenerRuta() {
    if (this.activo === 'gestiones') {
      this.subruta = 'Componente de Gestión';
    } else if (this.activo === 'monitoreos') {
      this.subruta = 'Componente de Monitoreo';
    } else if (this.activo === 'indicadores') {
      this.subruta = 'Cumplimiento Global Indicadores';
    } else if (this.activo === undefined) {
      this.subruta = '';
    } else {
      this.subruta = this.activo.charAt(0).toUpperCase() + this.activo.slice(1);
    }
  }
  preventDefault($event) {
    $event.preventDefault();
  }
}
