import { TipoDocumentoService } from './../../../../parametricos-proyecto/tipos-documento/services/tipo-documento.service';
import { ConfigDocumentoTipoResponse } from './../interfaces/config-documento-tipo-response';
import { ConfigDocumentoTipoService } from './../services/config-documento-tipo.service';
import { Component, OnInit, Inject } from '@angular/core';
import { ConfigDocumentoTipo } from '../interfaces/config-documento-tipo';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Combo } from 'src/app/util/interfaces/combo';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CombosService } from 'src/app/util/services/combos/combos.service';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from 'src/app/util/services/error.service';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { CombosResponse } from 'src/app/util/interfaces/combos-response';
export interface DialogData {
  proyectoId: number;
  documentoTipoId: number;
}
@Component({
  selector: 'app-detalle-config-documento-tipo',
  templateUrl: './detalle-config-documento-tipo.component.html',
  styleUrls: ['./detalle-config-documento-tipo.component.sass']
})
export class DetalleConfigDocumentoTipoComponent implements OnInit {
  public proyectoDocumentoTipoForm: FormGroup;
  editable = true;
  documentoTipoId: number;
  proyectoId: number;
  cargando: boolean;
  process: boolean;
  private token;
  configDocumentoTipo: ConfigDocumentoTipo;
  documentoTipos: Combo[];

  constructor(public dialogRef: MatDialogRef<DetalleConfigDocumentoTipoComponent>, private fB: FormBuilder, private comboService: CombosService,
    private configDocumentoTipoService: ConfigDocumentoTipoService, private fS: FormStatusService, private toastr: ToastrService,
    private errorService: ErrorService, private localStorage: LocalStorageService, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
    this.proyectoId = this.data.proyectoId;
    this.documentoTipoId = this.data.documentoTipoId;
    this.token = this.localStorage.getTokenFromStorage();
    this.makeForm();
    this.getDocumentosTipo();
    this.get();
  }

  makeForm() {
    this.proyectoDocumentoTipoForm = this.fB.group({
      pro_documento_tipo_id: [{ value: null, disabled: this.editable }, Validators.required],
      activo: [{ value: null, disabled: this.editable }, Validators.required]
    });
  }

  get() {
    this.cargando = true;
    this.configDocumentoTipoService.getProyectoDocumentoTipo(this.proyectoId, this.documentoTipoId, this.token)
      .subscribe((respuesta: ConfigDocumentoTipoResponse) => {
        this.configDocumentoTipo = respuesta.data;
        this.proyectoDocumentoTipoForm.patchValue(this.configDocumentoTipo);
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
        this.cargando = false;
      });
  }
  actualizar() {
    this.process = true;
    const jsonData = this.fS.getJsonFromForm(this.proyectoDocumentoTipoForm);
    this.configDocumentoTipoService.actualizarProyectoDocumentoTipo(this.proyectoId, this.documentoTipoId, this.token, jsonData)
      .subscribe((respuesta: ConfigDocumentoTipoResponse) => {
        if (respuesta.success) {
          this.toastr.success('Tipo de Documento actualizado correctamente', 'Actualizar Tipo de Documento');
          this.get();
          this.editableSwitcher();
          this.dialogRef.close('updated');
        }
      }, error => {
        this.errorService.handleError(error);
        this.proyectoDocumentoTipoForm.enable();
      }).add(() => { this.process = false; });
  }

  volver() {
    this.dialogRef.close();
  }

  public editableSwitcher() {
    this.proyectoDocumentoTipoForm = this.fS.cambiarEstado(this.proyectoDocumentoTipoForm, this.editable);
    this.editable = !this.editable;
  }

  getDocumentosTipo() {
    this.comboService.getDocumentoTiposCombo()
      .subscribe((respuesta: CombosResponse) => {
        this.documentoTipos = respuesta.data;
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
      });
  }
}
