import { Component, OnInit } from '@angular/core';
import {LocalStorageService} from '../../../../util/local-storage.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-negocio-seguimiento-gestion-proyecto',
  templateUrl: './negocio-seguimiento-gestion-proyecto.component.html',
  styleUrls: ['./negocio-seguimiento-gestion-proyecto.component.sass']
})
export class NegocioSeguimientoGestionProyectoComponent implements OnInit {

  dashboardId: string;
  filter: string;
  idProyecto: number;
  constructor(private localStorage: LocalStorageService,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.idProyecto = +this.route.snapshot.paramMap.get('idProyecto');
    if (this.idProyecto) {
      this.filter = `&filter=vw_preguntas_respuestas/proyecto_id%20eq%20${this.idProyecto}`;
    }
    this.dashboardId = this.localStorage.getParametrosFromStorage().DASHBOARD6_ID;
  }

}
