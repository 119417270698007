import { Component, Input, OnInit } from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import Swal from 'sweetalert2'
import { GrupoAhorroService } from '../../../services/grupo-ahorro.service';
import { ErrorService } from 'src/app/util/services/error.service';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { CombosService } from 'src/app/util/services/combos/combos.service';
import { GrupoAhorro } from '../../../interfaces/grupo-ahorro.interface';


@Component({
  selector: 'app-reuniones',
  templateUrl: './reuniones.component.html',
  styleUrls: ['./reuniones.component.sass']
})
export class ReunionesComponent implements OnInit {
  @Input() ciclo: any;
  idProyecto: number;
  idGrupo: number;
  idCiclo: number;
  reuniones = [];
  pagination = { page: 1, per_page: 10 };
  private token;
  total: number;
  cargando: boolean;
  grupoAhorro: GrupoAhorro;
  editable = true;
  selected = null;
  searchForm: FormGroup;
  displayedColumns = ['fecha', 'nombre', 'hora'];

  constructor(
    private gruposAhorroService: GrupoAhorroService,
    private toastr: ToastrService,
    private errorService: ErrorService,
    private localStorage: LocalStorageService,
    private fB: FormBuilder,
    private fs: FormStatusService,
    private comboService: CombosService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.token = this.localStorage.getTokenFromStorage();
    this.searchForm = this.fB.group({
      nombre: '',
      fecha: ''
    });
  }


  ngOnInit() {
    this.idProyecto = +this.route.snapshot.paramMap.get('idProyecto');
    this.idGrupo = +this.route.snapshot.paramMap.get('idGrupo');
    this.idCiclo = +this.route.snapshot.paramMap.get('idCiclo');
    this.getReuniones();
  }

  private getReuniones() {
    this.cargando = true;
    this.gruposAhorroService.getReuniones(this.idProyecto, this.idGrupo, this.idCiclo,  this.searchForm.value, this.pagination)
      .subscribe((respuesta: any) => {
        this.reuniones = respuesta.data;
        console.log(this.reuniones);
        this.total = respuesta.metadata.total;
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
        this.cargando = false;
      });
  }

  onClick(event: any) {
    event ? this.filtrar() : this.onReset();
  }

  filtrar() {
    this.pagination.page = 1;
    this.getReuniones();
  }

  onReset() {
    this.pagination = { page: 1, per_page: 10 };
    this.searchForm.reset();
    this.getReuniones();
  }

  pageChange() {
    this.getReuniones();
  }

  changeBackGroundColor(event, row) {
    this.selected = event.type == 'mouseover' ? row.id : null;
  }

  verDetalle(id: number) {
    this.router.navigate(['./reuniones', id], { relativeTo: this.route });
  }

  crearReunion() {
    this.router.navigate(['./reuniones', 'new'], { relativeTo: this.route });
  }


}

