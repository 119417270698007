import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-boton-filtro',
  templateUrl: './boton-filtro.component.html',
  styleUrls: ['./boton-filtro.component.sass']
})
export class BotonFiltroComponent implements OnInit {
  file: any;
  @Output() clickEvent = new EventEmitter();
  @Output() clickPDF = new EventEmitter();
  @Output() clickExcel = new EventEmitter();
  @Output() clickCSV = new EventEmitter();
  @Output() clickUploading = new EventEmitter();
  @Input() downloading: boolean;
  @Input() uploading: boolean;
  @Input() mostrarSubir: boolean;
  @Input() mostrarDescargar: boolean;
  @Input() ocultarFiltro: boolean;
  @Input() textoDescargar: string;
  @Input() textoSubir: string;
  @Input() ocultarPDF: boolean;
  @Input() ocultarXLS: boolean;
  @Input() ocultarCSV: boolean;
  constructor() { }

  ngOnInit() {
  }

  filtrar() {
    this.clickEvent.emit(true);
  }

  reset() {
    this.clickEvent.emit(false);
  }

  fileChangeEvent(files): void {
    this.file = files[0];
  }

  pdf() {
    this.clickPDF.emit(true);
  }

  csv() {
    this.clickCSV.emit(true);
  }

  excel() {
    this.clickExcel.emit(true);
  }

  uploadFile() {
    this.clickUploading.emit(true);
  }
}
