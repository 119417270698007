import { Injectable } from '@angular/core';
import {Globals} from '../../../../Globals';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DocumentoService {

  constructor(private global: Globals, private http: HttpClient) { }
  getDocumentos(token, idProyecto, search = null, pagination = null) {
    const headers = this.global.getHeader(token);
    const url = this.global.makeUrl('/proyectos/' + idProyecto + '/documentos', search, pagination);
    return this.http.get(url, {headers});
  }

  getDocumento(token, idProyecto, idDocumento, search = null, pagination = null) {
    const headers = this.global.getHeader(token);
    const url = this.global.makeUrl('/proyectos/' + idProyecto + '/documentos/' + idDocumento, search, pagination);
    return this.http.get(url, {headers});
  }

  crearDocumento(token, idProyecto, jsonData, search = null, pagination = null) {
    const headers = new HttpHeaders({Authorization: 'Bearer ' + token});
    const url = this.global.makeUrl('/proyectos/' + idProyecto + '/documentos', search, pagination);
    return this.http.post(url, jsonData, {headers});
  }

  editarDocumento(token, idProyecto, idDocumento, jsonData, search = null, pagination = null) {
    const headers = new HttpHeaders({Authorization: 'Bearer ' + token});
    const url = this.global.makeUrl('/proyectos/' + idProyecto + '/documentos/' + idDocumento, search, pagination);
    return this.http.put(url, jsonData, {headers});
  }

  borrarDocumento(token, idProyecto, idDocumento, search = null, pagination = null) {
    const headers = this.global.getHeader(token);
    const url = this.global.makeUrl('/proyectos/' + idProyecto + '/documentos/' + idDocumento, search, pagination);
    return this.http.delete(url, {headers});
  }
}
