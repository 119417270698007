import { ActorUbicacionService } from './../services/actor-ubicacion.service';
import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Ubicacion } from 'src/app/Pages/Administracion/parametricos/paises/interfaces/ubicacion.interface';
import { Combo } from 'src/app/util/interfaces/combo';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { ToastrService } from 'ngx-toastr';
import { CombosService } from 'src/app/util/services/combos/combos.service';
import { ErrorService } from 'src/app/util/services/error.service';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { ConfigProyectoUbicacionResponse } from 'src/app/Pages/proyectos/editar-proyecto/config-proyecto-ubicacion/interfaces/config-proyecto-ubicacion-response';
import { HttpErrorResponse } from '@angular/common/http';
import { CombosResponse } from 'src/app/util/interfaces/combos-response';

export interface DialogData {
  actorId: number;
  paisId: number;
}
@Component({
  selector: 'app-crear-actor-ubicacion',
  templateUrl: './crear-actor-ubicacion.component.html',
  styleUrls: ['./crear-actor-ubicacion.component.sass']
})
export class CrearActorUbicacionComponent implements OnInit {
  public nuevaUbicacion: FormGroup;
  private actorId: number;
  private paisId: number;
  private token;
  ubicaciones: Ubicacion[];
  process: boolean;
  ubicacionForm: FormGroup;
  creando: string;
  ubicacionesPosibles = ['Departamento', 'Ciudad', 'Comunidad'];
  departamentos: Combo[];
  ciudades: Combo[];
  comunidades: Combo[];

  constructor(public dialogRef: MatDialogRef<CrearActorUbicacionComponent>, private fB: FormBuilder,
    private configUbicacionService: ActorUbicacionService, private fS: FormStatusService, private toastr: ToastrService, private comboService: CombosService,
    private errorService: ErrorService, private localStorage: LocalStorageService, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
    this.token = this.localStorage.getTokenFromStorage();
    this.actorId = this.data.actorId;
    this.paisId = this.data.paisId;
    this.makeForm();
  }

  makeForm() {
    this.nuevaUbicacion = this.fB.group({
      adm_pais_id: [this.paisId],
      adm_departamento_id: [null],
      adm_ciudad_id: [null],
      adm_comunidad_id: [null],
    });
    this.nuevaUbicacion.disable();
    this.nuevaUbicacion.controls.adm_pais_id.enable();
  }

  crear() {
    this.process = true;
    const jsonData = this.fS.getJsonFromForm(this.nuevaUbicacion);
    this.configUbicacionService.crearActorUbicacion(this.token, this.actorId, jsonData)
      .subscribe((respuesta: ConfigProyectoUbicacionResponse) => {
        if (respuesta.success) {
          this.toastr.success('Ubicación creada exitosamente', 'Crear Ubicación');
          this.dialogRef.close('created');
        }
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
        this.nuevaUbicacion.enable();
      }).add(() => { this.process = false; });
  }

  getCombos(creando) {
    this.creando = creando;
    if (!this.departamentos) {
      this.getDepartamentos();
    }
    this.nuevaUbicacion.controls.adm_departamento_id.enable();
  }

  getDepartamentos() {
    this.comboService.getDepartamentosPorPaisCombo(this.token, this.paisId)
      .subscribe((respuesta: CombosResponse) => {
        this.departamentos = respuesta.data;
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
      });
  }

  getCiudades(departamentoId) {
    if (this.creando !== 'Departamento' || this.ciudades.length === 0) {
      this.comboService.getCiudadesPorDepartamentoCombo(this.token, departamentoId)
        .subscribe((respuesta: CombosResponse) => {
          this.ciudades = respuesta.data;
        }, error => {
          this.errorService.handleError(error);
        }
        ).add(() => {
        });
    }
    this.nuevaUbicacion.controls.adm_ciudad_id.enable();
  }

  getComunidades(ciudadId) {
    if (this.creando === 'Comunidad' || this.ciudades.length === 0) {
      this.comboService.getComunidadesPorCiudad(this.token, ciudadId)
        .subscribe((respuesta: CombosResponse) => {
          this.comunidades = respuesta.data;
        }, error => {
          this.errorService.handleError(error);
        }
        ).add(() => {
        });
    }
    this.nuevaUbicacion.controls.adm_comunidad_id.enable();
  }
  volver() {
    this.dialogRef.close();
  }

}
