import { ComponenteMonitoreoResponse } from './../interfaces/componente-monitoreo-response';
import { ComponenteMonitoreoService } from './../services/componente-monitoreo.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { ErrorService } from 'src/app/util/services/error.service';
import { ToastrService } from 'ngx-toastr';
import { ComponenteMonitoreo } from '../interfaces/componente-monitoreo';

@Component({
  selector: 'app-detalle-componente-monitoreo',
  templateUrl: './detalle-componente-monitoreo.component.html',
  styleUrls: ['./detalle-componente-monitoreo.component.sass']
})
export class DetalleComponenteMonitoreoComponent implements OnInit {

  public componenteForm: FormGroup;
  editable = true;
  cargando: boolean;
  componenteMonitoreoId: number;
  componenteMonitoreo: ComponenteMonitoreo;
  private token;
  process: boolean;
  constructor(private fB: FormBuilder, private router: Router, private route: ActivatedRoute, private fS: FormStatusService,
    private localStorage: LocalStorageService, private errorService: ErrorService, private toastr: ToastrService, private componenteMonitoreoService: ComponenteMonitoreoService) { }

  ngOnInit() {
    this.componenteMonitoreoId = +this.route.snapshot.paramMap.get('id');
    this.token = this.localStorage.getTokenFromStorage();
    this.makeForm();
    this.get();
  }

  makeForm() {
    this.componenteForm = this.fB.group({
      nombre: [{ value: undefined, disabled: this.editable }, Validators.required],
      codigo: [{ value: undefined, disabled: this.editable }, Validators.required],
      descripcion: [{ value: undefined, disabled: this.editable }]
    });
  }

  get() {
    this.cargando = true;
    this.componenteMonitoreoService.getComponenteMonitoreo(this.componenteMonitoreoId, this.token)
      .subscribe((respuesta: ComponenteMonitoreoResponse) => {
        this.componenteMonitoreo = respuesta.data;
        this.componenteForm.patchValue(this.componenteMonitoreo);
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
        this.cargando = false;
      });
  }
  actualizar() {
    this.process = true;
    const jsonData = this.fS.getJsonFromForm(this.componenteForm);
    this.componenteMonitoreoService.actualizarComponenteMonitoreo(this.componenteMonitoreoId, this.token, jsonData)
      .subscribe((respuesta: ComponenteMonitoreoResponse) => {
        if (respuesta.success) {
          this.toastr.success('Componente de monitoreo actualizado correctamente', 'Actualizar Componente de Monitoreo');
          this.get();
          this.editableSwitcher();
        }
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(()=>{this.process = false;});
  }

  volver() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  public editableSwitcher() {
    this.componenteForm = this.fS.cambiarEstado(this.componenteForm, this.editable, ['nombre', 'codigo'], true);
    this.editable = !this.editable;
  }

}
