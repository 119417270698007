import { AdministracionComponent } from './Pages/Administracion/administracion.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { BaseLayoutComponent } from './Layout/base-layout/base-layout.component';
import { LoginComponent } from './Pages/Login/login/login.component';
import { PerfilComponent } from './Pages/Usuario/perfil/perfil.component';
import { AuthGuardService } from './auth/auth-guard.service';
import { HomeComponent } from './Pages/home/home.component';
import { RecuperarPasswordComponent } from './Pages/Login/login/recuperar-password/recuperar-password.component';
import { RecuperarExitoComponent } from './Pages/Login/login/recuperar-password/recuperar-exito/recuperar-exito.component';
import {ReporteComponent} from './Pages/Administracion/reporte/reporte.component';

const routes: Routes = [
  {
    path: 'login', component: LoginComponent
  },
  {
    path: 'recuperar', component: RecuperarPasswordComponent,
  },
  {
    path: 'recuperar/exito', component: RecuperarExitoComponent,
  },
  {
    path: '',
    component: BaseLayoutComponent,
    canActivate: [AuthGuardService],
    children: [
      // Administracion
      {
        path: '',
        component: HomeComponent,
        canActivate: [AuthGuardService],
        data: { extraParameter: 'administracionMenu' }
      },
      {
        path: 'administracion',
        canActivate: [AuthGuardService],
        children: [
          {
            path: '',
            component: AdministracionComponent
          },
          {
            path: 'personas',
            loadChildren: './Pages/Administracion/personas/personas.module#PersonasModule',
          },
          {
            path: 'usuarios',
            loadChildren: './Pages/Administracion/usuarios/usuarios.module#UsuariosModule',
            data: { extraParameter: 'administracionMenu' }
          },
          {
            path: 'modulos',
            loadChildren: './Pages/Administracion/modulos/modulos.module#ModulosModule',
            data: { extraParameter: 'administracionMenu' }
          },
          {
            path: 'roles',
            loadChildren: './Pages/Administracion/roles/roles.module#RolesModule',
            data: { extraParameter: 'administracionMenu' }
          },
          {
            path: 'auditoria',
            loadChildren: './Pages/Administracion/auditoria/auditoria.module#AuditoriaModule',
            data: { extraParameter: 'administracionMenu' }
          },
          {
            path: 'parametros-sistema',
            loadChildren: './Pages/Administracion/parametros-sistema/parametros-sistema.module#ParametrosSistemaModule',
            data: { extraParameter: 'administracionMenu' }
          },
          {
            path: 'parametricos',
            loadChildren: './Pages/Administracion/parametricos/parametricos.module#ParametricosModule',
            data: { extraParameter: 'administracionMenu' }
          },
          {
            path: 'reporte',
            component: ReporteComponent,
            data: { extraParameter: 'administracionMenu' }
          },
        ],
        data: { extraParameter: 'administracionMenu' }
      },
      // Administracion
      { path: 'usuario/perfil-usuario', component: PerfilComponent, data: { extraParameter: 'administracionMenu' } },

      // Proyectos
      {
        path: 'proyectos',
        canActivate: [AuthGuardService],
        loadChildren: './Pages/proyectos/proyectos.module#ProyectosModule',
        data: { extraParameter: 'proyectoMenu' }
      },
      // Paramétricos de proyecto
      {
        path: 'parametricos-proyecto',
        canActivate: [AuthGuardService],
        loadChildren: './Pages/parametricos-proyecto/parametricos-proyecto.module#ParametricosProyectoModule',
        data: { extraParameter: 'administracionMenu' }
      },
      // Notificaciones
      {
        path: 'notificaciones',
        canActivate: [AuthGuardService],
        loadChildren: './Pages/Notificaciones/notificaciones.module#NotificacionesModule',
        data: { extraParameter: 'notificacionesMenu' }
      }
    ]

  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      useHash: true
    })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
