import { ConfigProyectoUbicacionResponse } from './../interfaces/config-proyecto-ubicacion-response';
import { ConfigProyectoUbicacionService } from './../services/config-proyecto-ubicacion.service';
import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Ubicacion } from 'src/app/Pages/Administracion/parametricos/paises/interfaces/ubicacion.interface';
import { Combo } from 'src/app/util/interfaces/combo';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CombosService } from 'src/app/util/services/combos/combos.service';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from 'src/app/util/services/error.service';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { CombosResponse } from 'src/app/util/interfaces/combos-response';
import { ConfigProyectoUbicacion } from '../interfaces/config-proyecto-ubicacion';

export interface DialogData {
  proyectoId: number;
  paisId: number;
  ubicacionId: number;
}
@Component({
  selector: 'app-detalle-config-proyecto-ubicacion',
  templateUrl: './detalle-config-proyecto-ubicacion.component.html',
  styleUrls: ['./detalle-config-proyecto-ubicacion.component.sass']
})
export class DetalleConfigProyectoUbicacionComponent implements OnInit {

  private proyectoId: number;
  private paisId: number;
  private ubicacionId: number;
  private token;
  departamentoId: number;
  comunidadId: number;
  ciudadId: number;
  editable = true;
  ubicaciones: Ubicacion[];
  process: boolean;
  cargando: boolean;
  ubicacionForm: FormGroup;
  ubicacion: ConfigProyectoUbicacion;
  editOnly: string;
  ubicacionesPosibles = ['Departamento', 'Ciudad', 'Comunidad'];
  departamentos: Combo[];
  ciudades: Combo[];
  comunidades: Combo[];

  constructor(public dialogRef: MatDialogRef<DetalleConfigProyectoUbicacionComponent>, private fB: FormBuilder, private comboService: CombosService,
    private confgUbicacionService: ConfigProyectoUbicacionService, private fS: FormStatusService, private toastr: ToastrService,
    private errorService: ErrorService, private localStorage: LocalStorageService, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
    this.proyectoId = this.data.proyectoId;
    this.ubicacionId = this.data.ubicacionId;
    this.token = this.localStorage.getTokenFromStorage();
    this.makeForm();
    this.get();
  }

  makeForm() {
    this.ubicacionForm = this.fB.group({
      adm_departamento_id: [{ value: undefined, disabled: this.editable }],
      adm_ciudad_id: [{ value: undefined, disabled: this.editable }],
      adm_comunidad_id: [{ value: undefined, disabled: this.editable }],
    });
    this.ubicacionForm.disable();
  }

  get() {
    this.cargando = true;
    this.confgUbicacionService.getProyectoUbicacion(this.proyectoId, this.ubicacionId, this.token)
      .subscribe((respuesta: any) => {
        this.ubicacion = respuesta.data;
        this.evalUbicacion();
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
      });
  }

  evalUbicacion() {
    if (this.ubicacion.departamentos && this.ubicacion.departamentos.length > 0) {
      this.ubicacionForm.controls.adm_departamento_id.setValue(this.ubicacion.departamentos[0].id);
      this.departamentoId = this.ubicacion.departamentos[0].id;
      this.paisId = this.ubicacion.departamentos[0].adm_pais_id;
      this.editOnly = 'Departamento';
    }
    if (this.ubicacion.ciudades && this.ubicacion.ciudades.length > 0) {
      this.ubicacionForm.controls.adm_ciudad_id.setValue(this.ubicacion.ciudades[0].id);
      this.ubicacionForm.controls.adm_departamento_id.setValue(this.ubicacion.ciudades[0].adm_departamento_id);
      this.ciudadId = this.ubicacion.ciudades[0].id;
      this.departamentoId = this.ubicacion.ciudades[0].adm_departamento_id;
      this.paisId = this.ubicacion.ciudades[0].adm_pais_id;
      this.editOnly = 'Ciudad';

    }
    if (this.ubicacion.comunidades && this.ubicacion.comunidades.length > 0) {
      this.ubicacionForm.controls.adm_comunidad_id.setValue(this.ubicacion.comunidades[0].id);
      this.ubicacionForm.controls.adm_ciudad_id.setValue(this.ubicacion.comunidades[0].adm_ciudad_id);
      this.ubicacionForm.controls.adm_departamento_id.setValue(this.ubicacion.comunidades[0].adm_departamento_id);
      this.comunidadId = this.ubicacion.comunidades[0].id;
      this.departamentoId = this.ubicacion.comunidades[0].adm_departamento_id;
      this.ciudadId = this.ubicacion.comunidades[0].adm_ciudad_id;
      this.paisId = this.ubicacion.comunidades[0].adm_pais_id;
      this.editOnly = 'Comunidad';
    }
    this.getCombos();
  }
  getCombos() {
    this.getDepartamentos();
    if (this.ciudadId) {
      this.getCiudades(this.departamentoId);
    }
    if (this.comunidadId) {
      this.getComunidades(this.ciudadId);
    }
    this.cargando = false;
  }

  getDepartamentos() {
    this.comboService.getDepartamentosPorPaisCombo(this.token, this.paisId)
      .subscribe((respuesta: CombosResponse) => {
        this.departamentos = respuesta.data;
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
      });
  }

  getCiudades(departamentoId) {
    if (this.editOnly !== 'Departamento' || (this.ciudades && this.ciudades.length === 0)) {
      this.comboService.getCiudadesPorDepartamentoCombo(this.token, departamentoId)
        .subscribe((respuesta: CombosResponse) => {
          this.ciudades = respuesta.data;
        }, error => {
          this.errorService.handleError(error);
        }
        ).add(() => {
        });
    }
  }

  getComunidades(ciudadId) {
    if (this.editOnly === 'Comunidad' || (this.comunidades && this.comunidades.length === 0)) {
      this.comboService.getComunidadesPorCiudad(this.token, ciudadId)
        .subscribe((respuesta: CombosResponse) => {
          this.comunidades = respuesta.data;
        }, error => {
          this.errorService.handleError(error);
        }
        ).add(() => {
        });
    }
  }
  volver() {
    this.dialogRef.close();
  }

  actualizar() {
    this.process = true;
    if (this.editOnly === 'Departamento') {
      this.ubicacionForm.controls.adm_ciudad_id.disable();
      this.ubicacionForm.controls.adm_comunidad_id.disable();

    } else if (this.editOnly === 'Ciudad') {
      this.ubicacionForm.controls.adm_departamento_id.disable();
      this.ubicacionForm.controls.adm_comunidad_id.disable();
    } else {
      this.ubicacionForm.controls.adm_departamento_id.disable();
      this.ubicacionForm.controls.adm_ciudad_id.disable();
    }
    const jsonData = this.fS.getJsonFromForm(this.ubicacionForm);
    this.confgUbicacionService.actualizarProyectoUbicacion(this.proyectoId, this.ubicacionId, this.token, jsonData)
      .subscribe((respuesta: ConfigProyectoUbicacionResponse) => {
        if (respuesta.success) {
          this.toastr.success('Ubicación actualizada correctamente', 'Actualizar Ubicación');
          this.get();
          this.editableSwitcher();
          this.dialogRef.close('updated');
        }
      }, error => {
        this.errorService.handleError(error);
        this.ubicacionForm.enable();
      }).add(() => { this.process = false; });
  }

  public editableSwitcher() {
    let excluir;
    if (this.editOnly === 'Departamento') {
      excluir = ['adm_ciudad_id', 'adm_comunidad_id'];
    } else if (this.editOnly === 'Ciudad') {
      excluir = ['adm_departamento_id', 'adm_comunidad_id'];
    } else {
      excluir = ['adm_departamento_id', 'adm_ciudad_id'];
    }
    this.ubicacionForm = this.fS.cambiarEstado(this.ubicacionForm, this.editable, excluir, true);
    this.editable = !this.editable;
  }
}
