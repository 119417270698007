import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-datos-no-encontrados',
  templateUrl: './datos-no-encontrados.component.html',
  styleUrls: ['./datos-no-encontrados.component.sass']
})
export class DatosNoEncontradosComponent implements OnInit {

  public lottieConfig = {
    path: 'assets/lottie/empty-box.json',
    renderer: 'canvas',
    autoplay: true,
    loop: true
  };

  constructor() { }

  ngOnInit() {
  }

}
