import { ConfigProyectoInversoresService } from './../services/config-proyecto-inversores.service';
import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Combo } from 'src/app/util/interfaces/combo';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CombosService } from 'src/app/util/services/combos/combos.service';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from 'src/app/util/services/error.service';
import { InversoresService } from 'src/app/Pages/parametricos-proyecto/inversores/services/inversores.service';
import { CombosResponse } from 'src/app/util/interfaces/combos-response';

export interface DialogData {
  proyectoId: number;
  inversorId: number;
}
@Component({
  selector: 'app-detalle-config-proyecto-inversores',
  templateUrl: './detalle-config-proyecto-inversores.component.html',
  styleUrls: ['./detalle-config-proyecto-inversores.component.sass']
})
export class DetalleConfigProyectoInversoresComponent implements OnInit {

  public inversorForm: FormGroup;
  editable = true;
  inversorId: number;
  proyectoId: number;
  cargando: boolean;
  process: boolean;
  inversor: any;
  inversoresCombo: Combo[];

  constructor(public dialogRef: MatDialogRef<DetalleConfigProyectoInversoresComponent>, private fB: FormBuilder, private comboService: CombosService,
              private configProyectoInversoresService: ConfigProyectoInversoresService, private fS: FormStatusService, private toastr: ToastrService,
              private errorService: ErrorService, @Inject(MAT_DIALOG_DATA) public data: DialogData, private inversoresService: InversoresService) { }

  ngOnInit() {
    this.proyectoId = this.data.proyectoId;
    this.inversorId = this.data.inversorId;
    this.makeForm();
    this.get();
    this.getinversoresCombo();
  }

  makeForm() {
    this.inversorForm = this.fB.group({
      pro_inversor_id: [{ value: null, disabled: this.editable }, Validators.required],
    });
  }

  get() {
    this.cargando = true;
    this.configProyectoInversoresService.getInversor(this.proyectoId, this.inversorId)
      .subscribe((respuesta: any) => {
        this.inversor = respuesta.data;
        this.inversorForm.controls.pro_inversor_id.setValue(respuesta.data.pro_inversor_id);
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
        this.cargando = false;
      });
  }
  actualizar() {
    this.process = true;
    const jsonData = this.fS.getJsonFromForm(this.inversorForm);
    this.configProyectoInversoresService.actualizarInversor(this.proyectoId, this.inversorId, jsonData)
      .subscribe((respuesta: any) => {
        if (respuesta.success) {
          this.toastr.success('Aliado/Actor Institucional actualizado correctamente', 'Actualizar Aliado/Actor Institucional');
          this.get();
          this.editableSwitcher();
          this.dialogRef.close('updated');
        }
      }, error => {
        this.errorService.handleError(error);
        this.inversorForm.enable();
      }).add(() => { this.process = false; });
  }

  volver() {
    this.dialogRef.close();
  }

  public editableSwitcher() {
    this.inversorForm = this.fS.cambiarEstado(this.inversorForm, this.editable);
    this.editable = !this.editable;
  }

  // getinversoresCombo() {
  //   this.comboService.getInversoresCombo()
  //     .subscribe((respuesta: CombosResponse) => {
  //       this.inversoresCombo = respuesta.data;
  //     }, error => {
  //       this.errorService.handleError(error);
  //     }
  //     ).add(() => {
  //     });
  // }

  getinversoresCombo() {
    this.inversoresService.obtenerInversores()
      .subscribe((respuesta: CombosResponse) => {
        this.inversoresCombo = respuesta.data;
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
      });
  }
}
