import { ProyectoRolesResponse, ProyectoRolResponse } from './interfaces/proyecto-rol-response';
import { ProyectoRol } from './interfaces/proyecto-rol';
import { DetalleProyectoRolComponent } from './detalle-proyecto-rol/detalle-proyecto-rol.component';
import { CrearProyectoRolComponent } from './crear-proyecto-rol/crear-proyecto-rol.component';
import { ProyectoRolService } from './services/proyecto-rol.service';
import { Component, OnInit, Input } from '@angular/core';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from 'src/app/util/services/error.service';
import { MatDialog } from '@angular/material';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-config-proyecto-rol',
  templateUrl: './config-proyecto-rol.component.html',
  styleUrls: ['./config-proyecto-rol.component.sass']
})
export class ConfigProyectoRolComponent implements OnInit {

  @Input() proyectoId: number;
  cargando: boolean;
  private token;
  proyectoRoles: ProyectoRol[];
  displayedColumns = ['nombre', 'codigo', 'activo', 'acciones'];
  selected = null;
  total: number;

  constructor(private localStorage: LocalStorageService,
    private proyectoRolService: ProyectoRolService,
    private toastr: ToastrService,
    private errorService: ErrorService,
    public dialog: MatDialog ) { }

  ngOnInit() {
    this.token = this.localStorage.getTokenFromStorage();
    this.getProyectoRoles();
  }

  getProyectoRoles() {
    this.cargando = true;
    this.proyectoRolService.getProyectoRoles(this.token, this.proyectoId)
      .subscribe((respuesta: ProyectoRolesResponse) => {
        this.proyectoRoles = respuesta.data;
        this.total = respuesta.metadata.total;
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
        this.cargando = false;
      });
  }

  crear() {
    const dialogRef = this.dialog.open(CrearProyectoRolComponent, {
      width: '700px',
      data: { proyectoId: this.proyectoId }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'created') {
        this.getProyectoRoles();
      }
    });
  }

  verDetalle(id: number) {
    const dialogRef = this.dialog.open(DetalleProyectoRolComponent, {
      width: '700px',
      data: { proyectoId: this.proyectoId, proyectoRolId: id}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'updated') {
        this.getProyectoRoles();
      }
    });
  }

  changeBackGroundColor(event, row) {
    this.selected = event.type == 'mouseover' ? row.id : null;
  }

  borrar(id: number) {
    this.proyectoRolService.borrarProyectoRol(this.proyectoId, id, this.token)
      .subscribe((respuesta: ProyectoRolResponse) => {
        if (respuesta.success) {
          this.toastr.success('Rol borrado exitosamente', 'Borrar rol');
        }
        this.getProyectoRoles();
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
      }, () => { });
  }

}
