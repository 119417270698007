import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-botonera-creacion',
  templateUrl: './botonera-creacion.component.html',
  styleUrls: ['./botonera-creacion.component.sass']
})
export class BotoneraCreacionComponent implements OnInit {
  @Output() clickCancelEvent = new EventEmitter();
  @Output() clickCreatetEvent = new EventEmitter();

  @Input() valid: boolean;
  @Input() process: boolean;


  constructor() { }

  ngOnInit() {
  }

  crear() {
    this.clickCreatetEvent.emit(true);
  }

  cancelar() {
    this.clickCancelEvent.emit(true);
  }
}
