import { Component, OnInit } from '@angular/core';
import {User} from '../../auth/user';
import {LocalStorageService} from '../../util/local-storage.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass']
})
export class HomeComponent implements OnInit {
  public usuario: User;
  public fechaActual = new Date();

  constructor(private localStorageService: LocalStorageService) { }

  ngOnInit() {
    this.usuario = this.localStorageService.getUserFromStorage();
  }

}
