import { LottieAnimationViewModule } from 'ng-lottie';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkingOnItComponent } from './working-on-it.component';

@NgModule({
  declarations: [WorkingOnItComponent],
  imports: [
    CommonModule,
    LottieAnimationViewModule
  ],
  exports: [WorkingOnItComponent]
})
export class WorkingOnItModule { }
