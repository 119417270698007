import { FormGroup, FormBuilder } from '@angular/forms';
import { NgxPermissionsService } from 'ngx-permissions';
import { TallerGestionProyectoResponse, TalleresGestionProyectoResponse } from './interfaces/taller-gestion-proyecto-response';
import { TallerGestionProyectoService } from './services/taller-gestion-proyecto.service';
import { Proyecto } from './../../interfaces/proyecto';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { ErrorService } from 'src/app/util/services/error.service';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-talleres-gestion-proyecto',
  templateUrl: './talleres-gestion-proyecto.component.html',
  styleUrls: ['./talleres-gestion-proyecto.component.sass']
})
export class TalleresGestionProyectoComponent implements OnInit {

  grupos: any[];
  selected = null;
  proyecto: Proyecto;
  private token;
  private proyectoId: number;
  public pagination = { page: 1, per_page: 10 };
  public total: number;
  searchForm: FormGroup;
  cargando: boolean;
  title: string;
  displayedColumns = ['nombre', 'codigo', 'facilitador', 'acciones'];
  constructor(private router: Router,
    private route: ActivatedRoute,
    private localStorage: LocalStorageService,
    private tallerGestionProyectoService: TallerGestionProyectoService,
    private errorService: ErrorService,
    private toastr: ToastrService,
    private permissionService: NgxPermissionsService,
    private fB: FormBuilder) { }

  ngOnInit() {
    this.token = this.localStorage.getTokenFromStorage();
    this.proyectoId = +this.route.snapshot.paramMap.get('idProyecto');
    this.makeForm();
    if (this.route.snapshot.data.extraParameter === 'facilitadores') {
      this.searchForm.get('pro_grupo_taller_tipo_id').setValue(1);
      this.title = 'Talleres de Facilitadores';
    } else {
      this.searchForm.get('pro_grupo_taller_tipo_id').setValue(2);
      this.title = 'Talleres de Participantes';
    }
  }

  makeForm() {
    this.searchForm = this.fB.group({
      nombre: '',
      codigo: '',
      pro_grupo_taller_tipo_id: ''
    });
  }

  getTalleres() {
    this.cargando = true;
    this.tallerGestionProyectoService.getProyectoTalleres(this.token, this.proyectoId, this.searchForm.value, this.pagination)
      .subscribe((response: any) => {
        this.grupos = response.data;
        this.total = response.metadata.total;
      }, error => {
        this.errorService.handleError(error);
      }, () => {
        this.cargando = false;
      });
  }

  verDetalle(id) {
    this.permissionService.hasPermission('PRO/mant_grupos_talleres/get1508').then((result) => {
      if (result) {
        this.router.navigate(['./', id], { relativeTo: this.route });
      }
    });
  }
  crearTaller() {
    this.router.navigate(['./new'], { relativeTo: this.route });
  }

  borrar(id: number) {
    this.tallerGestionProyectoService.borrarProyectoTaller(this.proyectoId, id, this.token)
      .subscribe((respuesta: TallerGestionProyectoResponse) => {
        if (respuesta.success) {
          this.toastr.success('Taller borrado exitosamente', 'Borrar Taller');
        }
        this.getTalleres();
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
      }, () => { });
  }

  pageChange() {
    this.getTalleres();
  }

  changeBackGroundColor(event, row) {
    this.selected = event.type == 'mouseover' ? row.id : null;
  }

  onReset() {
    this.pagination.page = 1;
    this.searchForm.get('nombre').reset();
    this.searchForm.get('codigo').reset();
    this.getTalleres();
  }

  filtrar() {
    this.pagination.page = 1;
    this.getTalleres();
  }

  verInformacion() {
    const text = '<b>' + 'Pasos a seguir para la gestión de talleres: <br> 1. Crear Grupo <br> 2. Agregar Miembros al grupo <br> 3. Agregar Instancias de algún taller al grupo <br> 4. Agregar los participantes de esa Instancia de Taller' + '</b>';
    Swal.fire({
      html: text,
      imageWidth: 50,
      imageHeight: 50,
      showCancelButton: false,
      confirmButtonText: 'Entendido!',
      confirmButtonColor: '#008000',
      focusCancel: true,
      width: 400
    }).then((result) => {
      if (result.value) {
        // do nothing
      }
    });
  }
}
