import {ToastrService} from 'ngx-toastr';
import {FormGroup, FormBuilder} from '@angular/forms';
import {ErrorService} from '../../../../util/services/error.service';
import {RespuestaMonitoreoService} from './services/respuesta-monitoreo.service';
import {Proyecto} from '../../interfaces/proyecto';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {RespuestasMonitoreoProyectoResponse} from './interfaces/respuesta-monitoreo-proyecto-response';
import {RespuestaMonitoreoProyecto} from './interfaces/respuesta-monitoreo-proyecto';
import {LocalStorageService} from '../../../../util/local-storage.service';
import {NgxPermissionsService} from 'ngx-permissions';

@Component({
  selector: 'app-respuesta-monitoreo-proyecto',
  templateUrl: './respuesta-monitoreo-proyecto.component.html',
  styleUrls: ['./respuesta-monitoreo-proyecto.component.sass']
})
export class RespuestaMonitoreoProyectoComponent implements OnInit {
  encuestas: RespuestaMonitoreoProyecto[];
  displayedColumns = ['nombre', 'descripcion', 'fecha_encuesta', 'acciones'];
  total: number;
  selected = null;
  proyecto: Proyecto;
  search: FormGroup;
  cargando: boolean;
  pagination = {page: 1, per_page: 10};

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private respuestaMonitoreoService: RespuestaMonitoreoService,
    private errorService: ErrorService,
    private fB: FormBuilder,
    private toastr: ToastrService,
    private localStorageService: LocalStorageService,
    private ngxPermissionService: NgxPermissionsService
  ) {
  }

  ngOnInit() {
    this.makeForm();
  }

  verifyOdkToken(): boolean {
    let result = false;
    const odkData = this.localStorageService.getSavedItem('odk_data');
    result = true;
    return result;
  }

  makeForm() {
    this.search = this.fB.group({
      nombre: undefined,
      codigo: undefined,
      activo: undefined,
      fecha_desde_ini: undefined,
      fecha_desde_fin: undefined,
      fecha_hasta_ini: undefined,
      fecha_hasta_fin: undefined
    });
  }

  getEncuestas(proyecto) {
    this.proyecto = proyecto;
    this.cargando = true;
    this.respuestaMonitoreoService.getEncuestas(proyecto.id, this.search.value, this.pagination)
      .subscribe((encuestas: RespuestasMonitoreoProyectoResponse) => {
        this.encuestas = encuestas.data;
        this.total = encuestas.metadata.total;
      }, error => {
        this.errorService.handleError(error);
      }, () => {
        this.cargando = false;
      });
  }

  verDetalle(id) {
    this.ngxPermissionService.hasPermission('PRO/mant_encuestas/get802')
      .then(result => {
        if (result) {
          this.router.navigate(['./', id], {relativeTo: this.route});
        }
      });
  }

  crearEncuesta() {
    this.router.navigate(['./new'], {relativeTo: this.route});
  }

  borrar(id) {
    this.respuestaMonitoreoService.borrarEncuesta(this.proyecto.id, id).subscribe(() => {
      this.toastr.success('Encuesta borrada exitosamente', 'Borrar Encuesta');
      this.getEncuestas(this.proyecto);
    }, error => {
      this.errorService.handleError(error);
    });
  }

  changeBackGroundColor(event, row) {
    this.selected = event.type == 'mouseover' ? row.id : null;
  }

  pageChange() {
    this.getEncuestas(this.proyecto);
  }

  onClick(event: any) {
    event ? this.filtrar() : this.onReset();
  }

  onReset() {
    this.pagination.page = 1;
    this.search.reset();
    this.getEncuestas(this.proyecto);
  }

  filtrar() {
    this.pagination.page = 1;
    this.getEncuestas(this.proyecto);
  }
}
