import { EncuestasTiposService } from './../services/encuestas-tipos.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from 'src/app/util/services/error.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-crear-encuesta-tipo',
  templateUrl: './crear-encuesta-tipo.component.html',
  styleUrls: ['./crear-encuesta-tipo.component.sass']
})
export class CrearEncuestaTipoComponent implements OnInit {

  public nuevaEncuestaTipo: FormGroup;
  process: boolean;
  constructor(  private fB: FormBuilder,
                private router: Router,
                private route: ActivatedRoute,
                private encuestasTiposService: EncuestasTiposService,
                private fS: FormStatusService,
                private toastr: ToastrService,
                private errorService: ErrorService) { }

  ngOnInit() {
    this.makeForm();
  }

  makeForm() {
    this.nuevaEncuestaTipo = this.fB.group({
      nombre: [undefined, Validators.required],
      codigo: [undefined, Validators.required],
      descripcion: [undefined]
    });
  }

  crear() {
    this.process = true;
    const jsonData = this.fS.getJsonFromForm(this.nuevaEncuestaTipo);
    this.encuestasTiposService.crearEncuestaTipo(jsonData)
      .subscribe((respuesta: any) => {
        if (respuesta.success) {
          this.toastr.success('Encuesta Tipo creado exitosamente', 'Crear Encuesta Tipo');
          this.volver();
        }
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
        this.nuevaEncuestaTipo.enable();
      }).add(() => { this.process = false; });
  }

  volver() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

}
