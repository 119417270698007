
import { ProgramaResponse } from './../interfaces/programa-response';
import { ToastrService } from 'ngx-toastr';
import { ProgramaService } from './../services/programa.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { ErrorService } from 'src/app/util/services/error.service';
import { LocalStorageService } from 'src/app/util/local-storage.service';

@Component({
  selector: 'app-crear-programa',
  templateUrl: './crear-programa.component.html',
  styleUrls: ['./crear-programa.component.sass']
})
export class CrearProgramaComponent implements OnInit {
  public nuevoPrograma: FormGroup;
  private portafolioId: number;
  private token;
  constructor(private fB: FormBuilder, private router: Router, private route: ActivatedRoute,
    private programaService: ProgramaService, private fS: FormStatusService, private toastr: ToastrService,
    private errorService: ErrorService, private localStorage: LocalStorageService) { }

  ngOnInit() {
    this.token = this.localStorage.getTokenFromStorage();
    this.portafolioId = +this.route.snapshot.paramMap.get('portafolioId');
    this.makeForm();
  }

  makeForm() {
    this.nuevoPrograma = this.fB.group({
      nombre: [null, Validators.required],
      descripcion: [null]
    });
  }

  crear() {
    const jsonData = this.fS.getJsonFromForm(this.nuevoPrograma);
    this.programaService.crearPrograma(this.portafolioId, this.token, jsonData)
      .subscribe((respuesta: ProgramaResponse) => {
        if (respuesta.success) {
          this.toastr.success('Programa creado exitosamente', 'Crear Programa');
          this.volver();
        }
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
        this.nuevoPrograma.enable();
      });
  }

  volver() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

}
