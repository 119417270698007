import { EncuestaTipo } from './../interfaces/encuesta-tipo';
import { EncuestasTiposService } from './../services/encuestas-tipos.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { ErrorService } from 'src/app/util/services/error.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-detalle-encuesta-tipo',
  templateUrl: './detalle-encuesta-tipo.component.html',
  styleUrls: ['./detalle-encuesta-tipo.component.sass']
})
export class DetalleEncuestaTipoComponent implements OnInit {

  public encuestaTipoForm: FormGroup;
  editable = true;
  cargando: boolean;
  encuestaTipoId: number;
  encuestaTipo: EncuestaTipo;
  process: boolean;

  constructor(  private fB: FormBuilder,
                private router: Router,
                private route: ActivatedRoute,
                private fS: FormStatusService,
                private encuestasTiposService: EncuestasTiposService,
                private errorService: ErrorService,
                private toastr: ToastrService) { }

  ngOnInit() {
    this.encuestaTipoId = +this.route.snapshot.paramMap.get('id');
    this.makeForm();
    this.get();
  }

  makeForm() {
    this.encuestaTipoForm = this.fB.group({
      nombre: [{ value: null, disabled: this.editable }, Validators.required],
      codigo: [{ value: null, disabled: this.editable }, Validators.required],
      descripcion: [{ value: null, disabled: this.editable }]
    });
  }

  get() {
    this.cargando = true;
    this.encuestasTiposService.obtenerEncuestaTipo(this.encuestaTipoId)
      .subscribe((respuesta: any) => {
        this.encuestaTipo = respuesta.data;
        this.encuestaTipoForm.patchValue(this.encuestaTipo);
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
        this.cargando = false;
      });
  }
  actualizar() {
    this.process = true;
    const jsonData = this.fS.getJsonFromForm(this.encuestaTipoForm);
    this.encuestasTiposService.actualizarEncuestaTipo(this.encuestaTipoId, jsonData)
      .subscribe((respuesta: any) => {
        if (respuesta.success) {
          this.toastr.success('Encuesta Tipo actualizada correctamente', 'Actualizar Encuesta Tipo');
          this.get();
          this.editableSwitcher();
        }
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => { this.process = false; });
  }

  volver() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  public editableSwitcher() {
    this.encuestaTipoForm = this.fS.cambiarEstado(this.encuestaTipoForm, this.editable, ['nombre', 'codigo'], true);
    this.editable = !this.editable;
  }

}
