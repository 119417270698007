import { NgxPermissionsService } from 'ngx-permissions';
import { DetalleTemaIndicadorComponent } from './detalle-tema-indicador/detalle-tema-indicador.component';
import { CrearTemaIndicadorComponent } from './crear-tema-indicador/crear-tema-indicador.component';
import { TemaIndicadorResponse, TemaIndicadoresResponse } from './interfaces/tema-indicador-response';
import { TemaIndicadorService } from './services/tema-indicador.service';
import { TemaIndicador } from './interfaces/tema-indicador';
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from 'src/app/util/services/error.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-tema-indicadores',
  templateUrl: './tema-indicadores.component.html',
  styleUrls: ['./tema-indicadores.component.sass']
})
export class TemaIndicadoresComponent implements OnInit {

  heading = 'Indicadores';
  subheading = 'En este módulo se encuentra la lista de indicadores.';
  icon = 'pe-7s-note2 icon-gradient bg-ripe-malin';
  cargando: boolean;
  total: number;
  pagination = { page: 1, per_page: 10 };
  selected = null;
  displayedColumns = ['nombre', 'codigo', 'porcentaje', 'esPonderado', 'descripcion', 'acciones'];
  @Input() temaId: number;
  private token;


  indicadores: TemaIndicador[];
  searchForm: FormGroup;
  constructor(private router: Router, private route: ActivatedRoute, private fB: FormBuilder,
    private localStorage: LocalStorageService, private toastr: ToastrService, private errorService: ErrorService,
    private temaIndicadorService: TemaIndicadorService, public dialog: MatDialog, private permissionService: NgxPermissionsService) { }

  ngOnInit() {
    this.token = this.localStorage.getTokenFromStorage();
    this.makeSearchForm();
    this.getIndicadores();
  }

  makeSearchForm() {
    this.searchForm = this.fB.group({
      nombre: '',
      codigo: ''
    });
  }

  getIndicadores() {
    this.cargando = true;
    this.temaIndicadorService.getTemaIndicadores(this.token, this.temaId, this.searchForm.value, this.pagination)
      .subscribe((respuesta: TemaIndicadoresResponse) => {
        this.indicadores = respuesta.data;
        this.total = respuesta.metadata.total;
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
        this.cargando = false;
      });
  }

  crear() {
    const dialogRef = this.dialog.open(CrearTemaIndicadorComponent, {
      width: '700px',
      data: { temaId: this.temaId }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'created') {
        this.getIndicadores();
      }
    });
  }

  verDetalle(id: number) {
    this.permissionService.hasPermission('PARPRO/mant_indicadores/get207').then(value => {
      if(value){
        const dialogRef = this.dialog.open(DetalleTemaIndicadorComponent, {
          width: '700px',
          data: { temaId: this.temaId, indicadorId: id}
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result === 'updated') {
            this.getIndicadores();
          }
        });
      }
    });
  }

  borrar(id: number) {
    this.temaIndicadorService.borrarTemaIndicador(this.temaId, id, this.token)
      .subscribe((respuesta: TemaIndicadorResponse) => {
        if (respuesta.success) {
          this.toastr.success('Indicador borrado exitosamente', 'Borrar Indicador');
        }
        this.getIndicadores();
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
      }, () => { });
  }

  onReset() {
    this.pagination.page = 1;
    this.searchForm.reset();
    this.getIndicadores();
  }

  filtrar() {
    this.pagination.page = 1;
    this.getIndicadores();
  }

  pageChange() {
    this.getIndicadores();
  }
  onClick(event: any) {
    event ? this.filtrar() : this.onReset();
  }

  changeBackGroundColor(event, row) {
    this.selected = event.type == 'mouseover' ? row.id : null;
  }
}
