import { FacilitadoresInstanciaService } from './../services/facilitadores-instancia.service';
import { Component, Inject, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { ErrorService } from 'src/app/util/services/error.service';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { TallerParticipantesService } from '../../../../taller-participante/services/taller-participantes.service';

@Component({
  selector: 'app-crear-facilitador-subgrupo-instancia',
  templateUrl: './crear-facilitador-subgrupo-instancia.component.html',
  styleUrls: ['./crear-facilitador-subgrupo-instancia.component.sass']
})
export class CrearFacilitadorSubgrupoInstanciaComponent implements OnInit {

  public participante: FormGroup;
  private tallerId: number;
  private proyectoId: number;
  private instanciaId: number;
  private token;
  process: boolean;
  actores: any[];
  constructor(public dialogRef: MatDialogRef<CrearFacilitadorSubgrupoInstanciaComponent>, private fB: FormBuilder,
    private tallerParticipanteService: TallerParticipantesService, private fS: FormStatusService, private toastr: ToastrService,
    private errorService: ErrorService, private localStorage: LocalStorageService, @Inject(MAT_DIALOG_DATA) public data: any,
    private faciliadorSubgrupoService: FacilitadoresInstanciaService) { }

  ngOnInit() {
    this.token = this.localStorage.getTokenFromStorage();
    this.tallerId = this.data.tallerId;
    this.instanciaId = this.data.instanciaId;
    this.makeForm();
    this.getActores();
    console.log(`Taller: ${this.tallerId} Instanca: ${this.instanciaId}`);
  }

  makeForm() {
    this.participante = this.fB.group({
      pro_grupo_taller_miembro_id: [null, Validators.required]
    });
  }

  getActores() {
    const searchV = {tipo: 'F'};
    this.tallerParticipanteService.getTallerParticipantes(this.token, this.tallerId, searchV, { page: 'disable' })
      .subscribe((respuesta: any) => {
        this.actores = respuesta.data;
      }, error => {
        this.errorService.handleError(error);
      });
  }

  crear() {
    this.process = true;
    const jsonData = this.fS.getJsonFromForm(this.participante);
    this.faciliadorSubgrupoService.crear(this.instanciaId, jsonData)
      .subscribe((respuesta: any) => {
        if (respuesta.success) {
          this.toastr.success('Facilitador asignado exitosamente', 'Asingar Facilitador');
        }
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
        this.participante.enable();
      }, () => {
        this.dialogRef.close(true);
      }).add(() => { this.process = false; });
  }

  volver() {
    this.dialogRef.close(false);
  }
}
