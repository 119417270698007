import {Injectable} from '@angular/core';
import {LocalStorageService} from './util/local-storage.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ParametrosData} from './Pages/Login/interfaces/parametros';
import {OdkService} from './util/services/odk/odk.service';
import {ToastrService} from 'ngx-toastr';
import {ODKLoginResponse} from './util/services/odk/interfaces/odk-responses';
import {environment} from '../environments/environment';
import {PowerBiLoginResponse, PowerBiReport} from './util/services/power-bi/interfaces/power-bi-bix';
import {BixdashboardsService} from './util/services/power-bi/bixdashboards.service';

@Injectable()
export class Globals {

  constructor(private localStorageService: LocalStorageService, private odkService: OdkService, private toastr: ToastrService,
              private http: HttpClient) {
  }

  /* FUNDAK */
  API_ENDPOINT_ES = 'https://apiquantidev.espy.cloud/api/v2';
  FILE_ENDPOINT = 'http://deswedd.espy.cloud:9568/apifundak';
  /* MICROSOFT */
  API_MICROSOFT = 'https://login.microsoftonline.com/common';
  API_POWERBI = 'https://api.powerbi.com/v1.0';
  ORG_NAME = 'myorg';
  GROUP_ID = '884ba0b0-29f4-4475-87f8-32c5a8d4cf31';
  DASHBOARD1_ID = '13934b06-af7f-4d97-a638-6b5e9247c984';
  /* ESOLUTIONS BASEBACKEND */
  // API_ENDPOINT_ES = 'http://esolutions.com.py:8080/basebackend/api';
  // FILE_ENDPOINT = 'http://esolutions.com.py:8080/basebackend';

  /* BASEBACKEND */
  // API_ENDPOINT_ES = 'http://deswedd.espy.cloud:9568/basebackend/api';
  // FILE_ENDPOINT = 'http://deswedd.espy.cloud:9568/basebackend';

  /* FUNDAK V1 */
  // API_ENDPOINT_ES = 'http://deswedd.espy.cloud:9568/fundak/api';
  // FILE_ENDPOINT = 'http://deswedd.espy.cloud:9568/fundak';

  API_ENDPOINT = './assets/data/';
  API_ENDPOINTTEST = 'http://192.168.1.124/php-firebase-onesignal';
  DOCS_ENDPOINT = 'https://docs.quanti.fundak.io/';

  public parametros: ParametrosData[];

  /* Habilita la creación de usuarios desde la ventana del login */
  registroDeUsuarios = true;

  getHeader(token = null) {
    return new HttpHeaders(
      {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      });
  }

  getHeaderODK() {
    const credentials = this.localStorageService.getSavedItem('odk_user_credentials');
    if (credentials) {
      const expiredAt = new Date(credentials.expiresAt);
      const today = new Date();
      if (today > expiredAt) {
        this.loginOdk().then(result => {
          return result;
        }).catch(reject => {
          console.error(reject);
        });
      } else {
        return new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${credentials.token}`
          });
      }
    } else {
      this.loginOdk().then(result => {
        return result;
      }).catch(reject => {
        console.error(reject);
      });
    }
  }

  getPowerBiHeaders() {
    const bixData = this.localStorageService.getSavedItem('bix_data');
    let credentials;
    if (bixData) {
      credentials = this.localStorageService.getSavedItem('bix_data').data as PowerBiLoginResponse;
    }
    if (credentials) {
      const expiredAt = new Date(+credentials.expires_on * 1000);
      console.log(`Valid untill: ${expiredAt}`);
      const today = new Date();
      if (today > expiredAt) {
        this.loginPowerBi().then(result => {
          return result;
        }).catch(reject => {
          console.error(reject);
        });
      } else {
        return new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${credentials.token}`
          });
      }
    } else {
      this.loginPowerBi().then(result => {
        return result;
      }).catch(reject => {
        console.error(reject);
      });
    }
  }

  loginOdk(): Promise<HttpHeaders> {
    const jsonData = JSON.stringify(environment.rootUser);
    let result;
    return new Promise((resolve, reject) => {
      this.odkService.login(jsonData)
        .subscribe((response: ODKLoginResponse) => {
          this.localStorageService.saveItem('odk_user_credentials', response);
        }, error => {
          reject(error);
          this.toastr.error('No se ha podido contactar con el ODK Central, favor contactar con el administrador', 'Atención');
        }, () => {
          result = this.localStorageService.getSavedItem('odk_user_credentials');
          const headers = new HttpHeaders(
            {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${result.token}`
            });
          resolve(headers);
        });
    });
  }

  loginPowerBi(): Promise<HttpHeaders> {
    let result;
    return new Promise((resolve, reject) => {
      this.loginMicrosoft()
        .subscribe((response: PowerBiLoginResponse) => {
          this.localStorageService.saveItem('bix_data', response);
        }, error => {
          reject(error);
          const mensaje = (error.error.errors.length > 0) ? error.error.errors : 'No se ha podido contactar con Microsoft, favor contactar con el administrador';
          this.toastr.error(mensaje, 'Atención - Error de conexión a Microsoft');
        }, () => {
          result = this.localStorageService.getSavedItem('bix_data');
          const headers = new HttpHeaders(
            {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${result.token}`
            });
          resolve(headers);
        });
    });
  }

  loginMicrosoft() {
    const headers = this.getHeader();
    const url = `${this.API_ENDPOINT_ES}/credenciales`;
    return this.http.get(url, {headers});
  }


  makeUrl(nombreServicio: string, search: any | undefined, pagination: any | undefined): string {
    let url: string = this.API_ENDPOINT_ES + nombreServicio;
    const params = [];

    for (const prop in search) {
      if (search[prop] !== undefined && search[prop] !== null && search[prop] !== '') {
        params.push(prop + '=' + search[prop]);
      }
    }

    for (const prop in pagination) {
      if (pagination[prop] !== undefined) {
        params.push(prop + '=' + pagination[prop]);
      }
    }

    if (params.length) {
      url += '?' + params.join('&');
    }

    return url;
  }

  prepareDashboardsTokens(): Promise<string> {
    const data = {datasets: [], reports: []};
    const dashboards = this.localStorageService.getSavedItem('bi_data') as PowerBiReport[];
    let token;
    dashboards.forEach(dashboard => {
      data.datasets.push({id: dashboard.datasetId});
      data.reports.push({id: dashboard.id});
    });
    return new Promise((resolve, reject) => {
      this.generarTokenParaReportes(JSON.stringify(data))
        .subscribe((response: any) => {
          // const responseObj = JSON.parse(response) as DashboardToken;
          this.localStorageService.saveItem('dashboard_token', response);
          token = response.token;
        }, error => {
          reject('Hubo un error al generar los accesos para los dashboards. Contacte con el Administrador');
        }, () => {
          resolve(token);
        });
    });
  }

  generarTokenParaReportes(reportsData) {
    const headers = this.getPowerBiHeaders();
    const url = `${environment.API_POWERBI}/${this.localStorageService.getParametrosFromStorage().ORG_NAME}/GenerateToken`;
    return this.http.post(url, reportsData, {headers});
  }

}
